import { gql } from "@apollo/client";

export const UPDATE_IMMOBILE_PROPOSAL = gql`
  mutation UpdateImmobileProposal(
    $id: Int!
    $immobile: UpdateImmobileProposalObjectInput!
  ) {
    updateImmobileProposal(id: $id, immobile: $immobile)
  }
`;
