import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";

import PasswordField from "./PasswordField";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_PASSWORD } from "graphql/mutation/updateUserPassword";

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
  user: any;
}

function ChangePasswordModal({
  open,
  onClose,
  user,
}: ChangePasswordModalProps) {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [updateUserPassword, { loading }] = useMutation(UPDATE_USER_PASSWORD);

  const { control, handleSubmit, watch, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data: any) => {
    if (data.password === data.confirmPassword) {
      try {
        const response = await updateUserPassword({
          variables: {
            id: user.id,
            password: data.password,
          },
        });

        enqueueSnackbar("Senha atualizada com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });

        reset();
        onClose();
      } catch (err) {
        enqueueSnackbar("Erro ao atualizar senha", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    } else {
      enqueueSnackbar("As senhas não coincidem", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Alterar senha</DialogTitle>
      <DialogContent>
        <PasswordField
          name="password"
          control={control}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
        <PasswordField
          name="confirmPassword"
          control={control}
          showPassword={showConfirmPassword}
          setShowPassword={setShowConfirmPassword}
          rules={{
            validate: (value: any) =>
              value === watch("password") || "As senhas não correspondem",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          autoFocus
          disabled={loading}
          sx={{
            backgroundColor: "#51d347",
            "&:hover": {
              backgroundColor: "#39a930",
              borderColor: "#0062cc",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Alterar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePasswordModal;
