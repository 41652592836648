import { UpdateFormProps } from "types/DetailProposal";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Box, Button, Checkbox, CircularProgress, Grid } from "@mui/material";
import Input from "components/Input";
import { maskCPF, maskDate, maskMoney, maskPhone } from "utils/maskOutputs";
import { UPDATE_TENANT_PROPOSAL } from "graphql/mutation/updateTenantProposal";
import { useMutation } from "@apollo/client";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { unMaskPhone, unmaskMoney } from "utils/unmaskOutputs";

dayjs.locale("pt-br");

export default function UpdateTentant({ data, onClose }: UpdateFormProps) {
  const { id } = useParams();

  const [updateTenantProposal, { loading }] = useMutation(
    UPDATE_TENANT_PROPOSAL,
    {
      refetchQueries: [
        { query: DETAIL_PROPOSAL, variables: { id: Number(id) } },
      ],
    }
  );

  const methods = useForm({
    defaultValues: {
      ...data,
      birth: maskDate(data.birth, "yyyy-MM-dd"),
      income: typeof data.income === 'number' ? data.income.toString() : data.income,
    },
  });

  const { handleSubmit, control, setValue } = methods;

  const onSubmit = async (formData: any) => {
    try {
      const tenant = {
        birth_date: formData.birth,
        email: formData.email,
        phone: formData.phone ? unMaskPhone(formData.phone) : "",
        income: formData.income ? unmaskMoney(formData.income) : 0,
        payment_responsible: formData.is_payment_responsible,
      };

      if (!tenant.email || !tenant.phone || !tenant.birth_date) {
        enqueueSnackbar("Erro ao atualizar corresponsável", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        return;
      }

      await updateTenantProposal({ variables: { id: Number(id), tenant } });
      enqueueSnackbar("Corresponsável atualizado com sucesso", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    } catch (error) {
      enqueueSnackbar("Erro ao atualizar corresponsável", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    } finally {
      onClose();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} pt={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="cpf"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="CPF*"
                  type="text"
                  fullWidth
                  disabled
                  value={maskCPF(field.value)}
                  showLabel={true}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <Controller
                name="birth"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Data de Nascimento"
                    format="DD/MM/YYYY"
                    value={
                      field.value ? dayjs(field.value, "YYYY-MM-DD") : null
                    }
                    onChange={(date) => {
                      setValue("birth", date ? date.format("YYYY-MM-DD") : "");
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Nome*"
                  type="text"
                  fullWidth
                  disabled
                  value={field.value}
                  showLabel={true}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Email*"
                  type="text"
                  fullWidth
                  value={field.value || ""}
                  showLabel={true}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="income"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Renda Mensal*"
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    field.onChange(maskMoney(e.target.value))
                  }}
                  value={field.value || ""}
                  showLabel={true}
                />
              )}
            />
          </Grid>


          <Grid item xs={12} md={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Telefone*"
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 15 }}
                  value={maskPhone(field.value)}
                  showLabel={true}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Controller
                name="is_payment_responsible"
                control={control}
                defaultValue={data.is_payment_responsible}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      color: "#51d347",
                      "&.Mui-checked": {
                        color: "#51d347",
                      },
                    }}
                  />
                )}
              />
              <span>Responsável pelo Pagamento</span>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          marginTop={4}
          spacing={1}
        >
          <Grid item>
            <Button disabled={loading} onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              autoFocus
              sx={{
                backgroundColor: "#51d347",
                "&:hover": {
                  backgroundColor: "#39a930",
                  borderColor: "#0062cc",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Atualizar"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
