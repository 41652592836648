import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ModalEdit from "./ModalEdit";
import CardWrapper from "components/CardWrapper";

export default function CardEdit({
    title,
    elements = [],
    form,
    formDetail,
    fromContract,
    isOccurrence,
    occurrenceId
}: CardEditProps) {

    const [openModal, setOpenModal] = useState<boolean>(false)

    return (
        <>
            <CardWrapper>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="#2E349F"
                            mb={2}
                        >
                            {title}
                        </Typography>
                        <Grid container spacing={2}>
                            {
                                elements.map((item, index) => (
                                    <Grid key={index} item xs={12} md={6} sx={{ wordWrap: 'break-word' }}>
                                        <Typography><b>{item.title}:</b> {item.value}</Typography>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    {
                        !fromContract &&
                        <Grid item xs={1}>
                            <EditIcon sx={{ cursor: "pointer" }} onClick={() => setOpenModal(true)} />
                        </Grid>
                    }
                </Grid>
            </CardWrapper>

            <ModalEdit
                {...{ title, form, formDetail }}
                open={openModal}
                handleClose={() => setOpenModal(false)}
                isOccurrence={isOccurrence}
                occurrenceId={occurrenceId}
            />
        </>
    )
}

interface CardEditProps {
    title: string,
    elements?: Array<{ title: string; value: any }>,
    form: "tenant" | "extraTenant" | "payment" | "location",
    formDetail: any,
    fromContract: boolean,
    isOccurrence?: boolean,
    occurrenceId?: number,
}