import React, { useState, ChangeEvent, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { DocumentNode, useQuery } from "@apollo/client";
import useDebounce from "hooks/useDebounce";

export function SelectWithSearch({
  value,
  onChange,
  label,
  query,
  queryHook,
  queryVariables,
  isDisabled = false,
  error,
}: SelectWithSearchProps) {
  const [keyword, setKeyword] = useState<string>(value);
  const debouncedValue = useDebounce(keyword);

  const { data, loading } = useQuery(queryHook, {
    variables: {
      keyword: debouncedValue,
      ...queryVariables,
    },
  });
  const list = data?.[query] || [];

  const stopImmediatePropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const renderResults = () => {
    if (loading) {
      return (
        <Box width="100%" textAlign="center" mt={2} mb={2}>
          <CircularProgress />
        </Box>
      );
    }

    if (list.length === 0) {
      return (
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          Nenhum resultado...
        </p>
      );
    }

    if (list.length > 0) {
      return list.map((option: any) => (
        <MenuItem
          onClick={() => {
            let itemData = {
              id: option.id,
              name: option.name,
            };
            onChange(itemData);
          }}
          key={option.id}
          data-name={option.name}
          data-id={option.id}
          value={option.name}
        >
          {option.name}
        </MenuItem>
      ));
    }
  };

  useEffect(() => {
    if (list.length > 0) {
      const defaultCity = list.find(
        (city: { name: string }) => city.name === value
      );

      if (defaultCity) {
        let itemData = {
          id: defaultCity.id,
          name: defaultCity.name,
        };
        onChange(itemData);
      }
    }
  }, [list]);

  return (
    <Box>
      <TextField
        disabled={isDisabled || loading}
        fullWidth
        select
        value={value}
        label={label}
        variant="outlined"
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{
          style: isDisabled ? labelDisabledStyle : labelStyle,
        }}
        InputProps={{
          style: {
            borderRadius: loading ? "5px 5px 0 0" : 5,
          },
        }}
        error={!!error}
        helperText={error}
      >
        <MenuItem
          value={""}
          onClickCapture={stopImmediatePropagation}
          onKeyDown={(e: React.KeyboardEvent) => e.stopPropagation()}
          style={{ backgroundColor: "#FFF" }}
        >
          <TextField
            fullWidth
            variant="standard"
            name="keyword"
            type="text"
            label="Pesquisar..."
            value={keyword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setKeyword(event.target.value);
            }}
          />
        </MenuItem>
        {renderResults()}
      </TextField>
      {loading && <LinearProgress />}
    </Box>
  );
}

interface SelectWithSearchProps {
  value: string;
  onChange: (value: any) => void;
  label: string;
  query: string;
  queryHook: DocumentNode;
  queryVariables?: {
    state: string;
    limit?: number;
  };
  isDisabled?: boolean;
  error?: string;
}

const labelStyle = {
  color: "#2E349F",
};

const labelDisabledStyle = {
  color: "#ccc",
};
