import React, { createContext, useContext, useReducer, useMemo } from "react";
import { Buffer } from "buffer";
import { AuthContextType } from "../types/AuthContext";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { tokenName } from "../service/api"

interface IAuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

function parseToken(token: string) {
  const content = token.split(".")[1];
  return JSON.parse(Buffer.from(content, "base64").toString());
}

function reducer(
  state: AuthContextType["state"],
  { type, payload }: { type: string; payload: string }
) {
  switch (type) {
    case "USER_LOGIN":
      return parseToken(payload);

    case "USER_LOGOUT":
      return null;

    case "UPDATE_TOKEN":
      return parseToken(payload);

    default:
      throw new Error("Nenhum tipo de ação encontrado");
  }
}

const getInitialUserInfo = () => {
  const token = Cookies.get(tokenName);
  return token ? parseToken(token) : null;
};

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, getInitialUserInfo());

  function handleLogout() {
    Cookies.remove(tokenName);
    navigate("/login");
    dispatch({ type: "USER_LOGOUT", payload: "" });
  }

  function handleLogin(token: string) {
    Cookies.set(tokenName, token, {
      expires: 7,
    });
    dispatch({ type: "USER_LOGIN", payload: token });
  }

  const value = useMemo(
    () => ({
      state,
      dispatch,
      TOKEN_USER: Cookies.get(tokenName) || null,
      handleLogout,
      handleLogin,
    }),
    [state, dispatch]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext deve ser usado dentro de um AuthProvider");
  }
  return context;
};


