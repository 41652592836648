import { useMutation, useQuery } from "@apollo/client";
import { Button, CircularProgress, Grid } from "@mui/material";
import Input from "components/Input";
import { SelectWithSearch } from "components/SelectWithSearch";
import {
  FetchCitiesResponse,
  Maybe,
  UpdateImmobileProposalObjectInput,
} from "graphql/gql/graphql";
import { UPDATE_IMMOBILE_PROPOSAL } from "graphql/mutation/updateImmobileProposal";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { FETCH_CITIES } from "graphql/query/fetchCities";
import { FETCH_STATES } from "graphql/query/fetchStates";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UpdateFormProps } from "types/DetailProposal";
import { maskCEP } from "utils/maskOutputs";

export default function UpdateLocation({ data, onClose }: UpdateFormProps) {
  const { id } = useParams();

  const [selectedState, setSelectedState] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [updateImmobileProposal, { loading: loadingUpdateImmobile }] =
    useMutation(UPDATE_IMMOBILE_PROPOSAL, {
      refetchQueries: [
        { query: DETAIL_PROPOSAL, variables: { id: Number(id) } },
      ],
    });

  const {
    loading,
    error,
    data: citiesData,
  } = useQuery(FETCH_CITIES, {
    variables: { state: selectedState?.name },
  });

  const methods = useForm({
    defaultValues: {
      ...data,
    },
  });
  const { handleSubmit, getValues, control, watch, setValue } = methods;

  const onSubmit = (formData: UpdateImmobileProposalObjectInput) => {
    const immobile = {
      zipcode: formData.zipcode,
      street: formData.street,
      number: formData.number,
      city_id: formData.city_id,
      district: formData.district,
      complement: formData.complement,
      reference: formData.reference,
    };

    // Cidade por padrão não está pegando o id, então é necessário fazer essa verificação
    if (!immobile.city_id) {
      enqueueSnackbar("Selecione a cidade novamente, por favor", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }

    updateImmobileProposal({ variables: { id: Number(id), immobile } })
      .then((response) => {
        enqueueSnackbar("Locação atualizada com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        onClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!loading && !error && citiesData && citiesData.fetchCities) {
      const defaultCity = citiesData.fetchCities.find(
        (city: Maybe<FetchCitiesResponse>) =>
          city && city.name === watch("city")?.name
      );

      if (defaultCity) {
        setValue("city_id", Number(defaultCity.id));
      }
    }
  }, [loading, error, citiesData, watch("city")]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} pt={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <SelectWithSearch
                  query="fetchStates"
                  queryHook={FETCH_STATES}
                  label="Estado*"
                  value={field.value?.name || ""}
                  onChange={(e: { id: number; name: string }) => {
                    field.onChange(e);
                    setSelectedState(e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <SelectWithSearch
              isDisabled={getValues("state")?.name == ""}
              query="fetchCities"
              queryHook={FETCH_CITIES}
              queryVariables={{
                state: getValues("state")?.name || "",
                limit: 860
              }}
              label="Cidade*"
              value={watch("city")?.name || ""}
              onChange={(e: { id: number; name: string }) => {
                setValue("city", e);
                setValue("city_id", Number(e.id));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Numero*"
                  type="number"
                  fullWidth
                  value={field.value}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="zipcode"
              control={control}
              defaultValue={data.zipCode}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Cep*"
                  type="text"
                  fullWidth
                  value={maskCEP(field.value)}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="street"
              control={control}
              defaultValue={data.address}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Endereço*"
                  type="text"
                  fullWidth
                  value={field.value}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="district"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Bairro*"
                  type="text"
                  fullWidth
                  value={field.value}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="complement"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Complemento"
                  type="text"
                  fullWidth
                  value={field.value}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="reference"
              control={control}
              defaultValue={data.referencePoint}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Ponto de referência"
                  type="text"
                  fullWidth
                  value={field.value}
                  showLabel={true}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          marginTop={4}
          spacing={1}
        >
          <Grid item>
            <Button disabled={loadingUpdateImmobile} onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              autoFocus
              disabled={loadingUpdateImmobile}
              sx={{
                backgroundColor: "#51d347",
                "&:hover": {
                  backgroundColor: "#39a930",
                  borderColor: "#0062cc",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {loadingUpdateImmobile ? (
                <CircularProgress size={24} />
              ) : (
                "Atualizar"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
