import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { FilterModal } from "../../components/FilterModal";
import { useQuery } from "@apollo/client";
import { FetchProposals } from "types/graphql/querie/fetchProposals";
import { FETCH_PROPOSALS } from "graphql/query/fetchProposals";
import { CardInfo } from "../../components/CardInfo";
import { CardAmountProposals } from "./CardAmountProposals";
import { CustomPagination } from "components/Pagination";
import useDebounce from "hooks/useDebounce";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

import {useNavigate} from "react-router-dom";

export function Proposal() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [order_by, setOrderBy] = useState<string>("proposals.created_at");
  const [direction, setDirection] = useState<string>("desc");
  const [status, setStatus] = useState<string>("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const debouncedValue = useDebounce(inputSearch);

  const { data: initialData, loading } = useQuery<FetchProposals>(
    FETCH_PROPOSALS,
    {
      variables: {
        keyword: debouncedValue,
        order_by,
        direction,
        status,
        limit: 10,
        page,
      },
      fetchPolicy: "network-only",
    }
  );
  const proposals = initialData?.fetchProposals?.data || [];
  const totalItems = initialData?.fetchProposals?.total;

  const statusOptions = [
    "Aguardando análise",
    "Aguardando assinatura",
    "Cancelada",
    "Recusada",
    "Aprovada",
    "Aguardando pagamento",
    "Resolver pendências",
  ];

  const handleBack = () => {
    navigate(-1)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFilterChange = (
    newOrderBy: string,
    newDirection: string,
    status: string,
    keyword: string
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
    setStatus(status);
    setInputSearch(keyword);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleBack}>
              <ArrowBackIosRoundedIcon sx={{ color: "#2E349F" }}/>
            </IconButton>
            <Typography
              sx={{
                fontSize: "1.7rem",
                fontWeight: "bolder",
                color: "#2E349F",
                textAlign: "start",
              }}
            >
              Propostas Cadastradas
            </Typography>
          </Box>
        </Grid>

        <Grid container spacing={2} padding="2rem 0 0 1rem">
          <Grid item xs={12} sm={12} md={4}>
            <CardAmountProposals handleClick={handleOpenModal} />
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            {loading ? (
              <Box width="100%" textAlign="center" mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              proposals.map((proposal: any) => (
                <Grid item key={proposal.id} xs={12}>
                  <CardInfo
                    id={proposal.id}
                    name={proposal.tenant.name}
                    code={proposal.code}
                    document={proposal.tenant.document}
                    created_at={proposal.created_at}
                    updated_at={proposal.updated_at}
                    status={proposal.status}
                  />
                  <Divider />
                </Grid>
              ))
            )}
            {proposals.length < 1 && !loading && (
              <Typography
                sx={{
                  color: "#2E349F",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  textAlign: "center",
                  mt: 6,
                }}
              >
                Nenhum resultado encontrado
              </Typography>
            )}
          </Grid>

          {totalItems && totalItems > 10 ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              padding="2rem"
            >
              <CustomPagination
                page={page}
                onChange={handleChange}
                totalItems={totalItems}
                limit={10}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      {isModalOpen && (
        <FilterModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onFilterChange={handleFilterChange}
          statusOptions={statusOptions}
          updateOptions={[
            { value: "", label: "Selecionar" },
            { value: "proposals.created_at", label: "Data de criação" },
            { value: "proposals.updated_at", label: "Data de atualização" },
          ]}
          ordenationOptions={[
            { value: "", label: "Selecionar" },
            { value: "desc", label: "Mais recente" },
            { value: "asc", label: "Mais antigo" },
          ]}
          data={{
            orderBy: order_by,
            direction: direction,
            status: status,
            keyword: inputSearch,
          }}
        />
      )}
    </Box>
  );
}
