import { useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import useDebounce from "hooks/useDebounce";
import { useState } from "react";
import { FilterModal } from "components/FilterModal";
import { CardInfo } from "components/CardInfo";
import { CustomPagination } from "components/Pagination";
import AddIcon from "@mui/icons-material/Add";
import FloatButton from "components/FloatButton";
import ModalAddOcurrence from "./ModalAddOcurrence";
import FETCH_OCCURRENCES from "graphql/query/fetchOccurrences";
import { QueryRoot } from "graphql/gql/graphql";
import { CircularLoading } from "components/CircularLoading";
import { useNavigate } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

export default function Occurrences() {
  const statusOptions = ["Ativo", "Aguardando", "Encerrado"];

  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [order_by, setOrderBy] = useState<string>("occurrences.created_at");
  const [direction, setDirection] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const debouncedValue = useDebounce(inputSearch);

  const { data: initialData, loading } = useQuery<QueryRoot>(
    FETCH_OCCURRENCES,
    {
      variables: {
        keyword: debouncedValue,
        order_by: order_by,
        direction,
        limit: 10,
        page,
      },
      fetchPolicy: "network-only",
    }
  );

  if (loading) {
    return <CircularLoading />;
  }

  const occurrences = initialData?.fetchOccurrences?.data || [];
  const totalItems = initialData?.fetchOccurrences?.total;

  const handleBack = () => {
    navigate(-1)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleFilterChange = (
    newOrderBy: string,
    newDirection: string,
    status: string,
    keyword: string
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
    setStatus(status);
    setInputSearch(keyword);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box display="flex" alignItems="center">
            <IconButton onClick={handleBack}>
              <ArrowBackIosRoundedIcon sx={{ color: "#2E349F" }} />
            </IconButton>
          <Typography
            sx={{
              fontSize: "1.7rem",
              fontWeight: "bolder",
              color: "#2E349F",
              textAlign: "start",
            }}
          >
            Ocorrências
          </Typography>
          </Box>
        </Grid>

        <Grid container spacing={2} padding="2rem 0 0 1rem">
          {/* <Grid item xs={12} sm={12} md={4}>
            <CardAmountOccurences handleClick={handleOpenModal} />
          </Grid> */}

          <Grid item xs={12} sm={12} md={12}>
            {loading ? (
              <Box width="100%" textAlign="center" mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              occurrences.map((occurrence: any, index: number) => (
                <Grid item key={index} xs={12}>
                  <CardInfo
                    id={occurrence.contract.proposal.id || 0}
                    name={occurrence.contract.proposal.tenant.person.name || ""}
                    code={occurrence.contract.proposal.code || ""}
                    document={occurrence.contract.proposal.tenant.person.document || ""}
                    created_at={occurrence.created_at || ""}
                    updated_at={occurrence.updated_at || ""}
                    status={occurrence.status || ""}
                    fromOccurrence={true}
                    occurrenceId={occurrence.id || 0}
                    typeOccurrence={occurrence.type || ""}
                  />
                  <Divider />
                </Grid>
              ))
            )}
            {occurrences.length < 1 && !loading && (
              <Typography
                sx={{
                  color: "#2E349F",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  textAlign: "center",
                  mt: 6,
                }}
              >
                Nenhum resultado encontrado
              </Typography>
            )}
          </Grid>
          {totalItems && totalItems > 10 ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              padding="2rem"
            >
              <CustomPagination
                page={page}
                onChange={handleChange}
                totalItems={totalItems}
                limit={10}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      {isModalOpen && (
        <FilterModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onFilterChange={handleFilterChange}
          statusOptions={statusOptions}
          updateOptions={[
            { value: "", label: "Selecionar" },
            { value: "occurrences.created_at", label: "Data de criação" },
            { value: "occurrences.updated_at", label: "Data de atualização" },
          ]}
          ordenationOptions={[
            { value: "", label: "Selecionar" },
            { value: "asc", label: "Mais recente" },
            { value: "desc", label: "Mais antigo" },
          ]}
          data={{
            orderBy: order_by,
            direction: direction,
            status: status,
            keyword: inputSearch,
          }}
        />
      )}

      {openModalAdd && (
        <ModalAddOcurrence
          open={openModalAdd}
          handleClose={() => setOpenModalAdd(false)}
        />
      )}

      <FloatButton
        icon={<AddIcon />}
        handleAction={() => {
          setOpenModalAdd(true);
        }}
      />
    </Box>
  );
}
