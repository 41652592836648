import { gql } from "@apollo/client";

export const FETCH_PROPOSALS = gql`
  query fetchProposals($keyword: String, $status: String, $order_by: String, $direction: String,$page: Int, $limit: Int) {
    fetchProposals(keyword: $keyword, status: $status, order_by: $order_by, direction: $direction, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        code
        status
        created_at
        updated_at
        real_estate{
          id
          name
          document
          email
        }
        plan{
          id
          name
        }
        tenant {
          id
          name
          document
          email
          phone
        }
      }
    }
  }
`;



