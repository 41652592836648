import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import useVerifyScreen from 'hooks/useVerifyScreen';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface FeatureCardProps {
  icon: any;
  title: string;
  description: string;
}
export default function FeatureCard({
  icon,
  title,
  description,

}: FeatureCardProps){

  const isMobile = useVerifyScreen()

  return (
    <Card
      sx={{
        width: isMobile ? "300px" : "500px",
        height: isMobile ? "150px" : "100px",
        padding: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#2E349F",
        gap: "1rem",
        backgroundColor: "#EEF0F8",
        borderRadius: "10px",
        boxShadow: "none",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#2E349F",
          color: "#fff",
        },
        transition: "0.2s"
      }}
    >
      {!isMobile && icon}

      <Box display="flex" flexDirection="column">
        <Typography sx={{ fontSize: isMobile ? "1.3rem" : "1.8rem", fontWeight: 700 }}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: '1rem', fontWeight: 400 }}
        >
          {description}
        </Typography>
      </Box>
      <ArrowForwardIosIcon sx={{color: '#51D347', fontSize: 32}}/>

    </Card>
  );
};
