import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { CardInfo } from "../../components/CardInfo";
import { CustomPagination } from "components/Pagination";
import { CircularLoading } from "components/CircularLoading";
import { FilterModal } from "components/FilterModal";
import { CardAmountContracts } from "./CardAmountContracts";
import { FETCH_CONTRACTS } from "graphql/query/fetchContracts";
import useDebounce from "hooks/useDebounce";
import { useNavigate } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

export function Contracts() {
  const statusOptions = ["Ativo", "Aguardando", "Encerrado"];

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [order_by, setOrderBy] = useState<string>("contracts.created_at");
  const [direction, setDirection] = useState<string>("desc");
  const [status, setStatus] = useState<string>("");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const debouncedValue = useDebounce(inputSearch);

  const { data: initialData, loading } = useQuery(FETCH_CONTRACTS, {
    variables: {
      keyword: debouncedValue,
      order_by,
      direction,
      status,
      limit: 10,
      page,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <CircularLoading />;
  }

  const contracts = initialData?.fetchContracts?.data || [];
  const totalItems = initialData?.fetchContracts?.total;

  const handleBack = () => {
    navigate(-1)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleFilterChange = (
    newOrderBy: string,
    newDirection: string,
    status: string,
    keyword: string
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
    setStatus(status);
    setInputSearch(keyword);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleBack}>
              <ArrowBackIosRoundedIcon sx={{ color: "#2E349F" }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1.7rem",
                fontWeight: "bolder",
                color: "#2E349F",
                textAlign: "start",
              }}
            >
              Contratos
            </Typography>
          </Box>
        </Grid>

        <Grid container spacing={2} padding="2rem 0 0 1rem">
          <Grid item xs={12} sm={12} md={4}>
            <CardAmountContracts handleClick={handleOpenModal} />
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            {loading ? (
              <Box width="100%" textAlign="center" mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              contracts.map((contract: any) => (
                <Grid item key={contract.id} xs={12}>
                  <CardInfo
                    id={contract.proposal.id}
                    name={contract.proposal.tenant.person.name}
                    code={contract.proposal.code}
                    document={contract.proposal.tenant.person.document}
                    created_at={contract.created_at}
                    updated_at={contract.updated_at}
                    status={contract.status}
                    contractId={contract.id}
                    fromContract={true}
                  />
                  <Divider />
                </Grid>
              ))
            )}
            {contracts.length < 1 && !loading && (
              <Typography
                sx={{
                  color: "#2E349F",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  textAlign: "center",
                  mt: 6,
                }}
              >
                Nenhum resultado encontrado
              </Typography>
            )}
          </Grid>
          {totalItems && totalItems > 10 ? (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              padding="2rem"
            >
              <CustomPagination
                page={page}
                onChange={handleChange}
                totalItems={totalItems}
                limit={10}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      {isModalOpen && (
        <FilterModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onFilterChange={handleFilterChange}
          statusOptions={statusOptions}
          updateOptions={[
            { value: "", label: "Selecionar" },
            { value: "contracts.created_at", label: "Data de criação" },
            { value: "contracts.updated_at", label: "Data de atualização" },
          ]}
          ordenationOptions={[
            { value: "", label: "Selecionar" },
            { value: "desc", label: "Mais recente" },
            { value: "asc", label: "Mais antigo" },
          ]}
          data={{
            orderBy: order_by,
            direction: direction,
            status: status,
            keyword: inputSearch,
          }}
        />
      )}
    </Box>
  );
}
