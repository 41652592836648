import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


export const FilterModal: React.FC<FilterModalProps> = ({
  open,
  onClose,
  onFilterChange,
  statusOptions,
  ordenationOptions,
  updateOptions,
  data
}) => {

  const [keyword, setKeyword] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [direction, setDirection] = useState<string>("");
  const [orderBy, setOrderBy] = useState<string>("");

  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
  };
  const handleOrderChange = (event: SelectChangeEvent) => {
    setDirection(event.target.value as string);
  };
  const handleTimeChange = (event: SelectChangeEvent) => {
    setOrderBy(event.target.value as string);
  };
  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value as string);
  };

  const handleFilter = () => {
    onFilterChange(orderBy, direction, selectedStatus, keyword);
    onClose();
  };

  const handleClearFilter = () => {
    setOrderBy("");
    setDirection("");
    setSelectedStatus("");
    setKeyword("");
    onFilterChange("", "", "", "");
    onClose()
  };

  const handleFillFilters = (data: DataProps) => {
    const { keyword, status, orderBy, direction } = data

    setOrderBy(orderBy);
    setDirection(direction);
    setSelectedStatus(status);
    setKeyword(keyword);
  }

  useEffect(() => {
    if (data) handleFillFilters(data)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography style={styleTypographyTitle}>Filtro</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography style={styleTypography}>Pesquisa</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Pesquisar"
              fullWidth
              value={keyword}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleKeywordChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography style={styleTypography}>Status das propostas</Typography>
            <Select
              fullWidth
              value={selectedStatus}
              onChange={handleStatusChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                Selecionar status
              </MenuItem>
              {statusOptions.map((status) => (
                <MenuItem
                  key={status}
                  value={status}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Typography style={styleTypography}>Ordenação</Typography>
            <Grid item xs={12} mb={2}>
              <Select
                fullWidth
                value={orderBy}
                onChange={handleTimeChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {updateOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} >
              <Select
                fullWidth
                value={direction}
                onChange={handleOrderChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {ordenationOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClearFilter}
          sx={{
            color: "#51D347",
          }}
        >
          limpar filtros
        </Button>
        <Button
          variant="contained"
          onClick={handleFilter}
          autoFocus
          sx={{
            backgroundColor: "#51d347",
            "&:hover": {
              backgroundColor: "#39a930",
              borderColor: "#0062cc",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface OrdenationOptions {
  value: string;
  label: string;
}
interface UpdateOptions {
  value: string;
  label: string;
}
interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  onFilterChange: (orderBy: string, direction: string, status: string, keyword: string) => void;
  statusOptions: string[];
  ordenationOptions: OrdenationOptions[];
  updateOptions: UpdateOptions[];
  title?: string;
  data?: DataProps
}

interface DataProps {
  orderBy: string,
  direction: string,
  status: string,
  keyword: string
}

const styleTypography = {
  fontSize: "1.2rem",
  fontWeight: 700,
  marginBottom: 4,
  color: "#2E349F",
};
const styleTypographyTitle = {
  fontSize: "1.5rem",
  color: "#2E349F",
  fontWeight: 900
};