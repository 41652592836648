import { gql } from "@apollo/client";

export const DETAIL_PROPOSAL_SIMULATION = gql`
  query detailProposalSimulation($id:Int!) {
    detailProposalSimulation(id: $id) {
      credit_analysis {
        id
        risk
        status
        situation
        created_at
        updated_at
      }
    }
  }
`;
