import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { CREATE_PROPOSAL } from "graphql/mutation/createProposal";
import { useMutation } from "@apollo/client";
import useVerifyScreen from "hooks/useVerifyScreen";
import { ColorlibConnector, ColorlibStepIcon, steps } from "./Connector";
import FormLocation from "./FormLocation";
import FormCalcValues from "./FormCalcValues";
import FormTenant from "./FormTenant";
import { enqueueSnackbar } from "notistack";
import ModalCreditAnalyze from "components/ModalCreditAnalyze";
import { unmaskMoney } from "utils/unmaskOutputs";
import { useAuthContext } from "context/AuthProvider";

type FormatFormType = {
  proposal_simulation_id?: number;
  immobile: {
    zipcode: string;
    street: string;
    number: string;
    city_id: number;
    district: string;
    complement: string;
    reference: string;
  };
  plan_id: number;
  rent_amount: number;
  condominium_amount: number;
  other_taxes: number;
  chosen_installments: string;
  chosen_setup_fee: string;
  chosen_setup_installments: string;
  iptu: number;
  tenant: {
    document: string;
    name: string;
    birth_date: string;
    email: string;
    phone: string;
    income: number;
    extras?: string;
  };
};

export function CreateProposal() {

  let { state } = useLocation()
  const { state: userInfo }: { state: any } = useAuthContext()

  const isMobile = useVerifyScreen()

  const [createProposal, { loading }] = useMutation(CREATE_PROPOSAL);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [openModalAnalyzis, setOpenModalAnalyzis] = useState<boolean>(false);
  const [createdProposalId, setCreatedProposalId] = useState<number>(0);

  const [formData, setFormData] = useState({
    stepLocation: {
      zipCode: "",
      state: {
        id: 0,
        name: ""
      },
      city: {
        id: 0,
        name: ""
      },
      address: "",
      number: "",
      district: "",
      complement: "",
      reference: "",
    },
    stepValues: {
      iptu: "",
      rent: "",
      condo: "",
      otherTaxes: "",
      totalValue: 0,
      service: {
        description: "",
        fee: 0,
        id: 0,
        max_installments: 0,
        name: "",
        setup_fee: [],
        setup_max_installments: 0,
        warranty_months: 0,
        __typename: ""
      },
      serviceInstallmentOption: "",
      setupValue: "",
      setupInstallmentOption: ""
    },
    stepTenant: {
      name: "",
      email: "",
      cpf: "",
      phone: "",
      selectedDate: "",
      responsiblePayment: true,
      income: "",
      extras: {
        email: "",
        phone: "",
        address: {
          zipcode: "",
          street: "",
          number: "",
          district: "",
          city: {
            id: 0,
            name: "",
            state: "",
          },
          complement: "",
          reference: ""
        }
      },
    },
    creditAnalysis: null
  });

  const receiveData = (data: any, objName: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [objName]: data
    }));
  };
  const handleCreateProposal = async () => {
    const proposalId = state ? state.proposalId : null;

    const existProposalId = proposalId !== null && proposalId !== 0 ? true : false;

    let formatForm: FormatFormType = {
      immobile: {
        zipcode: formData.stepLocation.zipCode,
        street: formData.stepLocation.address,
        number: formData.stepLocation.number,
        city_id: formData.stepLocation.city.id,
        district: formData.stepLocation.district,
        complement: formData.stepLocation.complement,
        reference: formData.stepLocation.reference,
      },
      plan_id: formData.stepValues.service.id,
      rent_amount: unmaskMoney(formData.stepValues.rent),
      condominium_amount: unmaskMoney(formData.stepValues.condo),
      other_taxes: unmaskMoney(formData.stepValues.otherTaxes),
      chosen_installments: formData.stepValues.serviceInstallmentOption,
      chosen_setup_fee: formData.stepValues.setupValue,
      chosen_setup_installments: formData.stepValues.setupInstallmentOption,
      iptu: unmaskMoney(formData.stepValues.iptu),
      tenant: {
        document: formData.stepTenant.cpf,
        name: formData.stepTenant.name,
        birth_date: formData.stepTenant.selectedDate,
        email: formData.stepTenant.email,
        phone: formData.stepTenant.phone,
        income: unmaskMoney(formData.stepTenant.income),
        extras: JSON.stringify({
          email: formData.stepTenant.extras.email,
          phone: formData.stepTenant.extras.phone,
          address: {
            zipcode: formData.stepTenant.extras.address.zipcode,
            street: formData.stepTenant.extras.address.street,
            number: formData.stepTenant.extras.address.number,
            city: {
              id: formData.stepTenant.extras.address.city.id,
              name: formData.stepTenant.extras.address.city.name,
              state: formData.stepTenant.extras.address.city.state,
            },
            district: formData.stepTenant.extras.address.district,
            complement: formData.stepTenant.extras.address.complement,
            reference: formData.stepTenant.extras.address.reference,
          },
        }),
      },
    }

    if (existProposalId) {
      formatForm = { ...formatForm, proposal_simulation_id: proposalId }
    }

    try {
      const { data } = await createProposal({
        variables: formatForm
      });

      setOpenModalAnalyzis(true)
      setCreatedProposalId(data?.createProposal)
      enqueueSnackbar("Proposta criada com sucesso", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    } catch (error) {
      setActiveStep(2)
      enqueueSnackbar("Erro ao criar proposta", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
      console.error("[Error ~ CreateProposal]: ", error);
    }
  };

  useEffect(() => {
    if (activeStep > 2) {
      handleCreateProposal()
    }
    return
  }, [activeStep])

  useEffect(() => {
    if (state) {
      const { formValues, creditAnalysis, proposalId } = state
      const {
        aluguel,
        cep,
        street,
        district,
        number,
        cidade,
        estado,
        condominio,
        cpf,
        iptu,
        nome,
        outras_taxas,
        valorLocaticioTotal,
        renda
      } = formValues

      setFormData((prevState) => ({
        creditAnalysis: creditAnalysis,
        stepLocation: {
          ...prevState["stepLocation"],
          number: number,
          city: cidade,
          state: estado,
          zipCode: cep,
          district: district,
          address: street,
        },
        stepValues: {
          ...prevState["stepValues"],
          rent: aluguel,
          iptu: iptu,
          condo: condominio,
          otherTaxes: outras_taxas,
          totalValue: valorLocaticioTotal
        },
        stepTenant: {
          ...prevState["stepTenant"],
          name: nome,
          cpf: cpf,
          income: renda
        }
      }));
    }

    if (!state) {
      setFormData((prevState) => ({
        ...prevState,
        stepLocation: {
          ...prevState.stepLocation,
          city: {
            id: userInfo?.person?.address?.city?.id || 0,
            name: userInfo?.person?.address?.city?.name || ""
          },
          state: {
            id: 0,
            name: userInfo?.person?.address?.city?.state || ""
          }
        }
      }));
    }
  }, [])

  return (
    <>
      <Box
        width="100%"
        margin={isMobile ? "2rem 0 0 0" : "2rem 1rem 0 10rem"}
        padding="0.5rem 2rem 2rem 1rem"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems={isMobile ? "center" : ""}
      >
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "900 ",
            color: "#2E349F",
            textAlign: "start",
          }}
        >
          Cadastre uma proposta locatícia
        </Typography>

        <div style={{ padding: "3rem 0 3rem 0", width: "80%" }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </div>
        <Box width={isMobile ? "100%" : "80%"} display="flex">
          {activeStep == 0 && (
            <FormLocation {...{ activeStep, setActiveStep }} formData={formData.stepLocation} sendData={receiveData} />
          )}
          {activeStep == 1 && (
            <FormCalcValues {...{ activeStep, setActiveStep }} formData={formData.stepValues} sendData={receiveData} />
          )}
          {activeStep == 2 && (
            <FormTenant {...{ activeStep, setActiveStep }} formData={formData.stepTenant} sendData={receiveData} />
          )}
          {activeStep > 2 && (
            <Box
              sx={{
                width: '60%',
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4
              }}>
              {
                loading &&
                <CircularProgress sx={{ color: "#51D347" }} />
              }
              <Typography variant="h6" color="#2e349f" fontWeight="bold">{
                loading
                  ? "Enviando Formulário"
                  : "Formulário enviado"
              }</Typography>
            </Box>
          )}
        </Box>

      </Box>

      {
        openModalAnalyzis &&
        <ModalCreditAnalyze
          formValues={{
            nome: formData.stepTenant.name,
            cpf: formData.stepTenant.cpf
          }}
          proposalId={createdProposalId}
          preCreditAnalysis={formData.creditAnalysis}
          open={openModalAnalyzis}
          handleClose={() => setOpenModalAnalyzis(false)}
          fromFormProposal
        />
      }

    </>
  );
}
