import { gql } from "@apollo/client";

export const SEND_ATTACHMENTS_PROPOSAL = gql`
  mutation sendAttachmentsProposal(
    $proposal_id: Int!
    $names: [String]!
    $types: [String]!
    $files: [AttachmentFile]
  ) {
    sendAttachmentsProposal(
      proposal_id: $proposal_id
      names: $names
      types: $types
      files: $files
    )
  }
`;
