import { gql } from "@apollo/client";

export const DETAIL_OCCURRENCE = gql`
  query detailOccurrence($id: Int!) {
    detailOccurrence(id: $id) {
      id
      type
      status
      value
      dead_line
      description
      deleted_at
      created_at
      updated_at
      cemig
      condominium_amount
      contractual_fine
      copasa
      iptu
      other_taxes
      rent_amount
      repairs
      contract {
        id
        start
        finish
        status
        created_at
        updated_at
        proposal {
          id
          code
          rent_amount
          condominium_amount
          other_taxes
          iptu
          chosen_plan
          guarantee_fee
          warranty_months
          chosen_installments
          chosen_setup_fee
          chosen_setup_installments
          status
          created_at
          updated_at
          real_estate {
            id
            name
            document
            phone
            email
            zipcode
            street
            number
            district
            complement
            reference
            city {
              id
              name
              state
            }
            bank_data {
              id
              account_type
              bank
              agency
              account
              created_at
            }
          }
          tenant {
            id
            name
            document
            birth_date
            email
            phone
            is_payment_responsible
            created_at
          }
          history {
            title
            person_id
            person_name
            description
            updated_at
          }
        }
        payment_responsible {
          id
          name
          document
          birth_date
          email
          phone
        }
      }
      attachments {
        id
        name
        url
        created_at
      }
    }
  }
`;