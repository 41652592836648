import { gql } from '@apollo/client';

export const UPDATE_OCCURRENCE = gql`
    mutation UpdateOccurrence(
        $id: Int!
        $type: String
        $status: String
        $rent_amount: Float
        $condominium_amount: Float
        $copasa: Float
        $cemig: Float
        $repairs: Float
        $iptu: Float
        $contractual_fine: Float
        $other_taxes: Float
        $value: Float
        $dead_line: String
        $description: String
    ) {
        UpdateOccurrence(
            id: $id
            type: $type
            status: $status
            rent_amount: $rent_amount
            condominium_amount: $condominium_amount
            copasa: $copasa
            cemig: $cemig
            repairs: $repairs
            iptu: $iptu
            contractual_fine: $contractual_fine
            other_taxes: $other_taxes
            value: $value
            dead_line: $dead_line
            description: $description
        )
    }
`;