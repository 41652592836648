import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAuthContext } from "context/AuthProvider";
import { CREATE_USER } from "graphql/mutation/createUser";
import { ME } from "graphql/query/me";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import PasswordField from "../ChangePasswordModal/PasswordField";

interface FormDataProps {
  real_estate_id: number;
  email: string;
  password: string;
  status: string;
}

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;
  real_estate_id: number;
  me_id: number;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  open,
  onClose,
  real_estate_id,
  me_id,
}) => {
  const { control, handleSubmit, reset } = useForm<FormDataProps>();
  const [showPassword, setShowPassword] = useState(false);

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    refetchQueries: [
      {
        query: ME,
        variables: { id: me_id },
      },
    ],
  });

  const onSubmit = async (data: FormDataProps) => {
    try {
      await createUser({
        variables: { ...data, real_estate_id: real_estate_id },
      });
      enqueueSnackbar("Usuário adicionado com sucesso", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      reset({ email: "", password: "", status: "Ativo" });
      onClose();
    } catch (error) {
      enqueueSnackbar("Erro ao adicionar usuário", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar usuário</DialogTitle>
      <DialogContent>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              label="Email"
              type="email"
              fullWidth
            />
          )}
        />

        <PasswordField
          name="password"
          control={control}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />

        <Controller
          name="status"
          control={control}
          defaultValue="Ativo"
          render={({ field }) => (
            <FormControl fullWidth margin="dense">
              <InputLabel>Status</InputLabel>
              <Select {...field}>
                <MenuItem value="Ativo">Ativo</MenuItem>
                <MenuItem value="Inativo">Inativo</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          autoFocus
          sx={{
            backgroundColor: "#51d347",
            "&:hover": {
              backgroundColor: "#39a930",
              borderColor: "#0062cc",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Adicionar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
