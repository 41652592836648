import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { FETCH_CONTRACTS } from "graphql/query/fetchContracts";
import useDebounce from "hooks/useDebounce";
import { maskCPF } from "utils/maskOutputs";

export default function Step01({ formData, setFormData }: Step01Props) {
  const [checked, setChecked] = useState<null | number>(formData?.id || null);
  const [search, setSearch] = useState<string>("");

  const debouncedValue = useDebounce(search);

  const { data, loading } = useQuery(FETCH_CONTRACTS, {
    variables: {
      keyword: debouncedValue,
    },
    fetchPolicy: "network-only",
  });
  const contracts = data?.fetchContracts?.data || [];

  const handleRadioChange = (value: any) => () => {
    setFormData((prevState: any) => ({ ...prevState, step01: value }));
    setChecked(value.id);
  };

  return (
    <Box pt={2} pb={2}>
      <TextField
        id="input-with-icon-textfield"
        label="Pesquisar"
        variant="outlined"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        value={search}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {loading && (
        <Box textAlign="center" mt={4} mb={4}>
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <List
          dense
          sx={{
            width: "100%",
            maxHeight: "300px",
            overflow: "auto",
            bgcolor: "background.paper",
            mt: 2,
          }}
        >
          {contracts.map((value: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <ListItem
                  onClick={handleRadioChange(value)}
                  secondaryAction={
                    <Radio edge="end" checked={checked == value.id} />
                  }
                  disablePadding
                  sx={{
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <ListItemButton>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography sx={{ fontWeight: "900" }}>
                          Contrato:
                        </Typography>
                        <Typography>{value.proposal.code}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8}>
                        <Typography sx={{ fontWeight: "900" }}>
                          Locatário:
                        </Typography>
                        <Typography sx={{ fontWeight: "400" }}>
                          {value.proposal.tenant.person.name}
                        </Typography>
                        <Typography sx={{ fontWeight: "900" }}>CPF:</Typography>
                        <Typography>
                          {maskCPF(value.proposal.tenant.person.document)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      )}
    </Box>
  );
}

interface Step01Props {
  formData: any;
  setFormData: (arg: any) => void;
}
