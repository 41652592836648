import { Box, List, ListItem } from "@mui/material";
import { useQuery } from "@apollo/client";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { getItems } from "./items";
import TopicWithSubtopics from "../TopicWithSubtopics";
import { DetailProposalCoResponsibleResponse, QueryRoot } from "graphql/gql/graphql";
import { useParams } from "react-router-dom";

export default function Topics() {
  const { code } = useParams<{ code: string }>();

  const { data, loading, error } = useQuery<QueryRoot>(DETAIL_PROPOSAL, {
    variables: {
      code: code,
    },
  });

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro :(</p>;

  const proposal = data?.detailProposal;
  const immobile = proposal?.immobile;
  const tenant = proposal?.tenant;
  const co_responsibles = proposal?.co_responsibles?.filter(
    (co_responsible): co_responsible is DetailProposalCoResponsibleResponse =>
      co_responsible !== null && co_responsible !== undefined
  );

  if (!proposal || !tenant || !immobile || !co_responsibles) {
    return <p>Informações do inquilino ou imóvel não disponíveis</p>;
  }

  const items = getItems(proposal, tenant, immobile, co_responsibles);
  return (
    <Box>
      <List>
        {items.map((item, index) => (
          <ListItem key={index}>
            {item.subtopics && (
              <TopicWithSubtopics
                index={index}
                title={item.title}
                subtopics={item.subtopics}
                tableData={item.type === "table" ? item.data : undefined}
                tableSubtopicIndex={
                  item.type === "table" ? item.subtopics.length : undefined
                }
              />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
