import {
  Box,
  SpeedDialProps as SpeedDialPropsMaterial,
  Tooltip,
} from "@mui/material";
import IconButton from "components/IconButton";

type Action = {
  icon: JSX.Element;
  name: string;
  onClick: () => void;
};

type SpeedDialProps = SpeedDialPropsMaterial & {
  actions: Array<Action>;
};

const iconButtonStyles = {
  backgroundColor: "#ffffff",
  color: "#808080",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: "#ffffff",
    borderColor: "#0062cc",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  fontSize: "3rem",
};

const boxStyles = {
  position: "fixed",
  bottom: 20,
  right: 20,
  display: "flex",
  flexDirection: "row",
  gap: 2,
};

export default function SpeedDialComponent({ actions }: SpeedDialProps) {
  return (
    <Box sx={boxStyles}>
      {actions.map((action, index) => (
        <Tooltip key={index} title={action.name} arrow>
          <IconButton
            key={action.name}
            onClick={action.onClick}
            size="large"
            sx={iconButtonStyles}
          >
            {action.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
}
