import { Box, Grid, Typography } from "@mui/material";
import CardWrapper from "components/CardWrapper";
import { maskDate } from "utils/maskOutputs";

export function Historic({ timeline }: HistoricProps) {
  return (
    <CardWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="bold" color="#2E349F" mb={2}>
            Histórico
          </Typography>

          <Grid container spacing={1}>
            {timeline.map((item: TimelineProps, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    "&::before": {
                      content: index !== timeline.length - 1 ? '""' : "none",
                      position: "absolute",
                      top: index === 0 ? "10px" : 0,
                      bottom: index === timeline.length - 2 ? "-10px" : 0,
                      left: "7px",
                      borderLeft: "1px solid #2e349f",
                    },
                  }}
                >
                  <Grid container alignItems="flex-start">
                    <Grid
                      item
                      xs={1}
                      mt={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          minHeight: 15,
                          borderRadius: "50%",
                          border: "1.5px solid #2e349f",
                          backgroundColor: "#fff",
                          position: "relative",
                          zIndex: 1,
                        }}
                      ></Box>
                    </Grid>
                    <Grid item xs={11} sx={{ wordWrap: "break-word" }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {item.title}
                      </Typography>
                      <Typography variant="body1">
                        {maskDate(item.updated_at, "dd/MM/yyyy HH:mm")} - por{" "}
                        {item.person_name}
                      </Typography>
                      <Typography variant="body1">
                        {item.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </CardWrapper>
  );
}

interface HistoricProps {
  timeline: Array<TimelineProps>;
}

type TimelineProps = {
  title: string;
  person_id: number;
  person_name: string;
  description: string;
  updated_at: string;
  __typename: string;
};
