import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import * as yup from "yup";

interface ModalEditProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}
const validationSchema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  document: yup.string().required("CNPJ é obrigatório"),
  phone: yup.string().required("Telefone é obrigatório"),
});

import { TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { maskCNPJ, maskPhone } from "utils/maskOutputs";
import { UPDATE_PERSON } from "graphql/mutation/updatePerson";
import { ME } from "graphql/query/me";

type FormDataProps = {
  name: string;
  document: string;
  phone: string;
};

export default function ModalEdit({ open, handleClose, data }: ModalEditProps) {
  const methods = useForm({
    defaultValues: {
      name: data.name,
      document: maskCNPJ(data.document),
      phone: maskPhone(data.phone),
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const [updatePerson, { loading }] = useMutation(UPDATE_PERSON, {
    refetchQueries: [{ query: ME, variables: { id: data.id } }],
  });

  const onSubmit = (formData: FormDataProps) => {
    updatePerson({ variables: { ...formData, id: data.id } })
      .then((response) => {
        enqueueSnackbar("Dados atualizados com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        handleClose();
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar("Erro ao atualizar dados", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Editar informações do usuário
      </DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nome"
                  type="text"
                  error={!!methods.formState.errors.name}
                  fullWidth
                />
              )}
            />
            <Controller
              name="document"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  id="document"
                  label="CNPJ"
                  type="text"
                  error={!!methods.formState.errors.document}
                  onChange={(e) => field.onChange(maskCNPJ(e.target.value))}
                  fullWidth
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  id="phone"
                  label="Telefone"
                  type="text"
                  error={!!methods.formState.errors.phone}
                  onChange={(e) => field.onChange(maskPhone(e.target.value))}
                  fullWidth
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              autoFocus
              disabled={loading}
              sx={{
                backgroundColor: "#51d347",
                "&:hover": {
                  backgroundColor: "#39a930",
                  borderColor: "#0062cc",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Salvar"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
