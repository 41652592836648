import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import Input from "components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { maskCEP, maskMoney, maskNumber } from "utils/maskOutputs";
import { maskCPF } from "utils/maskOutputs";
import { SelectWithSearch } from "components/SelectWithSearch";
import { unMaskCep, unmaskMoney } from "utils/unmaskOutputs";
import ModalCreditAnalyze from "components/ModalCreditAnalyze";
import { useMutation } from "@apollo/client";
import { CREATE_PROPOSAL_SIMULATION } from "graphql/mutation/createProposalSimulation";
import { FETCH_CITIES } from "graphql/query/fetchCities";
import { FETCH_STATES } from "graphql/query/fetchStates";
import { useAuthContext } from "context/AuthProvider";

interface FeatureModalSimulationDashboardProps {
  openModal: boolean;
  handleCloseModal: () => void;
}
export function ModalSimulationDashboard({
  openModal,
  handleCloseModal,
}: FeatureModalSimulationDashboardProps) {

  const { state }: { state: any } = useAuthContext()

  const [simulationProposalId, setSimulationProposalId] = useState(0);
  const [openModalAnalyze, setModalAnalyze] = useState(false);
  
  const [createProposalSimulation, { loading }] = useMutation(CREATE_PROPOSAL_SIMULATION)
  
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: "",
      cpf: "",
      cep: "",
      street: "",
      number: "",
      district: "",
      cidade: {
        id: state?.person?.address?.city?.id || null,
        name: state?.person?.address?.city?.name || ""
      },
      estado: {
        id: null,
        name: state?.person?.address?.city?.state
      },
      aluguel: "",
      condominio: "",
      outras_taxas: "",
      iptu: "",
      valorLocaticioTotal: 0,
      renda: "",
    }
  });
  const { handleSubmit, getValues, control, watch, setValue } = methods

  const handleSum = () => {
    var values = getValues(["iptu", "aluguel", "condominio", "outras_taxas"])
    const formatNumbers = values.map(el => unmaskMoney(el));

    const soma = formatNumbers.reduce((acumulador, numero) => acumulador + numero, 0).toFixed(2);

    setValue("valorLocaticioTotal", Number(soma))
  }

  const onSubmit = async () => {
    const {
      aluguel,
      condominio,
      cpf,
      cep,
      street,
      number,
      district,
      iptu,
      nome,
      outras_taxas,
      renda,
    } = getValues()

    try {
      const { data } = await createProposalSimulation({
        variables: {
          immobile: {
            city_id: getValues("cidade").id,
            zipcode: unMaskCep(cep),
            street: street,
            number: number,
            district: district
          },
          rent_amount: unmaskMoney(aluguel),
          condominium_amount: unmaskMoney(condominio),
          other_taxes: unmaskMoney(outras_taxas),
          iptu: unmaskMoney(iptu),
          tenant: {
            name: nome,
            document: cpf,
            income: renda ? unmaskMoney(renda) : 0
          }
        }
      });

      if (data?.createProposalSimulation) {
        setSimulationProposalId(data.createProposalSimulation);
      }
    } catch (error) {
      console.error("[Error ~ CreateProposalSimulation]: ", error);
    } finally {
      setModalAnalyze(true)
    }
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
      >
        <FormProvider {...methods}>
          <DialogTitle>
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "900 ", color: "#2E349F" }}
            >
              Faça uma simulação de fiança para o locatário
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: "1.1rem", color: "#2E349F" }}>
              Informe os dados reais do locatário para fazer uma análise
            </Typography>
            <Box display="flex" flexDirection="column" paddingTop="1rem">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Nome *"
                        type="text"
                        fullWidth
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="cpf"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="CPF *"
                        type="text"
                        fullWidth
                        value={maskCPF(field.value)}
                        inputProps={{ maxLength: 14 }}
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="renda"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Renda Mensal*"
                        type="text"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(maskMoney(e.target.value))
                        }}
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="cep"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="CEP *"
                        type="text"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(maskCEP(e.target.value))
                        }}
                        inputProps={{ maxLength: 9 }}
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="street"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Rua *"
                        type="text"
                        fullWidth
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="number"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Número *"
                        type="text"
                        value={maskNumber(field.value)}
                        fullWidth
                        inputProps={{ maxLength: 6 }}
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="district"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Bairro *"
                        type="text"
                        fullWidth
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <SelectWithSearch
                    query="fetchStates"
                    queryHook={FETCH_STATES}
                    label="Estado *"
                    value={watch("estado")?.name || ""}
                    onChange={(e: { id: number, name: string }) => {
                      setValue("estado", e)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <SelectWithSearch
                    isDisabled={getValues("estado").name == null}
                    query="fetchCities"
                    queryHook={FETCH_CITIES}
                    queryVariables={{
                      state: getValues("estado").name || "",
                      limit: 860
                    }}
                    label="Cidade *"
                    value={watch("cidade")?.name || ""}
                    onChange={(e: { id: number, name: string }) => {
                      setValue("cidade", e)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="aluguel"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Aluguel *"
                        type="text"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(maskMoney(e.target.value))
                          handleSum()
                        }}
                        value={field.value}
                        showLabel={true}
                      />
                    )}
                  />

                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="condominio"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Condomínio *"
                        type="text"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(maskMoney(e.target.value))
                          handleSum()
                        }}
                        value={field.value}
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="outras_taxas"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Outras Taxas *"
                        type="text"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(maskMoney(e.target.value))
                          handleSum()
                        }}
                        value={field.value}
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="iptu"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="IPTU *"
                        type="string"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(maskMoney(e.target.value))
                          handleSum()
                        }}
                        value={field.value}
                        showLabel={true}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mt={2}>
              <Typography variant="body1">
                Valor Locatício Total
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                {maskMoney(watch("valorLocaticioTotal"))}
              </Typography>
            </Box>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseModal}
              sx={{
                color: "#51d347"
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              autoFocus
              sx={{
                backgroundColor: "#51d347",
                '&:hover': {
                  backgroundColor: '#39a930',
                  borderColor: '#0062cc',
                  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                },
              }}
            >
              {
                loading &&
                <CircularProgress size={20} sx={{ marginRight: 2, color: "#51d347" }} />
              }
              Simular
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog >

      {
        openModalAnalyze &&
        <ModalCreditAnalyze
          proposalId={simulationProposalId}
          open={openModalAnalyze}
          handleClose={() => setModalAnalyze(false)}
          formValues={getValues()}
        />
      }

    </>
  );
}

const schema = yup.object({
  nome: yup.string().required("Nome é obrigatório"),
  cpf: yup.string().required("CPF é obrigatório"),
  cep: yup.string().required("CEP é obrigatório"),
  street: yup.string().required("Rua é obrigatório"),
  number: yup.string().required("Número é obrigatório"),
  district: yup.string().required("Bairro é obrigatório"),
  cidade: yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string(),
  }).required('Nome da cidade é obrigatório'),
  estado: yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string(),
  }).required('Nome da cidade é obrigatório'),
  aluguel: yup.string().required("Aluguel é obrigatório"),
  condominio: yup.string().required("Condomínio é obrigatório"),
  outras_taxas: yup.string().required("Outras taxas é obrigatório"),
  iptu: yup
    .string()
    .required("Valor locatício total é obrigatório"),
  valorLocaticioTotal: yup.number(),
  renda: yup.string().required("Renda é obrigatório"),
});