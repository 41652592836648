import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";


export default function CardPlanModel({
  id,
  planName,
  description,
  selectedPlan,
  onPlanChange,
}: CardPlanModelProps){

  return (
    <Card style={{ backgroundColor: "#EEF0F8" }}>
      <CardContent>
        <Box sx={customCard}>
          <Radio
            aria-label="select-plan"
            name="select-plan"
            value={planName}
            checked={selectedPlan === id}
            onChange={() => onPlanChange()}
            sx={{
              "& .css-hyxlzm": {
                color: "#51D347",
              },
            }}
          />
          <Typography sx={{ fontWeight: "900", fontSize: "1.2rem" }}>
            {planName}
          </Typography>
        </Box>
        <List>
          {description.map((desc, index) => (
            <Typography
              sx={{ fontSize: "1rem", padding: "0.8rem", color: "#2E349F" }}
              key={index}
            >
              {desc}
            </Typography>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

interface CardPlanModelProps {
  id: number
  planName: string;
  description: string[];
  selectedPlan: number;
  onPlanChange: () => void;
}

const customCard = {
  display: "flex",
  alignItems: "center",
  color: "#2E349F",
};


