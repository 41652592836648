import { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  CardActions,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";

import DeleteConfirmationModal from "./DeleteUserModal";
import EditUserModal from "./EditUserModal";
import AddUserModal from "./AddUserModal";

import ChangePasswordModal from "./ChangePasswordModal";
import { MeUsersResponse } from "graphql/gql/graphql";

interface UserTableProps {
  users: MeUsersResponse[];
  real_estate_id: number;
  me_id: number;
}

interface ModalState {
  type: string | null;
  user: MeUsersResponse | null;
}

function UserTable({ users, real_estate_id, me_id }: UserTableProps) {
  const [modal, setModal] = useState<ModalState>({ type: null, user: null });
  const handleOpenModal = (type: string, user: MeUsersResponse | null) => {
    setModal({ type, user });
  };

  const handleCloseModal = () => {
    setModal({ type: null, user: null });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 400, overflow: "auto" }}
        elevation={0}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                }}
              >
                Email
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                }}
              >
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <TableRow key={user?.id}>
                <TableCell>{user?.email}</TableCell>
                <TableCell>{user?.status}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    aria-label="editar usuário"
                    onClick={() => handleOpenModal("edit", user)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="default"
                    aria-label="alterar senha"
                    onClick={() => handleOpenModal("changePassword", user)}
                  >
                    <LockIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="excluir usuário"
                    onClick={() => handleOpenModal("delete", user)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal("add", null)}
          style={{ marginTop: 10 }}
        >
          ADICIONAR
        </Button>
      </CardActions>

      <AddUserModal
        open={modal.type === "add"}
        onClose={handleCloseModal}
        real_estate_id={real_estate_id}
        me_id={me_id}
      />

      <EditUserModal
        open={modal.type === "edit"}
        onClose={handleCloseModal}
        user={modal.user}
        me_id={me_id}
      />
      <ChangePasswordModal
        open={modal.type === "changePassword"}
        onClose={handleCloseModal}
        user={modal.user}
      />
      <DeleteConfirmationModal
        open={modal.type === "delete"}
        onClose={handleCloseModal}
        user={modal.user}
        me_id={me_id}
      />
    </>
  );
}

export default UserTable;
