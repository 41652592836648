import { Box, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { maskMoney } from "utils/maskOutputs";
import ModalAddFiles from "../ModalAddFiles";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { useState } from "react";

export default function Step02({ formData, setFormData }: Step02Props) {
  const [showFields, setShowFields] = useState(false);

  const handleChange = (
    value: string,
    propName: string,
    type: string = "none"
  ) => {
    let formatedValue = value;
    if (type == "money") formatedValue = maskMoney(value);

    setFormData((prevState: any) => ({
      ...prevState,
      step02: {
        ...prevState.step02,
        [propName]: formatedValue,
      },
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    handleChange(value, "type", "type");
    setShowFields(value === "Rescisão");
  }

  return (
    <Box pt={3} pb={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            value={formData.type || "Simples"}
            onChange={handleSelectChange}
            fullWidth
          >
            <MenuItem value={"Simples"}>Simples</MenuItem>
            <MenuItem value={"Rescisão"}>Recisão</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Valor original*"
            type="text"
            name="orignalvalue"
            fullWidth
            value={formData.chargeAmount}
            onChange={(e) => {
              handleChange(e.target.value, "chargeAmount", "money");
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              label="Data de vencimento original*"
              format="DD/MM/YYYY"
              value={
                formData.originalDueDate
                  ? dayjs(formData.originalDueDate, "YYYY-MM-DD")
                  : null
              }
              onChange={(date) => {
                handleChange(
                  date ? date.format("YYYY-MM-DD") : "",
                  "originalDueDate"
                );
              }}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Aluguel"
            type="text"
            name="rentAmount"
            fullWidth
            value={formData.rentAmount}
            onChange={(e) => {
              handleChange(e.target.value, "rentAmount", "money");
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Condomínio"
            type="text"
            name="condominiumAmount"
            fullWidth
            value={formData.condominiumAmount}
            onChange={(e) => {
              handleChange(e.target.value, "condominiumAmount", "money");
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="IPTU"
            type="text"
            name="iptu"
            fullWidth
            value={formData.iptu}
            onChange={(e) => {
              handleChange(e.target.value, "iptu", "money");
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Outras taxas"
            type="text"
            name="otherTaxes"
            fullWidth
            value={formData.otherTaxes}
            onChange={(e) => {
              handleChange(e.target.value, "otherTaxes", "money");
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            label="Descrição*"
            type="text"
            name="description"
            fullWidth
            value={formData.description}
            multiline
            rows={4}
            onChange={(e) => {
              handleChange(e.target.value, "description");
            }}
          />
        </Grid>

        {showFields && (
          <>

            <Grid item xs={12} md={6}>
              <TextField
                label="Copasa"
                type="text"
                name="copasa"
                fullWidth
                value={formData.copasa}
                onChange={(e) => {
                  handleChange(e.target.value, "copasa", "money");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Cemig"
                type="text"
                name="cemig"
                fullWidth
                value={formData.cemig}
                onChange={(e) => {
                  handleChange(e.target.value, "cemig", "money");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Reparos"
                type="text"
                name="repairs"
                fullWidth
                value={formData.repairs}
                onChange={(e) => {
                  handleChange(e.target.value, "repairs", "money");
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Multa contratual"
                type="text"
                name="contractualFine"
                fullWidth
                value={formData.contractualFine}
                onChange={(e) => {
                  handleChange(e.target.value, "contractualFine", "money");
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <ModalAddFiles
            data={formData.includeFiles}
            setData={(arg: any) => handleChange(arg, "includeFiles")}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

interface Step02Props {
  formData: any;
  setFormData: (arg: any) => void;
}