import { gql } from "@apollo/client";

export const FETCH_PLANS = gql`
  query fetchPlans($keyword: String, $page: Int, $limit:Int) {
    fetchPlans(keyword: $keyword, page: $page, limit: $limit ) {
      total
      current_page
      last_page
      per_page
      data{
        id
        name
        description
        fee
        warranty_months
        max_installments
        setup_fee
        setup_max_installments
      }
  }
} 
`;
