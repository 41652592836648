import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { DELETE_USER } from "graphql/mutation/deleteUser";
import { ME } from "graphql/query/me";
import { useAuthContext } from "context/AuthProvider";
import { MeUsersResponse } from "graphql/gql/graphql";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

interface DeleteUserModalProps {
  open: boolean;
  onClose: () => void;
  user: MeUsersResponse | null;
  me_id: number;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  open,
  onClose,
  user,
  me_id,
}) => {
  const [deleteUserMutation, { loading }] = useMutation(DELETE_USER, {
    refetchQueries: [
      {
        query: ME,
        variables: { id: me_id },
      },
    ],
  });

  const handleDelete = async () => {
    if (user) {
      try {
        const response = await deleteUserMutation({
          variables: { id: user.id },
        });
        enqueueSnackbar("Usuário excluido com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        onClose();
      } catch (err) {
        enqueueSnackbar("Erro ao excluir usuário", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"Confirmação de Exclusão"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Você tem certeza que deseja excluir este usuário?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          color="error"
          disabled={loading}
          autoFocus
        >
          {loading ? <CircularProgress size={24} /> : "Excluir"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
