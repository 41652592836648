import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "graphql/mutation/updateUser";

import { useForm, Controller } from "react-hook-form";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { ME } from "graphql/query/me";
import { useAuthContext } from "context/AuthProvider";

interface EditUserModalProps {
  open: boolean;
  onClose: () => void;
  user: any;
  me_id: number;
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  user,
  me_id,
}) => {
  const { state }: { state: any } = useAuthContext();

  const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    if (open) {
      reset({
        email: user?.email || "",
        status: user?.status || "",
      });
    }
  }, [open, user, reset]);

  const [updateUserMutation, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: ME, variables: { id: me_id } }],
  });

  const handleConfirm = handleSubmit(async ({ email, status }) => {
    try {
      const response = await updateUserMutation({
        variables: {
          id: user.id,
          email,
          status,
        },
      });
      enqueueSnackbar("Usuário atualizado com sucesso", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      onClose();
    } catch (err) {
      enqueueSnackbar("Erro ao atualizar usuário", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"Editar Usuário"}</DialogTitle>
      <DialogContent>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              label="Email"
              type="email"
              fullWidth
            />
          )}
        />

        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select {...field} fullWidth margin="dense">
              <MenuItem value="Ativo">Ativo</MenuItem>
              <MenuItem value="Inativo">Inativo</MenuItem>
            </Select>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={handleConfirm}
          autoFocus
          disabled={loading}
          sx={{
            backgroundColor: "#51d347",
            "&:hover": {
              backgroundColor: "#39a930",
              borderColor: "#0062cc",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Atualizar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
