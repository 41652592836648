import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Cookies from "js-cookie";

const link = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

export const tokenName = "@Renti:token";

const authLink = setContext(async (request, { headers }) => {
  const urlToken = Cookies.get('urlToken');
  const accessToken = Cookies.get(tokenName);

  let token;
  if (accessToken) {
    token = accessToken;
  } else {
    token = urlToken;
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (
    networkError &&
    (networkError.cause === 500 || networkError.cause === 404)
  ) {
    window.location.href = "/login";
  }

  if (graphQLErrors) {
    if (graphQLErrors[0].message === "Signature verification failed") {
      window.location.href = "/login";
    }

    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, link]),
});