import { gql } from "@apollo/client"

export const UPDATE_PERSON = gql`
  mutation UpdatePerson(
    $id: Int!
    $name: String!
    $document: String!
    $phone: String!
    $zipcode: String
    $street: String
    $number: String
    $district: String
    $city_id: Int
    $complement: String
    $reference: String
  ) {
    updatePerson(
      id: $id
      name: $name
      document: $document
      phone: $phone
      zipcode: $zipcode
      street: $street
      number: $number
      district: $district
      city_id: $city_id
      complement: $complement
      reference: $reference
    )
  }
`;
