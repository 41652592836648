import React from "react";
import { Button, CardContent, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { useQuery } from "@apollo/client";
import { COUNTERS_BY_STATUS_CONTRACTS } from "graphql/query/countersByStatusContracts";
import { CircularLoading } from "components/CircularLoading";
import DataDoteDisplay from "components/DataDoteDisplay";

interface StatusProposal {
  status: string;
  total: number;
}
export function CardAmountContracts({
  handleClick
}: CardAmountContractsProps) {

  const { data, loading, error } = useQuery(
    COUNTERS_BY_STATUS_CONTRACTS,
    {
      fetchPolicy: "network-only",
    }
  );

  if (loading) {
    return <CircularLoading />;
  }

  const renderedItems = data?.countersByStatusContracts?.map(
    (item: StatusProposal) => (
      <Grid container spacing={1} key={item.status} display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column">
        <Grid item xs={12} width="100%">
          <DataDoteDisplay label={item.status} value={item.total} />
        </Grid>
      </Grid>
    )
  );

  return (
    <Card
      sx={{
        backgroundColor: "#EEF0F8",
        maxWidth: "100%",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        color: "#2E349F",
        padding: "1rem",
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {renderedItems}
      </CardContent>
      <Button
        variant="contained"
        fullWidth
        onClick={handleClick}
      >
        <b>Filtros</b>
      </Button>
    </Card>
  );
}

interface CardAmountContractsProps {
  handleClick: () => void
}

