import { Box, FormControl, InputAdornment, InputLabel, LinearProgress, OutlinedInput, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { RecoveryItemsProps } from ".";
import PasswordIndicator from "components/PasswordIndicator";
import IconButton from "components/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from "react";

export default function FormPasswords({
    control
}: RecoveryItemsProps) {

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    return (
        <Box>
            <Typography mb={2}>Digita sua nova senha e confirme para realizar a alteração</Typography>
            <Controller
                name="password"
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth variant="outlined" sx={{mt: 2}}>
                        <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput
                            {...field}
                            value={field.value}
                            required
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Senha"
                        />
                        <PasswordIndicator testingPassword={field.value} />
                    </FormControl>
                )}
            />
            <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth variant="outlined" sx={{mt: 3}}>
                        <InputLabel htmlFor="outlined-adornment-password">Confirmar senha</InputLabel>
                        <OutlinedInput
                            {...field}
                            value={field.value}
                            required
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirmar senha"
                        />
                    </FormControl>
                )}
            />
        </Box>
    )
}