import { LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function PasswordIndicator({
    testingPassword
}: PasswordIndicatorProps) {

    const [verifyResult, setVerifyResult] = useState<VerifyResult>({
        label: "Fraca",
        range: 0,
        color: "error"
    })

    function strongPasswordVerify(password: string): VerifyResult {
        
        const comprimentoMinimo = 8;
        const possuiLetrasMinusculas = /[a-z]/.test(password);
        const possuiLetrasMaiusculas = /[A-Z]/.test(password);
        const possuiNumeros = /[0-9]/.test(password);
        const possuiCaracteresEspeciais = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);


        if (password.length == 0) {
            return {
                label: "",
                range: 0,
                color: "info"
            };
        } else if (password.length >= comprimentoMinimo && possuiLetrasMinusculas && possuiLetrasMaiusculas && possuiNumeros && possuiCaracteresEspeciais) {
            return {
                label: "Senha Forte",
                range: 100,
                color: "success"
            };
        } else if (password.length >= comprimentoMinimo && (possuiLetrasMinusculas || possuiLetrasMaiusculas || possuiNumeros)) {
            return {
                label: "Senha Média",
                range: 60,
                color: "warning"
            };
        } else {
            return {
                label: "Senha Fraca",
                range: 20,
                color: "error"
            };
        }
    }

    useEffect(() => {
        setVerifyResult(strongPasswordVerify(testingPassword))
    }, [testingPassword])

    return (
        <>
            <LinearProgress variant="determinate" value={verifyResult.range} sx={{ mt: 0.5 }} color={verifyResult.color || "error"} />
            <Typography variant="caption" fontWeight="bold">{verifyResult.label}</Typography>
        </>
    )
}

interface PasswordIndicatorProps {
    testingPassword: string
}

type VerifyResult = {
    label: string,
    range: number,
    color: "success" | "warning" | "error" | "inherit" | "primary" | "secondary" | "info"
}