import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordIndicator from "components/PasswordIndicator";

interface PasswordFieldProps {
  name: string;
  control: any;
  showPassword: boolean;
  setShowPassword: (show: boolean) => void;
  rules?: any;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  control,
  showPassword,
  setShowPassword,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-password">
          {name === "password" ? "Senha" : "Confirmar senha"}
        </InputLabel>
        <OutlinedInput
          {...field}
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          onChange={(event) => {
            field.onChange(event);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={name === "password" ? "Senha" : "Confirmar senha"}
        />
        {name === "password" && (
          <PasswordIndicator testingPassword={field.value || ""} />
        )}
      </FormControl>
    )}
  />
);

export default PasswordField;
