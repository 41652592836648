import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Chip, Grid } from "@mui/material";
import { maskDate } from "utils/maskOutputs";
import { getStatusColor } from "utils/getStatusColor";

interface CardInfoProps {
  id: number;
  name: string;
  code: string;
  document: string;
  created_at: string;
  updated_at: string;
  status: string;
  fromContract?: boolean;
  contractId?: number;
  fromOccurrence?: boolean;
  occurrenceId?: number;
  typeOccurrence?: string;
}

export const CardInfo: React.FC<CardInfoProps> = ({
  id,
  code,
  name,
  document,
  created_at,
  updated_at,
  status,
  fromContract = false,
  contractId,
  fromOccurrence = false,
  occurrenceId,
  typeOccurrence,
}: CardInfoProps) => {
  const navigate = useNavigate();

  if (!id) {
    return null;
  }

  const location = useLocation();
  let type: "Proposta" | "Contrato" | "Ocorrencia" = "Proposta";

  if (
    location.pathname.includes("contratos")
  ) {
    type = "Contrato";
  } else if (location.pathname.includes("propostas")) {
    type = "Proposta";
  } else if (location.pathname.includes("ocorrencias")) {
    type = "Ocorrencia";
  }

  let linkURI = `/proposta/${id}`;
  const searchParams = new URLSearchParams();
  let stateId = id;

  if (fromContract) {
    linkURI = `/contrato/${id}`;
    if (contractId) {
      searchParams.append("contractId", contractId.toString());
      stateId = contractId;
    }
  } else if (fromOccurrence) {
    linkURI = `/ocorrencia/${id}`;
    if (occurrenceId) {
      searchParams.append("occurrenceId", occurrenceId.toString());
      stateId = occurrenceId;
    }
  }

  const handleClick = () => {
    navigate(linkURI, { state: { id: stateId } });
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        backgroundColor: "#EEF0F8",
        boxShadow: "none",
        paddingTop: 2,
        paddingBottom: 2,
        cursor: "pointer",
      }}
    >
      <CardContent
        sx={{
          color: "#2E349F",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{ fontWeight: "900" }}>Solicitação:</Typography>
            <Typography>{code}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{ fontWeight: "900" }}>Locatário:</Typography>
            <Typography sx={{ fontWeight: "400" }}>{name}</Typography>
            <Typography sx={{ fontWeight: "900" }}>CPF:</Typography>
            <Typography>{document}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            flexDirection="column"
            gap="0.5rem"
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bolder", marginRight: "0.5rem" }}>
                Criado em:
              </Typography>
              <Typography>{maskDate(created_at)}</Typography>
            </Box>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bolder", marginRight: "0.5rem" }}>
                Atualizado em:
              </Typography>
              <Typography>{maskDate(updated_at)}</Typography>
            </Box>

            <Box>
              <Chip
                label={status}
                sx={{
                  fontWeight: "bolder",
                  backgroundColor: getStatusColor(type, status),
                }}
              />
              { type === "Ocorrencia" && (
                <Chip
                label={typeOccurrence}
                sx={{
                  marginLeft: "0.5rem",
                }}
              />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
