import React, { ReactNode, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import EmptyDrawner from "components/EmptyDrawner";
import { verifyUnTokenRoutes } from "utils/helpers";
import { useAuthContext } from "context/AuthProvider";

interface LayoutProps {
  children: ReactNode;
}
export function Layout({ children }: LayoutProps) {

  const navigate = useNavigate()
  const { TOKEN_USER } = useAuthContext();

  const [isExternalPage, setIsExternalPage] = useState(false)

  const isViewContractPage = location.pathname.startsWith('/termos-de-uso');

  let DrawnerBar;
  if (isExternalPage || isViewContractPage) {
    DrawnerBar = EmptyDrawner
  } else {
    DrawnerBar = Header
  }

  useEffect(() => {
    const verifyPaths = verifyUnTokenRoutes()

    if (!TOKEN_USER && !verifyPaths) {
      navigate("/login");
    }
    setIsExternalPage(verifyPaths)
  }, [TOKEN_USER])

  return (
    <>
      <Grid container>
        <Grid style={{ width: "100vw" }}>
          <DrawnerBar>
            {children}
          </DrawnerBar>
        </Grid>
      </Grid>
    </>
  );
}