import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DataReview from "./DataReview";
import useVerifyScreen from "hooks/useVerifyScreen";
import AcceptContract from "./AcceptContract";
import { SIGN_CONTRACT } from "graphql/mutation/signContract";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack"
import { QueryRoot } from "graphql/gql/graphql";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import Cookies from "js-cookie";

export default function SignContract() {
  const navigate = useNavigate();
  const isMobile = useVerifyScreen();
  const { code } = useParams<{ code: string }>();
  const location = useLocation();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { data, loading, error, refetch } = useQuery<QueryRoot>(DETAIL_PROPOSAL, {
    variables: {
      code,
    },
  });

  const contractIsSigned = data ? data?.detailProposal?.contract !== null : false;

  const [signContract, { loading: loadingContract }] = useMutation(SIGN_CONTRACT);

  const handleSubmit = async () => {
    try {
      const result = await signContract({
        variables: {
          proposal_code: code
        },
        onCompleted: () => {
          refetch();
        }
      })

      if (result) {
        enqueueSnackbar("Contrato assinado com sucesso!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        })


        const newSignedAt = data?.detailProposal?.signatures?.map((signature) => signature?.signed_at);
        const newAllSigned = newSignedAt?.every((date) => date !== null) ?? false;

        if (newAllSigned) {
          navigate(`/visualizar-contrato/${code}`);
        }
      }
      setButtonDisabled(true);
    } catch (e: any) {
      enqueueSnackbar(e.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      })
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      Cookies.set('urlToken', token, { expires: 1/48 });
    } else {
      navigate('/login');
    }
  }, []);
  return (
    <Box p="2%">
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img src="/assets/logo_blue.png" alt="logo" />
        <Typography mt={2} variant="h5" color="primary" fontWeight="bold">
          Assinatura de Contrato Locatício e Pagamento
        </Typography>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <Container>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          error ? (
            <>
              <Typography variant="h6">
                Erro ao carregar os dados
              </Typography>
            </>
          ) : (
            <Grid
              container
              spacing={3}
              direction={isMobile ? "column-reverse" : "row"}
            >
              <Grid item xs={12} md={8}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap={2}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      color="primary"
                    >
                      Termos do contrato
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                    >
                      Leia o contrato e, caso esteja de acordo com os termos, sinalize para efetuar sua assinatura
                    </Typography>
                  </Box>


                  <AcceptContract
                    checked={acceptTerms || contractIsSigned}
                    handleChange={() => setAcceptTerms(!acceptTerms)}
                    disabled={buttonDisabled || loadingContract || contractIsSigned}
                  />


                  <Button
                    disabled={!acceptTerms || loadingContract || buttonDisabled}
                    variant="contained"
                    onClick={handleSubmit}
                    size="large"
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {loading && <CircularProgress size={20} style={{ marginRight: 8 }} />}
                    Assinar
                  </Button>
                </Box>

              </Grid>
              <Grid item xs={12} md={4}>
                <DataReview data={data} />
              </Grid>
            </Grid>
          )
        )}
      </Container>
    </Box>
  );
}
