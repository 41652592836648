import { Theme, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";

export default function useVerifyScreen() {

    const theme: Theme = useTheme()
    const isResponsivity: boolean = useMediaQuery(theme.breakpoints.down('md'));

    return isResponsivity;

}