import { useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FetchContractsResponse,
  FetchProposalsResponse,
  QueryRoot,
} from "graphql/gql/graphql";
import { FETCH_CONTRACTS } from "graphql/query/fetchContracts";
import { FETCH_PROPOSALS } from "graphql/query/fetchProposals";
import { useLocation } from "react-router-dom";
import { ContractTable } from "./ContractTable";
import { ProposalTable } from "./ProposalTable";

import { useNavigate } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

export function ReportDetail() {
  const {
    data: dataContracts,
    loading: loadingContracts,
    error: errorContracts,
  } = useQuery<QueryRoot>(FETCH_CONTRACTS);
  const {
    data: dataProposals,
    loading: loadingProposals,
    error: errorProposals,
  } = useQuery<QueryRoot>(FETCH_PROPOSALS);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1)
  }

  if (loadingContracts || loadingProposals) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }
  if (errorContracts || errorProposals) {
    return <Typography>Erro ao carregar dados</Typography>;
  }

  const contracts = (dataContracts?.fetchContracts?.data || []).filter(
    (contract): contract is FetchContractsResponse =>
      contract !== null && contract !== undefined && contract.status === status
  );

  const proposals = (dataProposals?.fetchProposals?.data || []).filter(
    (proposal): proposal is FetchProposalsResponse =>
      proposal !== null && proposal !== undefined && proposal.status === status
  );

  const type = queryParams.get("type");
  const isContractStatus = type === "contrato";
  const isProposalStatus = type === "proposta";

  if (
    (isContractStatus && contracts.length === 0) ||
    (isProposalStatus && proposals.length === 0)
  ) {
    return <Typography>Não há dados disponíveis</Typography>;
  }

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={handleBack}>
          <ArrowBackIosRoundedIcon sx={{ color: "#2E349F" }} />
        </IconButton>
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "bolder",
            color: "#2E349F",
            textAlign: "start",
          }}
        >
          {status} ({type})
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>SOLICITAÇÃO</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>INQUILINO</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>CPF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isContractStatus &&
              contracts.map((contract) => (
                <ContractTable
                  key={contract.id}
                  code={contract.proposal?.code || ""}
                  name={contract.proposal?.tenant?.person?.name || ""}
                  document={contract.proposal?.tenant?.person?.document || ""}
                />
              ))}
            {isProposalStatus &&
              proposals.map((proposal) => (
                <ProposalTable
                  key={proposal.id}
                  code={proposal.code || ""}
                  tenant={proposal.tenant?.name || ""}
                  document={proposal.tenant?.document || ""}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
