import { gql } from "@apollo/client";

export const DETAIL_PROPOSAL = gql`
  query detailProposal($id: Int, $code: String) {
    detailProposal(id: $id, code: $code) {
      code
      rent_amount
      condominium_amount
      other_taxes
      iptu
      chosen_plan
      guarantee_fee
      warranty_months
      chosen_installments
      chosen_setup_fee
      chosen_setup_installments
      status
      created_at
      history {
        title
        person_id
        person_name
        description
        updated_at
      }
      real_estate {
        id
        name
        document
        phone
        email
      }
      credit_analysis {
        id
        risk
        status
        situation
        created_at
        updated_at
      }
      immobile {
        id
        zipcode
        street
        number
        complement
        reference
        district
        city {
          name
          state
        }
      }
      plan {
        id
        name
        description
        fee
        warranty_months
        max_installments
        setup_fee
        setup_max_installments
        history {
          name
          description
          fee
          warranty_months
          max_installments
          setup_fee
          setup_max_installments
          user_id
          updated_at
        }
      }
      tenant {
        id
        name
        document
        birth_date
        email
        phone
        income
        extras
        is_payment_responsible
        created_at
      }
      co_responsibles {
        id
        is_payment_responsible
        person {
          id
          name
          document
          birth_date
          email
          phone
        }
      }
      attachments {
        id
        name
        type
        url
        created_at
      }
      contract {
        id
        payment_responsible_id
        start
        finish
        status
        updated_at
        created_at
        occurrences {
          contract_id
          type
          status
        }
      }
      signatures {
        person {
          id
          name
        }
        status
        signed_at
      }
    }
  }
`;
