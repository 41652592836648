import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, Typography } from "@mui/material"; 7
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { unmaskMoney } from "utils/unmaskOutputs";
import { DETAIL_PROPOSAL_SIMULATION } from "graphql/query/detailProposalSimulation";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";


export default function ModalCreditAnalyze({
    open,
    handleClose,
    formValues,
    proposalId,
    preCreditAnalysis = null,
    fromFormProposal = false
}: ModalCreditAnalyzeProps) {

    const [creditAnalysis, setCreditAnalysis] = useState<any>(preCreditAnalysis)

    const [shouldPoll, setShouldPoll] = useState(true);
    const { data, loading } = useQuery(fromFormProposal ? DETAIL_PROPOSAL : DETAIL_PROPOSAL_SIMULATION, {
        variables: {
            id: proposalId
        },
        fetchPolicy: "no-cache",
        pollInterval: shouldPoll || !creditAnalysis ? 4000 : 0,
    });

    useEffect(() => {
        if (data && !creditAnalysis) {
            const detailData = fromFormProposal ? data.detailProposal : data.detailProposalSimulation;
            if (detailData) {
                const { credit_analysis } = detailData;

                if (credit_analysis.status === 'Concluído') {
                    setCreditAnalysis(credit_analysis)
                    setShouldPoll(false);
                }
            }
        }
    }, [data, setShouldPoll, fromFormProposal]);


    let RenderComponent = Attention;
    switch (creditAnalysis?.risk) {
        case "Aprovada":
            RenderComponent = Approved
            break
        case "Reprovada":
            RenderComponent = Denied
            break
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                }}
            >
                {
                    (!creditAnalysis || creditAnalysis.risk == null) &&
                    <WaitingResponse />
                }
                {
                    (formValues && creditAnalysis && creditAnalysis.risk != null) &&

                    <RenderComponent {...{ formValues, creditAnalysis, proposalId, fromFormProposal }} tenantDoc={formValues?.cpf} tenantName={formValues?.nome} />
                }
            </DialogContent>
        </Dialog>
    )

}

function WaitingResponse() {
    return (
        <>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", padding: "24px 0 24px 0" }}>
                <CircularProgress size={70} sx={{ color: "#51D347" }} />
            </Box>
            <Typography variant="h6" fontWeight="bold" color="#2E349F" textAlign="center">Estamos realizando a consulta, aguarde um momento...{"\n"}
                Essa operação pode demorar alguns minutos</Typography>
        </>
    )
}

function Approved({
    tenantName,
    tenantDoc,
    formValues,
    creditAnalysis,
    fromFormProposal,
    proposalId
}: AnalyzeVariantProps) {
    return (
        <>
            <CheckCircleIcon sx={{ color: "#51D347", fontSize: 70 }} />
            <Typography variant="h5" fontWeight="bold" color="#2E349F">
                O crédito do locatário foi aprovado
            </Typography>
            <Typography mb={4} color="#2E349F">
                O locatário <b>{tenantName}</b> de CPF <b>{tenantDoc}</b> foi aprovado{
                    fromFormProposal ?
                        " e sua proposta foi cadastrada com sucesso!"
                        :
                        ". Deseja iniciar o cadastro de uma proposta agora?"
                }
            </Typography>
            {
                <Link
                    to={
                        fromFormProposal ?
                            `/proposta/${proposalId}`
                            :
                            "/cadastrarProposta"
                    }
                    state={
                        fromFormProposal ?
                            {}
                            :
                            { formValues, creditAnalysis, proposalId }
                    }
                    style={{ textAlign: "center" }}
                >
                    <Button variant="contained"
                        sx={{
                            backgroundColor: "#51d347",
                            '&:hover': {
                                backgroundColor: '#39a930',
                                borderColor: '#0062cc',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                            },
                        }}
                    >
                        {
                            fromFormProposal ?

                                "Ver proposta"

                                :

                                "Iniciar cadastro de proposta"
                        }
                    </Button>
                </Link>
            }
        </>
    )
}

function Denied({
    tenantName,
    tenantDoc
}: AnalyzeVariantProps) {

    const navigate = useNavigate()
    const location = useLocation();

    const handleRedirect = () => {
        const actualPath = location.pathname
        actualPath == "/dashboard" ?
            navigate(0) : navigate("/dashboard")
    }

    return (
        <>
            <CancelIcon sx={{ color: "#FF2727", fontSize: 70 }} />
            <Typography variant="h5" fontWeight="bold" color="#2E349F">
                O crédito do locatário não foi aprovado
            </Typography>
            <Typography mb={4} color="#2E349F">
                O locatário <b>{tenantName}</b> de CPF <b>{tenantDoc}</b> não foi aprovado.
            </Typography>
            <Box style={{ textAlign: "center" }}>
                <Button variant="contained"
                    onClick={handleRedirect}
                    sx={{
                        minWidth: 200,
                        backgroundColor: "#51d347",
                        '&:hover': {
                            backgroundColor: '#39a930',
                            borderColor: '#0062cc',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                        },
                    }}
                >
                    Ok
                </Button>
            </Box>
        </>
    )
}

function Attention({
    tenantName,
    tenantDoc,
    formValues,
    creditAnalysis,
    fromFormProposal,
    proposalId
}: AnalyzeVariantProps) {
    return (
        <>
            <InfoIcon sx={{ color: "#F1C425", fontSize: 70 }} />
            <Typography variant="h5" fontWeight="bold" color="#2E349F">
                É necessária uma comprovação de crédito
            </Typography>
            <Typography mb={4} color="#2E349F">
                O locatário <b>{tenantName}</b> de CPF <b>{tenantDoc}</b> foi pré-aprovado{
                    fromFormProposal ?
                        " e sua proposta foi cadastrada, mas será necessário um envio de documentação externa para comprovar crédito e continuar o processo de locação."
                        :
                        ", mas é necessária uma comprovação de crédito do mesmo. Deseja iniciar o cadastro de uma proposta agora?"
                }
            </Typography>
            {
                <Link
                    to={
                        fromFormProposal ?
                            `/proposta/${proposalId}`
                            :
                            "/cadastrarProposta"
                    }
                    state={
                        fromFormProposal ?
                            {}
                            :
                            { formValues, creditAnalysis }
                    }
                    style={{ textAlign: "center" }}
                >
                    <Button variant="contained"
                        sx={{
                            backgroundColor: "#51d347",
                            '&:hover': {
                                backgroundColor: '#39a930',
                                borderColor: '#0062cc',
                                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                            },
                        }}
                    >
                        {
                            fromFormProposal ?

                                "Enviar Documentação"

                                :

                                "Iniciar cadastro de proposta"
                        }
                    </Button>
                </Link>
            }
        </>
    )
}


interface AnalyzeVariantProps {
    tenantName?: string
    tenantDoc?: string,
    formValues?: any,
    creditAnalysis?: any
    fromFormProposal?: boolean
    proposalId: number
}

interface ModalCreditAnalyzeProps extends AnalyzeVariantProps {
    open: boolean
    handleClose: (args: boolean) => void,
    preCreditAnalysis?: any
}
