import { gql } from "@apollo/client";

export const FETCH_STATES = gql`
  query fetchStates($keyword: String) {
    fetchStates(keyword: $keyword) {
      id
      name
  }
} 
`;
