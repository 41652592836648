import React from "react";
import {
  CssBaseline,
  ThemeProvider,
  Container,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";

import logo from "../../assets/logo.png";
import theme from "../../theme";
import Footer from "components/Footer";
import { FormLogin } from "./FormLogin";
import { useParams } from "react-router-dom";

export function Login() {

  let { token } = useParams();

  const isPhone = useMediaQuery("(max-width: 400px) ");

  return (
    <>
      <Container
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: isPhone ? "" : "center",
          maxWidth: "100vw",
          height: "100vh",
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
          gap: "1rem",
          paddingTop: isPhone ? "1rem" : "",
        }}
      >
        <CssBaseline />

        <img src={logo} alt="logo Renti" width="250px" />

        <Typography
          variant="h1"
          align="center"
          sx={{ fontSize: isPhone ? "2.5rem" : "3rem", fontWeight: "900" }}
        >
          Bem-Vindo!
        </Typography>

        <Typography
          variant="h6"
          align="center"
          sx={{ fontSize: "1.5rem", fontWeight: "900" }}
        >
          Aluguel descomplicado, rápido e fácil!
        </Typography>
        
        <FormLogin token={token || null}  />
        
        <Footer />
      </Container>
    </>
  );
}
