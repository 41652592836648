
import { Box, Button, Grid, Typography } from "@mui/material";
import CardWrapper from "components/CardWrapper";
import InfoIcon from "@mui/icons-material/Info";

import { Link } from "react-router-dom";

export function CardTermOfUse({ code }: { code: string; }) {
  return (
    <CardWrapper>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "900",
            color: "#2E349F",
          }}
        >
          Termos e condições de uso
        </Typography>
      </Box>
      <Grid container spacing={2} mt={4} mb={4}>
        {!code ? (
          <Grid item xs={12} display="flex" gap={1}>
            <InfoIcon />
            <Typography>Nenhum termo de uso adicionado </Typography>
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Link to={`/termos-de-uso/${code}`} target="_blank">
              <Button variant="outlined">
                Visualizar
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </CardWrapper>
  );
}
