import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser(
    $real_estate_id: Int!
    $email: String!
    $status: String!
    $password: String!
  ) {
    createUser(
      real_estate_id: $real_estate_id
      email: $email
      status: $status
      password: $password
    )
  }
`;
