import { useQuery } from "@apollo/client";

import { CircularProgress, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { FetchProposals } from "types/graphql/querie/fetchProposals";
import { FETCH_PROPOSALS } from "graphql/query/fetchProposals";
import { useAuthContext } from "context/AuthProvider";

import RegisteredProposalCard from "./RegisteredProposalCard";
import { Card } from "pages/Dashboard/Card";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export function Dashboard() {
  const { state } = useAuthContext()

  const { data: dataProposals, loading: loadingProposals } = useQuery<FetchProposals>(FETCH_PROPOSALS, {
    variables: {
      limit: 10
    },
    fetchPolicy: "network-only",
  });
  const proposals = dataProposals?.fetchProposals?.data || [];

  return (
    <Box>
      <Grid container spacing={1}>

        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "2rem",
              color: "#2E349F",
              fontWeight: "bolder"
            }}>
            Olá, {state?.person.name || ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "#2E349F"
            }}
          >
            Esta é a dashboard da Renti. Aqui você tem acesso rápido às suas
            propostas e simulações de proposta de aluguéis.
          </Typography>
        </Grid>

        <Grid item container xs={12}>
          <Card />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontSize: "1.6rem",
              color: "#2E349F",
              fontWeight: "bolder",
              marginBottom: 2
            }}>
            Últimas propostas cadastradas
          </Typography>

          {
            loadingProposals ?
              <Stack alignItems="center" mt={6}>
                <CircularProgress />
              </Stack>

              :

              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                sx={{
                  overflow: "auto",
                  width: "100%",
                  paddingTop: 2,
                  paddingBottom: 2,
                  mr: 2
                }}
              >
                {
                  proposals.map((proposal) => (
                    <RegisteredProposalCard key={proposal.id} proposal={proposal} />
                  ))
                }
              </Box>
          }
        </Grid>
      </Grid>
    </Box >
  );
}
