import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Container, Divider, Grid, Typography } from "@mui/material";
import { QueryRoot } from "graphql/gql/graphql";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";

import useVerifyScreen from "hooks/useVerifyScreen";
import DataReview from "pages/SignContract/DataReview";
import { useParams } from "react-router-dom";

export default function ViewContract() {
  const isMobile = useVerifyScreen();

  const { code } = useParams<{ code: string }>();

  const { data, loading, error } = useQuery<QueryRoot>(DETAIL_PROPOSAL, {
    variables: {
      code,
    },
  });

  return (
    <Box p="2%">
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img src="/assets/logo_blue.png" alt="logo" />
        <Typography mt={2} variant="h5" color="primary" fontWeight="bold">
          Visualização de Contrato
        </Typography>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <Container>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          error ? (
            <>
              <Typography variant="h6">
                Erro ao carregar os dados
              </Typography>
            </>
          ) : (
            <Grid
              container
              spacing={3}
              direction={isMobile ? "column-reverse" : "row"}
            >
              <Grid item xs={12} md={12}>
                <DataReview
                  data={data}
                />
              </Grid>
            </Grid>
          )
        )}
      </Container>
    </Box>
  );
}
