import React from "react";
import Pagination from "@mui/material/Pagination";

type CustomPaginationProps = {
  totalItems?: number;
  page: number;
  limit: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

export const CustomPagination: React.FC<CustomPaginationProps> = ({
  totalItems,
  page,
  onChange,
  limit,
}) => {
  let totalPages = 0;
  if (totalItems !== undefined) {
    totalPages = Math.ceil(totalItems / limit);
  }

  return <Pagination count={totalPages} page={page} onChange={onChange} />;
};
