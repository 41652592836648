import { gql } from "@apollo/client";

const FETCH_OCCURRENCES = gql`
  query FetchOccurrences(
    $keyword: String
    $start: String
    $finish: String
    $order_by: String
    $direction: String
    $page: Int
    $limit: Int
  ) {
    fetchOccurrences(
      keyword: $keyword
      start: $start
      finish: $finish
      order_by: $order_by
      direction: $direction
      page: $page
      limit: $limit
    ) {
      total
      current_page
      per_page
      last_page
      data {
        id
        type
        status
        value
        dead_line
        description
        created_at
        updated_at
        contract {
          id
          start
          finish
          status
          created_at
          updated_at
          proposal {
            id
            code
            rent_amount
            condominium_amount
            other_taxes
            iptu
            chosen_plan
            guarantee_fee
            warranty_months
            chosen_installments
            chosen_setup_fee
            chosen_setup_installments
            status
            created_at
            updated_at
            tenant {
              person {
                id
                name
                document
                birth_date
                email
                phone
              }
            }
          }
          payment_responsible {
            id
            name
            document
            birth_date
            email
            phone
          }
        }
      }
    }
  }
`;

export default FETCH_OCCURRENCES;
