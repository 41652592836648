import { gql } from "@apollo/client";

export const FETCH_CITIES = gql`
  query fetchCities($keyword: String, $limit:Int, $state: String) {
    fetchCities(keyword: $keyword, limit: $limit, state: $state ) {
      id
      name
      state
  }
} 
`;
