import { gql } from "@apollo/client";

export const FETCH_CONTRACTS = gql`
  query fetchContracts($keyword: String, $status: String, $order_by: String, $direction: String,$page: Int, $limit: Int) {
    fetchContracts(keyword: $keyword, status: $status, order_by: $order_by, direction: $direction, page: $page, limit: $limit) {
      total
      current_page
      per_page
      last_page
      data {
        id
        start
        finish
        status
        created_at
        updated_at
        proposal{
            id
            code
            tenant{
                person{
                    name
                    document
                }
            }
            immobile {
              zipcode
              street
              number
              reference
              district
              city {
                id
                name
                state
              }
            }
        }
      }
    }
  }
`;