import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

interface CustomLoadingButtonProps extends LoadingButtonProps {
  textColor?: string;
  width?:string;
  height?:string;
  backgroundColor?:string;
  borderColor?:string;
  fontSize?:string

}
const loadingButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomLoadingButtonProps
> = (
  {
    onClick,
    children,
    type = "button",
    variant,
    textColor,
    color,
    backgroundColor,
    loading,
    disabled,
    width,
    height,
    borderColor,
    fontSize

  },
  ref
) => {

  const buttonStyles = {
    width: width || "auto", 
    height: height || "auto",
    color: textColor,
    backgroundColor: backgroundColor,
    fontSize: fontSize || "1rem",
    borderColor: borderColor,
  };
  return (
    <LoadingButton
      loading={loading}
      onClick={onClick}
      variant={variant}
      type={type}
      ref={ref}
      color={color}
      disabled={disabled}
      style={buttonStyles}
  
    >
      {children}
    </LoadingButton>
  );
};

export default forwardRef(loadingButton);
