import {
  AccessTime,
  Block,
  Cancel,
  ErrorOutline,
  HourglassEmpty,
  Payment,
  ReportProblem,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";

interface ReportListItemProps {
  onClick: () => void;
  icon: React.ReactElement;
  text: string;
  color: string;
}

export const ReportListItem: React.FC<ReportListItemProps> = ({
  onClick,
  icon,
  text,
  color,
}) => (
  <ListItem
    onClick={onClick}
    sx={{
      cursor: "pointer",
      "&:hover": { backgroundColor: "#f5f5f5" },
      transition: "background-color 0.3s ease",
    }}
  >
    <ListItemIcon>{React.cloneElement(icon, { sx: { color } })}</ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);
