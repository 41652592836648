
import CardPlanModel from "components/CardPlan/CardPlanModel";
import { CircularProgress, Grid } from "@mui/material";
import { DataElPlan } from "types/CreateProposal";

export function CardPlan({
  data,
  value,
  setSelectedPlan
}: CardPlanProps) {

  const handlePlanChange = (item: DataElPlan) => {
    setSelectedPlan(item);
  };

  if (!data) return (
    <CircularProgress size={20} />
  )

  return (
    <>
      <Grid container spacing={1} display="flex" gap="2rem" >
        {
          data.map((el: DataElPlan, index: number) => {

            const feeCount = el.fee.toFixed(2)

            return (
              <Grid
                item
                xs={12}
                sm={5}
                md={3}
                key={index}
              >
                <CardPlanModel
                  id={el.id || 0}
                  planName={el.name}
                  description={[
                    `Garantia de ${el.warranty_months}x o valor do aluguel em caso de inadimplência`,
                    `Taxa de ${feeCount}%`,
                  ]}
                  selectedPlan={value}
                  onPlanChange={() => handlePlanChange(el)}
                />
              </Grid>
            )
          })
        }
      </Grid>

    </>
  );
}

interface CardPlanProps {
  data: any
  value: number,
  setSelectedPlan: (args: DataElPlan) => void
}
