import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingButton from "components/LoadingButton";
import { Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function RegisteredProposalCard({ proposal }: any) {
  
  const navigate = useNavigate();

  if (!proposal) {
    return null;
  }
  const isPhone = useMediaQuery("(max-width: 400px) ");
  const textStyle = {
    color: "#2E349F",
    fontSize: isPhone ? "1rem" :"1.2",
  };
  
  const handleRedirect = () => {
    navigate(`/proposta/${proposal.id}`);
  }

  return (
    <Box>
      <Card
        sx={{ width:"300px", backgroundColor: "#EEF0F8", boxShadow: "none" }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            wordWrap: "break-word",
            gap:"0.3rem"
          }}
        >
          <Typography sx={{ fontWeight: "900"}} style={textStyle}>
            Código:{" "}
          </Typography>
          <Typography sx={{ fontWeight: "400" ,width: "300px" ,textAlign:"center"}} style={textStyle}>
            {proposal.code}
          </Typography>
          <Typography sx={{ fontWeight: "900" }} style={textStyle}>
            Nome:
          </Typography>
          <Typography sx={{ fontWeight: "400" }} style={textStyle}>
            {proposal.tenant.name}
          </Typography>
          <Typography sx={{ fontWeight: "900" }} style={textStyle}>
            CPF:
          </Typography>
          <Typography sx={{ fontWeight: "400" }} style={textStyle}>
            {proposal.tenant.document}
          </Typography>
          <Typography sx={{ fontWeight: "900" }} style={textStyle}>
            Status:
          </Typography>
          <Typography sx={{ fontWeight: "400" }} style={textStyle}>
            {" "}
            {proposal.status}
          </Typography>
          <Box sx={{ padding: "1rem 0 1rem 0" }}>
            <LoadingButton
              variant="contained"
              type="submit"
              backgroundColor="#51D347"
              width="250px"
              onClick={handleRedirect}
            >
              Ver mais
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

