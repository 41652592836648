import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Typography,
} from "@mui/material";

import { QueryRoot } from "graphql/gql/graphql";
import { useLocation } from "react-router-dom";
import { maskCPF, maskMoney, maskPhone } from "utils/maskOutputs";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface DataReviewProps {
  data: QueryRoot | undefined;
}

export default function DataReview({ data }: DataReviewProps) {
  const location = useLocation();

  const isViewContract = location.pathname.startsWith("/visualizar-contrato");

  const tenant = data ? data?.detailProposal?.tenant : null;
  const proposal = data ? data?.detailProposal : null;
  const status = data ? data?.detailProposal?.status : null;
  const signature = data ? data?.detailProposal?.signatures : null;

  const installment =
    proposal?.rent_amount! +
    proposal?.condominium_amount! +
    proposal?.iptu! +
    proposal?.other_taxes!;

    const guarantee_fee_percentage = (proposal?.guarantee_fee!).toFixed(2);
    const guarante_fee = installment * (proposal?.guarantee_fee! / 100);

    const guarante_fee_money = guarante_fee.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

  return (
    <Alert severity={isViewContract ? "success" : "info"}>
      <AlertTitle color={isViewContract ? "#2e7d32" : "#329fdb"}>
        <b>{isViewContract ? "Contrato realizado" : "Revisão dos Dados"}</b>
      </AlertTitle>


      <Grid container mt={2} spacing={1}>
        <Grid item xs={12}>
          <LabelPrinter title="Status" label={`${status}`} />
        </Grid>
        <Grid item xs={12} >
          <Typography fontWeight="bold">Nome</Typography>
          {signature && signature.length > 0 && signature.map((sig, index) => (
            <Box display="flex" justifyContent="space-between" key={index}>
              <Typography>{sig?.person?.name}</Typography>
              {sig?.signed_at ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}
            </Box>
          ))}
        </Grid>
        <Grid item xs={12}>
          <LabelPrinter title="Contrato locatício" label="Aluguel" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid container spacing={1} mt={3}>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter title="Nome completo" label={tenant?.name!} />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter title="Cpf" label={maskCPF(tenant?.document!)} />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter title="Email" label={tenant?.email!} />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter title="Telefone" label={maskPhone(tenant?.phone!)} />
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={3}>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="Valor do aluguel"
              label={maskMoney(proposal?.rent_amount)}
            />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="Valor do condomínio"
              label={`${maskMoney(proposal?.condominium_amount)}`}
            />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="IPTU"
              label={`${maskMoney(proposal?.iptu)}`}
            />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="Outras taxas"
              label={`${maskMoney(proposal?.other_taxes)}`}
            />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="Parcela"
              label={`${maskMoney(installment)}`}
            />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="Setup da imobiliária"
              label={`${maskMoney(proposal?.chosen_setup_fee)} (${proposal?.chosen_setup_installments}x)`}
            />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="Plano selecionado"
              label={`${proposal?.chosen_plan}`}
            />
          </Grid>
          <Grid item xs={12} md={isViewContract ? 3 : 12}>
            <LabelPrinter
              title="Taxa única do plano"
              label={`${guarante_fee_money} (${guarantee_fee_percentage}%)`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Alert>
  );
}

function LabelPrinter({ title, label }: LabelPrinterProps) {
  return (
    <>
      <Typography fontWeight="bold">{title}</Typography>
      <Typography>{label}</Typography>
    </>
  );
}

interface LabelPrinterProps {
  title: string;
  label: string;
}
