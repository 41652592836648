import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import UpdateLocation from "./Proposal/UpdateLocation";
import UpdatePayment from "./Proposal/UpdatePayment";
import UpdateTentant from "./Proposal/UpdateTenant";
import UpdateDefaulter from "./Occurrence/UpdateDefaulter";
import UpdateExtraTentant from "./Proposal/UpdateExtraTenant";

export default function ModalEdit({
  open,
  handleClose,
  title,
  form,
  formDetail,
  isOccurrence,
  occurrenceId,
}: ModalEditProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogTitle id="alert-dialog-title">
          Editar {title.toLowerCase()}
        </DialogTitle>
        {form == "location" && isOccurrence ? (
          <UpdateDefaulter data={formDetail.rentalData} occurrenceId={occurrenceId} onClose={handleClose} />
        ) : form == "location" ? (
          <UpdateLocation data={formDetail.rentalData} onClose={handleClose} />
        ) : null}
        {form == "payment" &&
          <UpdatePayment data={formDetail.paymentDetails} onClose={handleClose} />
        }
        {form == "tenant" && (
          <UpdateTentant data={formDetail.tenantData} onClose={handleClose} />
        )}
        {form == "extraTenant" && (
          <UpdateExtraTentant data={formDetail.tenantData} onClose={handleClose} />
        )}
      </DialogContent>
    </Dialog >
  );
}

interface ModalEditProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  form: "tenant" | "extraTenant" | "payment" | "location";
  formDetail: any;
  isOccurrence?: boolean;
  occurrenceId?: number;
}
