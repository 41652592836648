import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import useVerifyScreen from 'hooks/useVerifyScreen';

export default function DataDoteDisplay({ label, value }: DataDoteDisplay) {

    const isMobile = useVerifyScreen()

    return (
        <Box
            display="flex"
            width="100%"
        >
            <Typography
                fontSize={isMobile ? 15 :20}
                fontWeight="bold"
            >
                {`${label} `}
            </Typography>


            <Box
                sx={{
                    flex: 1,
                    minWidth: 30,
                    borderBottom: "1px dotted #2E349F",
                    marginLeft: 1,
                    marginRight: 1
                }}
            ></Box>

            <Typography
                fontSize={isMobile ? 15 :20}
                fontWeight="bold"
            >
                {` ${value}`}
            </Typography>
        </Box>
    );
}

interface DataDoteDisplay {
    label: string,
    value: number | string
}