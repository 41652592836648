import { TextField, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { RecoveryItemsProps } from ".";

export default function FormEmail({
    control
}: RecoveryItemsProps) {
    return (
        <>
            <Typography mb={2}>Enviaremos um email com um código e instruções de como redefinir sua senha.</Typography>
            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        id="outlined-basic"
                        label="E-mail"
                        variant="outlined"
                        required
                        value={field.value}
                    />
                )}
            />

        </>
    )
}