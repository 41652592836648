export function getStatusColor(
  type: "Contrato" | "Proposta" | "Ocorrencia",
  status: string
): React.CSSProperties {
  if (type === "Contrato") {
    switch (status) {
      case "Inadimplente":
        return { backgroundColor: "#FF2727", color: "white" };
      case "Encerrado":
        return { backgroundColor: "#757575", color: "white" };
      case "Ativo":
        return { backgroundColor: "#4CAF50", color: "white" };
      default:
        return { backgroundColor: "grey", color: "white" };
    }
  } else if (type === "Proposta") {
    switch (status) {
      case "Aguardando assinatura":
        return { backgroundColor: "#F1C425", color: "white" };
      case "Aguardando análise":
        return { backgroundColor: "#FF9800", color: "white" };
      case "Recusada":
        return { backgroundColor: "#FF2727", color: "white" };
      case "Aprovada":
        return { backgroundColor: "#4CAF50", color: "white" };
      case "Aguardando pagamento":
        return { backgroundColor: "#3F51B5", color: "white" };
      case "Resolver pendências":
        return { backgroundColor: "#FF9800", color: "white" };
      default:
        return { backgroundColor: "grey", color: "white" };
    }
  } else if (type === "Ocorrencia") {
    switch (status) {
      case "Cancelado":
        return { backgroundColor: "#757575", color: "white" };
      case "Pago":
        return { backgroundColor: "#4CAF50", color: "white" };
      case "Pendente":
        return { backgroundColor: "#FF9800", color: "white" };
      case "Exonerado":
        return { backgroundColor: "#FF2727", color: "white" };
      default:
        return { backgroundColor: "grey", color: "white" };
    }
  }

  return { backgroundColor: "grey", color: "white" };
}