import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useEffect, useState } from "react";
import Step01 from "./Steps/Step01";
import Step02 from "./Steps/Step02";
import Step03 from "./Steps/Step03";
import useVerifyScreen from "hooks/useVerifyScreen";
import CREATE_OCCURRENCE from "graphql/mutation/createOccurrence";
import { useMutation } from "@apollo/client";
import { unmaskMoney } from "utils/unmaskOutputs";
import { enqueueSnackbar } from "notistack";
import FETCH_OCCURRENCES from "graphql/query/fetchOccurrences";

export default function ModalAddContract({
  open,
  handleClose,
  contractId,
}: ModalAddContractProps) {
  const isMobile = useVerifyScreen();

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    step01: {
      id: contractId ? contractId : null,
    },
    step02: {
      type: "Simples",
      rentAmount: "",
      condominiumAmount: "",
      iptu: "",
      otherTaxes: "",
      chargeAmount: "",
      originalDueDate: "",
      description: "",
      copasa: "",
      cemig: "",
      repairs: "",
      contractualFine: "",
      includeFiles: [],
    },
  });

  const [createOccurrence, { loading, error }] = useMutation(
    CREATE_OCCURRENCE,
    {
      refetchQueries: [{ query: FETCH_OCCURRENCES, variables: { page: 1 } }],
    }
  );

  const steps = [
    {
      label: "Incluir dados da inadimplência",
      content: <Step02 {...{ setFormData }} formData={formData.step02} />,
    },
    {
      label: "Revisar dados",
      content: <Step03 {...{ formData }} contractId={contractId || 0} />,
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateData = (data: FormDataProps) => {
    const requiredFieldsStep01: (keyof FormDataProps['step01'])[] = ['id'];
    const requiredFieldsStep02: (keyof FormDataProps['step02'])[] = ['chargeAmount', 'originalDueDate', 'description', 'includeFiles'];

    for (let field of requiredFieldsStep01) {
      if (!data.step01[field]) {
        return false;
      }
    }

    for (let field of requiredFieldsStep02) {
      if (!data.step02[field]) {
        return false;
      }
    }

    return true;
  };

  const handleFinish = async () => {
    try {
      if (!validateData(formData)) {
        enqueueSnackbar("Por favor, preencha todos os campos necessários.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        return;
      }

      const commonFields = {
        contract_id: formData.step01.id,
        type: formData.step02.type,
        value: unmaskMoney(formData.step02.chargeAmount) || 0,
        dead_line: formData.step02.originalDueDate,
        rent_amount: unmaskMoney(formData.step02.rentAmount),
        condominium_amount: unmaskMoney(formData.step02.condominiumAmount),
        iptu: unmaskMoney(formData.step02.iptu),
        other_taxes: unmaskMoney(formData.step02.otherTaxes),
        description: formData.step02.description,
        files: formData.step02.includeFiles.map(
          (item: { file: File }) => item.file
        ),
      };

      const specificFields = formData.step02.type === 'Rescisão' ? {
        copasa: unmaskMoney(formData.step02.copasa),
        cemig: unmaskMoney(formData.step02.cemig),
        repairs: unmaskMoney(formData.step02.repairs),
        contractual_fine: unmaskMoney(formData.step02.contractualFine),
      } : {};

      await createOccurrence({
        variables: {
          ...commonFields,
          ...specificFields,
        },
      });

      enqueueSnackbar("Ocorrência cadastrada com sucesso!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      handleClose();
    } catch (err) {
      enqueueSnackbar((err as Error).message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    if (contractId) {
      setFormData((prevState) => ({
        ...prevState,
        step01: {
          ...prevState.step01,
          id: contractId,
        },
      }));
    }
  }, [contractId]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Criar ocorrência</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Box width="100%" minWidth={!isMobile ? 550 : 0}>
                  {step.content}
                </Box>

                <Box sx={{ mb: 2 }}>
                  <div>
                    {index != steps.length - 1 && (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          mt: 1,
                          mr: 1,
                          backgroundColor: "#51d347",
                          "&:hover": {
                            backgroundColor: "#39a930",
                            borderColor: "#0062cc",
                            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                      >
                        Avançar
                      </Button>
                    )}
                    {index !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Voltar
                      </Button>
                    )}
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          disabled={activeStep != steps.length - 1 || loading}
          variant="contained"
          onClick={handleFinish}
          autoFocus
          sx={{
            backgroundColor: "#51d347",
            "&:hover": {
              backgroundColor: "#39a930",
              borderColor: "#0062cc",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Finalizar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ModalAddContractProps {
  open: boolean;
  handleClose: () => void;
  contractId?: number;
}

interface FormDataProps {
  step01: {
    id: number | null;
  };
  step02: {
    type: string;
    rentAmount: string;
    condominiumAmount: string;
    iptu: string;
    otherTaxes: string;
    chargeAmount: string;
    originalDueDate: string;
    description: string;
    copasa: string;
    cemig: string;
    repairs: string;
    contractualFine: string;
    includeFiles: Array<{ file: File }>;
  };
}