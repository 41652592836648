import { Checkbox, FormControlLabel } from "@mui/material";
import ContractHtml from "./ContractHtml";

export default function AcceptContract({
    checked,
    handleChange,
    disabled
}: AcceptContractProps) {
    return (
        <>
        <ContractHtml />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={disabled}
                    />
                }
                label="Li e concordo com os termos do contrato."
            />
        </>
    )
}

interface AcceptContractProps {
    checked: boolean,
    handleChange: () => void,
    disabled: boolean
}