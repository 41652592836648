import { Box, Typography, IconButton } from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';

import Topics from "pages/SignContract/ContractHtml/Topics";

export function TermsOfUse() {
  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <Box position="relative" mb={2}>
        <Box position="absolute" right={0} top={0}>
          <IconButton onClick={handlePrint} color="primary">
            <DownloadIcon />
          </IconButton>
        </Box>

        <Typography variant="body1">
          TERMOS E CONDIÇÕES DE USO DOS SERVIÇOS – RENTI E LOCATÁRIO
        </Typography>

        <Typography variant="body1" my={2}>
          Data da última alteração: 10/08/2023.
        </Typography>

        <Topics />

        <Typography variant="body1" mx={2} fontWeight={"bold"}>
          A CONTRATAÇÃO DOS SERVIÇOS RENTI PRESSUPÕE A LEITURA INTEGRAL DOS
          PRESENTES TERMOS, SUA COMPREENSÃO E ACEITE PELA IMOBILIÁRIA, CORRETOR
          AUTÔNOMO, LOCATÁRIO E COOBRIGADO.
        </Typography>
      </Box>
    </>
  );
}