import { useQuery } from "@apollo/client";
import { Alert, Grid, Typography } from "@mui/material";
import CardWrapper from "components/CardWrapper";
import { FetchContractsResponse, QueryRoot } from "graphql/gql/graphql";
import { FETCH_CONTRACTS } from "graphql/query/fetchContracts";
import { maskCEP, maskCPF, maskDate } from "utils/maskOutputs";

export default function Step03({ formData, contractId }: Step03Props) {
  const { step02 } = formData;

  const { data, loading, error } = useQuery<QueryRoot>(FETCH_CONTRACTS);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro :(</p>;

  const contracts = data?.fetchContracts?.data || [];
  const contract: FetchContractsResponse | undefined | null = contracts.find(
    (contract: any) => {
      return contract.id === contractId;
    }
  );

  if (!contract) {
    return <p>Contrato não encontrado</p>;
  }

  const files = step02?.includeFiles
    .map((item: any) => {
      return item.file;
    })
    .join("\n");

  return (
    <CardWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Locatário
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DataPrint
            label="Nome"
            value={contract.proposal?.tenant?.person?.name || "-"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataPrint
            label="CPF"
            value={maskCPF(contract?.proposal?.tenant?.person?.document || "-")}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Locação
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="Endereço"
            value={contract?.proposal?.immobile?.street || "-"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="Número"
            value={contract?.proposal?.immobile?.number || "-"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="CEP"
            value={maskCEP(contract?.proposal?.immobile?.zipcode || "-")}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="Bairro"
            value={contract?.proposal?.immobile?.district || "-"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="Cidade"
            value={contract?.proposal?.immobile?.city?.name || "-"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="UF"
            value={contract?.proposal?.immobile?.city?.state || "-"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="Ponto de referência"
            value={contract?.proposal?.immobile?.reference || "-"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Dados Bancários
          </Typography>
          <Alert severity="warning">
            O pagamento da inadimplência será depositada na seguinte conta,
            favor confirmar os dados!
          </Alert>
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="Beneficiário"
            value="ALVARO MAIA ADMINISTRAÇÃO DE IMOVEIS LTDA"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="CPNJ/CPF" value="17197278000101" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Banco" value="BANCO SICOOB S.A" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Agência" value="4487" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Conta" value="1695-0" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Tipo de conta" value="Conta Corrent" />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold">
            Dados da dívida
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Tipo" value={step02?.type || "-"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Valor original" value={step02?.chargeAmount || "-"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint
            label="Data de vencimento original"
            value={step02?.originalDueDate ? maskDate(step02?.originalDueDate) : "-"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Aluguel" value={step02?.rentAmount || "-"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Condomínio" value={step02?.condominiumAmount || "-"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="IPTU" value={step02?.iptu || "-"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataPrint label="Outras taxas" value={step02?.otherTaxes || "-"} />
        </Grid>

        {step02?.type === "Rescisão" && (
          <>
            <Grid item xs={12} md={4}>
              <DataPrint label="Copasa" value={step02?.copasa || "-"} />
            </Grid>
            <Grid item xs={12} md={4}>
              <DataPrint label="Cemig" value={step02?.cemig || "-"} />
            </Grid>
            <Grid item xs={12} md={4}>
              <DataPrint label="Reparos" value={step02?.repairs || "-"} />
            </Grid>
            <Grid item xs={12} md={4}>
              <DataPrint
                label="Multa contratual"
                value={step02?.contractualFine || "-"}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <DataPrint label="Descrição" value={step02?.description || "-"} />
        </Grid>

        <Grid item xs={12}>
          <DataPrint label="Anexo(s)" value={files || "-"} />
        </Grid>
      </Grid>
    </CardWrapper>
  );
}

function DataPrint({ label, value }: DataPrintProps) {
  return (
    <>
      <Typography variant="subtitle2" fontWeight="bold">
        {label}
      </Typography>
      <Typography variant="subtitle1" sx={{ wordWrap: "break-word" }}>
        {value}
      </Typography>
    </>
  );
}

interface Step03Props {
  formData: any;
  contractId: number;
}

interface DataPrintProps {
  label: string;
  value: string;
}
