import { Button, CircularProgress, Grid, MenuItem, Select } from "@mui/material";
import Input from "components/Input";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { maskMoney } from "utils/maskOutputs";
import { OccurrenceProps } from "types/graphql/DetailOccurrence";
import { UPDATE_OCCURRENCE } from "graphql/mutation/updateOccurrence";
import { useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";

import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { DETAIL_OCCURRENCE } from "graphql/query/detailOccurrence";
import { unmaskMoney } from "utils/unmaskOutputs";

interface UpdateDefaulterProps {
    data: OccurrenceProps;
    onClose: () => void;
    occurrenceId?: number;
}

export default function UpdateDefaulter({ data, onClose, occurrenceId }: UpdateDefaulterProps) {
    const [updateOccurrence, { loading, error }] = useMutation(UPDATE_OCCURRENCE, {
        refetchQueries: [
            { query: DETAIL_OCCURRENCE, variables: { id: occurrenceId } }
        ]
    });

    const defaultData = {
        ...data,
        dueDate: dayjs(data.dueDate).format("YYYY-MM-DD"),
        value: data.value ? maskMoney(data.value) : "",
        rentAmount: data.rentAmount ? maskMoney(data.rentAmount) : "",
        condominiumAmount: data.condominiumAmount ? maskMoney(data.condominiumAmount) : "",
        iptu: data.iptu ? maskMoney(data.iptu) : "",
        otherTaxes: data.otherTaxes ? maskMoney(data.otherTaxes) : "",
        copasa: data.copasa ? maskMoney(data.copasa) : "",
        cemig: data.cemig ? maskMoney(data.cemig) : "",
        repairs: data.repairs ? maskMoney(data.repairs) : "",
        contractualFine: data.contractualFine ? maskMoney(data.contractualFine) : "",
    };

    const methods = useForm({
        defaultValues: defaultData,
    });

    const { handleSubmit, control, watch, setValue } = methods;

    const type = watch("type");    

    const onSubmit = async (values: any) => {
        try {
            const data = {
                id: occurrenceId,
                type: values.type,
                value: unmaskMoney(values.value),
                dead_line: values.dueDate,
                rent_amout: unmaskMoney(values.rentAmount),
                condominium_amount: unmaskMoney(values.condominiumAmount),
                iptu: unmaskMoney(values.iptu),
                other_taxes: unmaskMoney(values.otherTaxes),
                copasa: type === "Rescisão" ? unmaskMoney(values.copasa) : 0,
                cemig: type === "Rescisão" ? unmaskMoney(values.cemig) : 0,
                repairs: type === "Rescisão" ? unmaskMoney(values.repairs) : 0,
                contractual_fine: type === "Rescisão" ? unmaskMoney(values.contractualFine) : 0,
                description: values.description,
            }

            await updateOccurrence({
                variables: {
                    ...data,
                }
            })
            enqueueSnackbar("Dados da inadimplência atualizado com sucesso", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
        } catch (error) {
            enqueueSnackbar("Erro ao atualizar dados da inadimplência", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
        } finally {
            onClose();
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} pt={2}>
                    <Grid item xs={12}>
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    fullWidth
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e);
                                    }}>
                                    <MenuItem value={"Simples"}>Simples</MenuItem>
                                    <MenuItem value={"Rescisão"}>Recisão</MenuItem>
                                </Select>
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            name="value"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Valor*"
                                    type="text"
                                    fullWidth
                                    value={field.value}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <Controller
                                name="dueDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Data de vencimento*"
                                        format="DD/MM/YYYY"
                                        value={field.value ? dayjs(field.value, "YYYY-MM-DD") : null}
                                        onChange={(date) => {
                                            setValue("dueDate", date ? date.format("YYYY-MM-DD") : "");
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            name="rentAmount"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Aluguel"
                                    type="text"
                                    fullWidth
                                    value={field.value}
                                    showLabel={true}
                                    onChange={(e) => {
                                        field.onChange(maskMoney(e.target.value));
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            name="condominiumAmount"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Condomínio"
                                    type="text"
                                    fullWidth
                                    value={field.value}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            name="iptu"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="IPTU"
                                    type="text"
                                    fullWidth
                                    value={field.value}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            name="otherTaxes"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Outras Taxas"
                                    type="text"
                                    fullWidth
                                    value={field.value ? field.value : ""}
                                    showLabel={true}
                                    onChange={(e) => {
                                        field.onChange(maskMoney(e.target.value));
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    {type === "Rescisão" && (
                        <>
                            <Grid item xs={6}>
                                <Controller
                                    name="copasa"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            placeholder="Copasa"
                                            type="text"
                                            fullWidth
                                            value={field.value ? field.value : ""}
                                            showLabel={true}
                                            onChange={(e) => {
                                                field.onChange(maskMoney(e.target.value));
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name="cemig"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            placeholder="Cemig"
                                            type="text"
                                            fullWidth
                                            value={field.value ? field.value : ""}
                                            showLabel={true}
                                            onChange={(e) => {
                                                field.onChange(maskMoney(e.target.value));
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name="repairs"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            placeholder="Reparos"
                                            type="text"
                                            fullWidth
                                            value={field.value ? field.value : ""}
                                            showLabel={true}
                                            onChange={(e) => {
                                                field.onChange(maskMoney(e.target.value));
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name="contractualFine"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            placeholder="Multa contratual"
                                            type="text"
                                            fullWidth
                                            value={field.value ? field.value : ""}
                                            showLabel={true}
                                            onChange={(e) => {
                                                field.onChange(maskMoney(e.target.value));
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Descrição*"
                                    type="text"
                                    fullWidth
                                    value={field.value}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    marginTop={4}
                    spacing={1}
                >
                    <Grid item>
                        <Button disabled={loading} onClick={onClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            type="submit"
                            autoFocus
                            disabled={loading}
                            sx={{
                                backgroundColor: "#51d347",
                                "&:hover": {
                                    backgroundColor: "#39a930",
                                    borderColor: "#0062cc",
                                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                                },
                            }}
                        >
                            {loading ? (
                                <CircularProgress size={24} />
                            ) : (
                                "Atualizar"
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider >
    );
}
