import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { maskMoney } from "utils/maskOutputs";
import Input from "components/Input";
import { CardPlan } from "components/CardPlan";
import FormActions from "./FormActions";
import useVerifyScreen from "hooks/useVerifyScreen";
import { unmaskMoney } from "utils/unmaskOutputs";
import { DataElPlan, FormCalcValuesProps } from "types/CreateProposal";
import { FETCH_PLANS } from "graphql/query/fetchPlans";
import { useQuery } from "@apollo/client";

export default function FormCalcValues({
  formData,
  sendData,
  activeStep,
  setActiveStep
}: FormCalcValuesProps) {

  const isMobile = useVerifyScreen()

  const [serviceSelected, setServiceSelected] = useState(false);

  const { data: data } = useQuery(FETCH_PLANS)
  const dataPlans = data?.fetchPlans?.data || []

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      rent: formData.rent,
      condominium: formData.condo,
      otherTaxes: formData.otherTaxes,
      iptu: formData.iptu,
      service: formData.service,
      serviceInstallmentOption: formData.serviceInstallmentOption,
      setupValue: formData.setupValue,
      setupInstallmentOption: formData.setupInstallmentOption,
      totalValue: formData.totalValue
    }
  });
  const { control, setValue, getValues, watch, formState: { errors } } = methods;

  const handleSendForm = async () => {
    await methods.trigger();

    const {
      rent,
      condominium,
      otherTaxes,
      iptu,
      service,
      serviceInstallmentOption,
      setupValue,
      setupInstallmentOption,
      totalValue
    } = getValues()

    const data = {
      rent,
      condominium,
      otherTaxes,
      iptu,
      service,
      serviceInstallmentOption,
      setupValue,
      setupInstallmentOption,
      totalValue
    }

    if (!serviceSelected) {
      methods.setError("service", {
        type: "manual",
        message: "Por favor, selecione uma opção de serviço."
      });
      return;
    }

    sendData({
      rent: rent,
      condo: condominium,
      otherTaxes: otherTaxes,
      iptu: iptu,
      totalValue: totalValue || 0,
      service: service,
      serviceInstallmentOption: serviceInstallmentOption,
      setupValue: setupValue,
      setupInstallmentOption: setupInstallmentOption
    }, "stepValues");
  };

  const handleServiceSelection = (arg: DataElPlan) => {
    setValue("service", arg);
    setServiceSelected(true);
  }

  const handleSum = () => {
    var values = getValues(["iptu", "condominium", "otherTaxes", "rent"])
    const formatNumbers = values.map(el => unmaskMoney(el));

    const soma = formatNumbers.reduce((acumulador, numero) => acumulador + numero, 0).toFixed(2);

    setValue("totalValue", Number(soma))
  }

  const paymentPlan = Array.from({ length: getValues("service.max_installments") }, (_, index) => index + 1);
  const setupFee = getValues("service.setup_fee")
  const setupPayment = Array.from({ length: getValues("service.setup_max_installments") }, (_, index) => index + 1);

  return (
    <Box width="100%">
      <Typography style={customTypography}>
        Preencha os valores referentes ao aluguel e outras taxas que serão
        cobradas ao locatário locatário
      </Typography>

      <FormProvider {...methods}>
        <Stack
          component="form"
          spacing={4}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Controller
                name="rent"
                control={control}
                render={({ field }) => (
                  <Input
                    {...{ field }}
                    placeholder="Aluguel*"
                    type="text"
                    name="rent"
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(maskMoney(e.target.value))
                      handleSum()
                    }}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Controller
                name="condominium"
                control={control}
                render={({ field }) => (
                  <Input
                    {...{ field }}
                    placeholder="Condomínio*"
                    type="text"
                    fullWidth
                    name="condominium"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(maskMoney(e.target.value))
                      handleSum()
                    }}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="otherTaxes"
                control={control}
                render={({ field }) => (
                  <Input
                    {...{ field }}
                    placeholder="Outras taxas*"
                    type="text"
                    fullWidth
                    name="otherTaxes"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(maskMoney(e.target.value))
                      handleSum()
                    }}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="iptu"
                control={control}
                render={({ field }) => (
                  <Input
                    {...{ field }}
                    placeholder="IPTU*"
                    type="text"
                    fullWidth
                    name="iptu"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(maskMoney(e.target.value))
                      handleSum()
                    }}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Valor Locatício Total
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                {maskMoney(watch("totalValue"))}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
        <Box display="flex" flexDirection="column" gap="1rem" paddingTop="1rem">
          <Typography style={customTypography} sx={{ fontWeight: "900" }}>
            Opções de serviços
          </Typography>
          <Typography style={customTypography}>
            {" "}
            Selecione uma opção de plano de serviço que melhor se encaixa no seu
            negócio
          </Typography>

          <CardPlan data={dataPlans} value={watch("service")?.id || 0} setSelectedPlan={handleServiceSelection} />

          {methods.formState.errors.service && !serviceSelected && (
            <FormHelperText error={true} sx={{
              fontSize: "1rem",
            }}>
              {methods.formState.errors.service.message}
            </FormHelperText>
          )}
        </Box>

        {serviceSelected && (
          <Box
            paddingTop="2rem"
            display="flex"
            flexDirection="column"
            gap="1rem"
            width={isMobile ? "100%" : "60%"}
          >
            <Typography style={customTypography} sx={{ fontWeight: "900" }}>
              Escolha uma opção de parcelamento do serviço
            </Typography>

            <FormControl fullWidth>
              <InputLabel id="pagamento" sx={labelStyle}>
                Pagamento*
              </InputLabel>
              <Controller
                name="serviceInstallmentOption"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...{ field }}
                      value={field.value}
                      label="pagamento"
                      error={!!errors.serviceInstallmentOption}
                      onChange={(e) => {
                        setValue("serviceInstallmentOption", e.target.value);
                        methods.clearErrors("serviceInstallmentOption");
                      }}
                    >
                      {
                        paymentPlan.map((item, index) => {

                          let label = `Parcelado (${item}x)`
                          if (item == 1) label = "À Vista"

                          return (
                            <MenuItem key={index} value={item}>{label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                    <FormHelperText>
                      {errors.serviceInstallmentOption && (
                        <FormHelperText error={true}>
                          {errors.serviceInstallmentOption.message}
                        </FormHelperText>
                      )}
                    </FormHelperText>
                  </>
                )}
              />

            </FormControl>

            <Typography style={customTypography} sx={{ fontWeight: "900" }}>
              Taxa de setup da imobiliária
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="valor-setup-label" sx={labelStyle}>
                Taxa Setup*
              </InputLabel>
              <Controller
                name="setupValue"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...{ field }}
                      labelId="valor-setup-label"
                      id="valor-setup-label"
                      error={!!errors.setupValue}
                      value={field.value}
                      label="Taxa Setup"
                      onChange={(e) => {
                        setValue("setupValue", e.target.value);
                        methods.clearErrors("setupValue");
                      }}
                    >
                      {
                        setupFee.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>{maskMoney(item)}</MenuItem>
                          )
                        })
                      }
                    </Select>
                    <FormHelperText>
                      {errors.setupValue && (
                        <FormHelperText error={true}>
                          {errors.setupValue.message}
                        </FormHelperText>
                      )}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="parcelamento-setup-label" sx={labelStyle}>
                Parcelamento Setup*
              </InputLabel>
              <Controller
                name="setupInstallmentOption"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...{ field }}
                      labelId="parcelamento-setup-label"
                      id="parcelamento-setup-label"
                      error={!!errors.setupInstallmentOption}
                      value={field.value}
                      label="Parcelamento Setup"
                      onChange={(e) => {
                        setValue("setupInstallmentOption", e.target.value);
                        methods.clearErrors("setupInstallmentOption");
                      }}
                    >
                      {
                        setupPayment.map((item, index) => {

                          let label = `Parcelado (${item}x)`
                          if (item == 1) label = "À Vista"

                          return (
                            <MenuItem key={index} value={item}>{label}</MenuItem>
                          )
                        })
                      }
                    </Select>
                    <FormHelperText>
                      {errors.setupInstallmentOption && (
                        <FormHelperText error={true}>
                          {errors.setupInstallmentOption.message}
                        </FormHelperText>
                      )}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>
          </Box>
        )}

      </FormProvider>

      <FormActions {...{ activeStep, setActiveStep, methods }} handleForm={handleSendForm} />
    </Box>
  );
}

const schema = yup.object({
  rent: yup.string().required("Aluguel é obrigatório"),
  condominium: yup.string().required("Condominio é obrigatório"),
  otherTaxes: yup.string().required("Outras taxas é obrigatória"),
  iptu: yup.string().required("IPTU é obrigatório"),
  service: yup.object().shape({
    description: yup.string().required(),
    fee: yup.number().required(),
    id: yup.number().nullable().required(),
    max_installments: yup.number().required(),
    name: yup.string().required(),
    setup_fee: yup.array().of(yup.number().required()).nullable().required(),
    setup_max_installments: yup.number().required(),
    warranty_months: yup.number().required(),
    __typename: yup.string().required()
  }).required('Nome do estado é obrigatório'),
  serviceInstallmentOption: yup.string().required("Opção de parcelamento obrigatória"),
  setupValue: yup.string().required("Opção de Setup obrigatória"),
  setupInstallmentOption: yup.string().required("Opção de Parcelamento do Setup obrigatória"),
  totalValue: yup.number()
});

const customTypography = {
  fontSize: "1.3rem",
  color: "#2E349F",
};
const labelStyle = {
  color: "#2E349F",
};