import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

interface TopicWithTableProps {
  data: (string | { col1: string; col2?: string })[];
}

export default function TopicWithTable({ data }: TopicWithTableProps) {
  return (
    <Box>
      <TableContainer>
        <Table sx={{ borderCollapse: 'collapse' }}>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} sx={{ borderBottom: '1px solid black' }}>
                {typeof row === "string" ? (
                  <TableCell colSpan={2} sx={{ border: '1px solid black', textAlign: 'center', fontWeight: 'bold', textDecoration: 'underline' }}>{row}</TableCell>
                ) : Object.keys(row).length === 1 ? (
                  <TableCell colSpan={2} sx={{ border: '1px solid black' }}>{row.col1}</TableCell>
                ) : (
                  <>
                    <TableCell sx={{ border: '1px solid black' }}>{row.col1}</TableCell>
                    <TableCell sx={{ border: '1px solid black' }}>{row.col2}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}