import { UpdateFormProps } from "types/DetailProposal";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { maskMoney } from "utils/maskOutputs";
import Input from "components/Input";
import { unmaskMoney } from "utils/unmaskOutputs";
import { FETCH_PLANS } from "graphql/query/fetchPlans";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { UPDATE_PAYMENT_PROPOSAL } from "graphql/mutation/updatePaymentProposal";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { enqueueSnackbar } from "notistack";

export default function UpdatePayment({ data, onClose }: UpdateFormProps) {
  const { id } = useParams();

  const [updatePaymentProposal, { loading }] = useMutation(
    UPDATE_PAYMENT_PROPOSAL,
    {
      refetchQueries: [
        { query: DETAIL_PROPOSAL, variables: { id: Number(id) } },
      ],
    }
  );

  const methods = useForm({
    defaultValues: {
      ...data,
      iptu: maskMoney(data.iptu || 0),
      condo: maskMoney(data.condo || 0),
      otherTaxes: maskMoney(data.otherTaxes || 0),
      rent: maskMoney(data.rent || 0),
      plan: data.plan,
    },
  });

  const { handleSubmit, getValues, control, watch, setValue } = methods;

  const { data: fetchedPlans, loading: loadingPlans } = useQuery(FETCH_PLANS);
  const dataPlans = fetchedPlans?.fetchPlans?.data || [];

  const handleSum = () => {
    var values = getValues(["iptu", "condo", "otherTaxes", "rent"]);
    const formatNumbers = values.map((el) => unmaskMoney(el));

    const soma = formatNumbers
      .reduce((acumulador, numero) => acumulador + numero, 0)
      .toFixed(2);

    setValue("totalValue", Number(soma));
  };

  const selectedPlan = dataPlans.find(
    (item: any) => item.name === watch("plan")
  );
  const paymentPlan =
    Array.from(
      { length: selectedPlan?.max_installments },
      (_, index) => index + 1
    ) || [];
  const setupFee = selectedPlan?.setup_fee || [];
  const setupPayment =
    Array.from(
      { length: selectedPlan?.setup_max_installments },
      (_, index) => index + 1
    ) || [];
  const warrantyMonths = selectedPlan?.warranty_months || [];

  const onSubmit = (formData: any) => {
    const payment = {
      rent_amount: unmaskMoney(formData.rent),
      condominium_amount: unmaskMoney(formData.condo),
      other_taxes: unmaskMoney(formData.otherTaxes),
      plan_id: selectedPlan?.id,
      chosen_setup_installments: getValues("planInstallments"),
      chosen_setup_fee: getValues("setupFee"),
      chosen_installments: getValues("setupInstallments"),
      iptu: unmaskMoney(formData.iptu),
    };

    if (!payment.plan_id) {
      console.error("plan is required");
      return;
    }

    updatePaymentProposal({
      variables: {
        id: Number(id),
        payment: payment,
      },
    })
      .then((response) => {
        enqueueSnackbar("Pagamento atualizado com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        onClose();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Erro ao atualizar pagamento", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} pt={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="rent"
              control={control}
              render={({ field }) => (
                <Input
                  {...{ field }}
                  placeholder="Aluguel*"
                  type="text"
                  name="rent"
                  fullWidth
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(maskMoney(e.target.value));
                    handleSum();
                  }}
                  showLabel={true}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="condo"
              control={control}
              render={({ field }) => (
                <Input
                  {...{ field }}
                  placeholder="Condomínio*"
                  type="text"
                  fullWidth
                  name="condo"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(maskMoney(e.target.value));
                    handleSum();
                  }}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="otherTaxes"
              control={control}
              render={({ field }) => (
                <Input
                  {...{ field }}
                  placeholder="Outras taxas*"
                  type="text"
                  fullWidth
                  name="otherTaxes"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(maskMoney(e.target.value));
                    handleSum();
                  }}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="iptu"
              control={control}
              render={({ field }) => (
                <Input
                  {...{ field }}
                  placeholder="IPTU*"
                  type="text"
                  fullWidth
                  name="iptu"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(maskMoney(e.target.value));
                    handleSum();
                  }}
                  showLabel={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Valor Total</Typography>
            <Typography variant="h4" fontWeight="bold">
              {maskMoney(watch("totalValue") || 0)}
            </Typography>
          </Grid>

          {loadingPlans && (
            <Box width="100%" textAlign="center" mt={5} mb={5}>
              <CircularProgress />
            </Box>
          )}

          {!loadingPlans && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Plano</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getValues("plan")}
                    label="Plano"
                  >
                    {dataPlans.map((item: any, index: number) => (
                      <MenuItem
                        key={index}
                        onClick={() => setValue("plan", item.name)}
                        value={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-basic"
                  label="Garantia"
                  variant="outlined"
                  disabled={true}
                  value={warrantyMonths + "x"}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Parcela plano
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={watch("planInstallments")}
                    label="Parcela plano"
                    onChange={(e) => {
                      setValue("planInstallments", e.target.value);
                    }}
                  >
                    {paymentPlan.map((item, index) => {
                      let label = `Parcelado (${item}x)`;
                      if (item == 1) label = "À Vista";
                      return (
                        <MenuItem key={index} value={item}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Taxa setup
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={watch("setupFee")}
                    label="Taxa setup"
                    onChange={(e) => {
                      setValue("setupFee", e.target.value);
                    }}
                  >
                    {setupFee.map((item: any, index: number) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {maskMoney(item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Parcela plano
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={watch("setupInstallments")}
                    label="Parcela plano"
                    onChange={(e) => {
                      setValue("setupInstallments", e.target.value);
                    }}
                  >
                    {setupPayment.map((item, index) => {
                      let label = `Parcelado (${item}x)`;
                      if (item == 1) label = "À Vista";
                      return (
                        <MenuItem key={index} value={item}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          marginTop={4}
          spacing={1}
        >
          <Grid item>
            <Button disabled={loading} onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              autoFocus
              disabled={loading}
              sx={{
                backgroundColor: "#51d347",
                "&:hover": {
                  backgroundColor: "#39a930",
                  borderColor: "#0062cc",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Atualizar"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
