import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { ListItem, Typography, useMediaQuery } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import NavLink from "../NavLink";
import iconeHome from "../../../assets/home.png";
import iconeCotract from "../../../assets/contract.png";
import iconeList from "../../../assets/lists.png";
import iconeRealStateAgent from "../../../assets/real_estate_agent.png";
import { Link, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { useAuthContext } from "context/AuthProvider";

import ListIcon from '@mui/icons-material/List';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FeedIcon from '@mui/icons-material/Feed';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonOffIcon from '@mui/icons-material/PersonOff';

interface MenuOpenProps {
  open: boolean;
  onClose: () => void;
  selectedItemMenu: string,
  setSelectedItemMenu: (args: string) => void
}

export function MenuOpen({ open, onClose, selectedItemMenu, setSelectedItemMenu }: MenuOpenProps) {

  const { handleLogout } = useAuthContext();

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        sx={{ width: 250 }}
      >
        <List sx={{ textDecoration: "none" }}>
          {
            menuItems.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.route}
                  onClick={() => {
                    setSelectedItemMenu(item.route)
                    onClose()
                  }}
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                      justifyItems: "center",
                      paddingTop: 3,
                      paddingBottom: 3,
                      backgroundColor: selectedItemMenu == item.route ? "rgba(46, 52, 159, 0.1)" : "white",
                      transition: "0.1s",
                      borderBottom: index == menuItems.length - 1 ? "0px" : "1px solid #ccc",
                      "&:hover": {
                        backgroundColor: "rgba(46, 52, 159, 0.1)"
                      },
                    }}
                  >
                    {item.icon}
                    <Typography sx={{
                      color: "#2E349F",
                      fontSize: 14
                    }}>{item.name}</Typography>
                  </ListItem>
                </Link>
              )
            })
          }
        </List>
      </Box>
    </Drawer>
  );
}

const itemsColor = "#2E349F"

const menuItems = [
  {
    route: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/propostas",
    name: "Propostas",
    icon: <FeedIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/contratos",
    name: "Contratos",
    icon: <ReceiptLongIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/ocorrencias",
    name: "Ocorrências",
    icon: <PersonOffIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/relatorios",
    name: "Relatórios",
    icon: <ListIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/configuracoes",
    name: "Configurações",
    icon: <SettingsIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/",
    name: "Sair",
    icon: <ExitToAppIcon sx={{ color: itemsColor }} />
  }
]
