import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CardWrapper from "components/CardWrapper";
import InfoIcon from "@mui/icons-material/Info";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import ModalSendDocument from "../../../components/ModalSendDocument";
import {
  DetailOccurrenceAttachmentsResponse,
  DetailOccurrenceContractProposalResponse,
} from "graphql/gql/graphql";
import { FormProvider, useForm } from "react-hook-form";

export function CardUpFileOccurrence({
  attachments,
}: {
  attachments:
    | DetailOccurrenceContractProposalResponse
    | DetailOccurrenceAttachmentsResponse[];
}) {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<DetailOccurrenceAttachmentsResponse[] | undefined>(undefined);

  const methods = useForm();

  useEffect(() => {
    if (Array.isArray(attachments)) {
      setData(
        attachments.filter(
          (item): item is DetailOccurrenceAttachmentsResponse => item !== null
        )
      );
    }
  }, [attachments]);

  return (
    <FormProvider {...methods}>
      <CardWrapper>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "900",
              color: "#2E349F",
            }}
          >
            Anexos
          </Typography>
        </Box>
        <Grid container spacing={2} mt={4} mb={4}>
          {data && data.length <= 0 ? (
            <Grid item xs={12} display="flex" gap={1}>
              <InfoIcon />
              <Typography>Nenhum anexo adicionado </Typography>
            </Grid>
          ) : (
            data &&
            data.filter(
                (item): item is DetailOccurrenceAttachmentsResponse =>
                  item !== null
              )
              .map(
                (item: DetailOccurrenceAttachmentsResponse, index: number) => (
                  <Grid
                    container
                    item
                    key={index}
                    xs={12}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={11}>
                      <Typography fontWeight="bold">{item.name}</Typography>
                      <Box display="flex" alignItems="center">
                        <AttachFileIcon sx={{ fontSize: 16 }} />
                        <a
                          href={item.url || ""}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.url || ""}
                        </a>
                      </Box>
                    </Grid>
                  </Grid>
                )
              )
          )}
        </Grid>
        {/*         <Button variant="outlined" onClick={() => setOpenModal(true)}>
          Adicionar anexos
        </Button> */}

        {openModal && (
          <ModalSendDocument
            {...{ setData, data }}
            open={openModal}
            handleClose={() => setOpenModal(false)}
          />
        )}
      </CardWrapper>
    </FormProvider>
  );
}
