import { gql } from "@apollo/client";

export const UPDATE_TENANT_PROPOSAL = gql`
  mutation UpdateTenantProposal(
    $id: Int!
    $tenant: UpdateTenantProposalObjectInput!
  ) {
    updateTenantProposal(
      id: $id, 
      tenant: $tenant
    )
  }
`;