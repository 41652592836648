import {gql} from "@apollo/client"

export const CREATE_PROPOSAL_SIMULATION = gql`
mutation createProposalSimulation(
    $immobile: CreateProposalSimulationImmobileObjectInput!
    $rent_amount: Float!
    $condominium_amount:Float
    $other_taxes:Float
    $iptu:Float
    $tenant: CreateProposalSimulationTenantObjectInput!
    ){
        createProposalSimulation(
            immobile: $immobile
            rent_amount: $rent_amount
            condominium_amount: $condominium_amount
            other_taxes:  $other_taxes
            iptu:$iptu
            tenant: $tenant
        )

    }
`