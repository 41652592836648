import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import { Controller, FormProvider, useForm } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

export default function ModalAddFiles({
  setData,
  data,
}: ModalSendDocumentProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const methods = useForm({
    defaultValues: {
      file: "",
      includeFiles: data,
    },
  });
  const { getValues, control, watch, setValue } = methods;

  const listFilesAdd = watch("includeFiles") || [];

  const handleAdd = () => {
    const { file, includeFiles } = getValues();

    if (!file) {
      enqueueSnackbar("Selecione um arquivo", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      return;
    }

    const cloneArr = [...includeFiles];

    cloneArr.push({
      file,
    });

    setValue("includeFiles", cloneArr);
    setValue("file", "");
    setData(cloneArr);
  };

  const handleRemove = (index: number) => {
    const { includeFiles } = getValues();
    const cloneArr = [...includeFiles];

    cloneArr.splice(index, 1);

    setValue("includeFiles", cloneArr);
  };

  return (
    <>
      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
        Anexos
      </Typography>
      {watch("includeFiles").length > 0 && (
        <>
          <Box flexDirection="column" display="flex">
            {listFilesAdd.map(
              (item: { name: string; file: File }, index: number) => (
                <Box
                  key={index}
                  flexDirection="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography fontWeight="bold">{item.name}</Typography>
                    <Typography>
                      <AttachFileIcon sx={{ fontSize: 15 }} />
                      <a href={URL.createObjectURL(item.file)} target="_blank">
                        {item.file.name}
                      </a>
                    </Typography>
                  </Box>
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    color="error"
                    onClick={() => handleRemove(index)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              )
            )}
          </Box>

          <Divider sx={{ mt: 3, mb: 3 }} />
        </>
      )}

      <FormProvider {...methods}>
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12} md={11}>
            <Controller
              control={control}
              name="file"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                  <input
                    id="file-input"
                    onBlur={onBlur}
                    onChange={(e) => {
                      const file = e.target.files ? e.target.files[0] : null;
                      setSelectedFile(file);
                      onChange(file);
                    }}
                    ref={ref}
                    type="file"
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file-input">
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      sx={{ height: "100%" }}
                    >
                      {selectedFile
                        ? selectedFile.name
                        : "Selecione um arquivo"}
                    </Button>
                  </label>
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} md={1}>
            <Button
              fullWidth
              variant="contained"
              sx={{ height: "100%" }}
              onClick={handleAdd}
            >
              <Typography fontSize={25}>+</Typography>
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}

interface ModalSendDocumentProps {
  setData: (args: any) => void;
  data: any;
}
