import { Button, CircularProgress, Grid } from "@mui/material";

import { useForm, FormProvider, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";

import { enqueueSnackbar } from "notistack";

import { SelectWithSearch } from "components/SelectWithSearch";
import Input from "components/Input";

import { UPDATE_TENANT_PROPOSAL } from "graphql/mutation/updateTenantProposal";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { FETCH_STATES } from "graphql/query/fetchStates";
import { FETCH_CITIES } from "graphql/query/fetchCities";

import { useMutation } from "@apollo/client";

import { maskCEP, maskDate, maskPhone } from "utils/maskOutputs";
import { unMaskCep, unMaskPhone } from "utils/unmaskOutputs";

import { UpdateFormProps } from "types/DetailProposal";

export default function UpdateExtraTentant({ data, onClose }: UpdateFormProps) {
    const { id } = useParams();

    const [updateTenantProposal, { loading }] = useMutation(
        UPDATE_TENANT_PROPOSAL,
        {
            refetchQueries: [
                { query: DETAIL_PROPOSAL, variables: { id: Number(id) } },
            ],
        }
    );

    const methods = useForm({
        defaultValues: {
            extras: {
                email: data.extras?.email || "",
                phone: data.extras?.phone || "",
                address: {
                    zipcode: data.extras?.address?.zipCode || "",
                    street: data.extras?.address?.street || "",
                    number: data.extras?.address?.number || "",
                    city: {
                        id: data.extras?.address?.city?.id || "",
                        name: data.extras?.address?.city?.name || "",
                        state: data.extras?.address?.city?.state || "",
                    },
                    district: data.extras?.address?.district || "",
                    complement: data.extras?.address?.complement || "",
                    reference: data.extras?.address?.reference || "",
                },
            },
        }
    });

    const { handleSubmit, control, setValue, watch, getValues } = methods;

    const onSubmit = async (formData: any) => {
        console.log("🚀 ~ onSubmit ~ formData:", formData)
        try {
            const tenant = {
                email: data.email,
                phone: data.phone,
                birth_date: maskDate(data.birth, "yyyy-MM-dd"),
                extras: JSON.stringify({
                    email: formData.extras.email,
                    phone: unMaskPhone(formData.extras.phone),
                    address: {
                        zipcode: unMaskCep(formData.extras.address.zipcode),
                        street: formData.extras.address.street,
                        number: formData.extras.address.number,
                        city: {
                            id: formData.extras.address.city.id,
                            name: formData.extras.address.city.name,
                            state: formData.extras.address.city.state,
                        },
                        district: formData.extras.address.district,
                        complement: formData.extras.address.complement,
                        reference: formData.extras.address.reference,
                    }
                }),
            };

            await updateTenantProposal({ variables: { id: Number(id), tenant } });
            
            enqueueSnackbar("Dados extras atualizado com sucesso", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
        } catch (error) {
            enqueueSnackbar("Erro ao atualizar dados extras do locatário", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
        } finally {
            onClose();
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} pt={2}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="extras.email"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="E-mail"
                                    type="text"
                                    fullWidth
                                    value={field.value ?? ""}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="extras.phone"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Telefone"
                                    type="text"
                                    fullWidth
                                    inputProps={{ maxLength: 15 }}
                                    value={maskPhone(field.value ?? "")}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="extras.address.zipcode"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="CEP"
                                    type="text"
                                    fullWidth
                                    inputProps={{ maxLength: 9 }}
                                    value={maskCEP(field.value ?? "")}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="extras.address.street"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Endereço"
                                    type="text"
                                    fullWidth
                                    value={field.value ?? ""}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="extras.address.number"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Número"
                                    type="text"
                                    fullWidth
                                    inputProps={{ maxLength: 4 }}
                                    value={field.value ?? ""}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <SelectWithSearch
                            query="fetchStates"
                            queryHook={FETCH_STATES}
                            label="Estado"
                            value={watch("extras.address.city.state") || ""}
                            onChange={(e: { id: number, name: string }) => {
                                setValue("extras.address.city.state", e.name)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectWithSearch
                            isDisabled={!getValues("extras.address.city.state")}
                            query="fetchCities"
                            queryHook={FETCH_CITIES}
                            queryVariables={{
                                state: getValues("extras.address.city.state") || "",
                                limit: 860,
                            }}
                            label="Cidade"
                            value={watch("extras.address.city.name") || ""}
                            onChange={(e: { id: number, name: string }) => {
                                setValue("extras.address.city.id", e.id)
                                setValue("extras.address.city.name", e.name)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="extras.address.district"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Bairro"
                                    type="text"
                                    fullWidth
                                    value={field.value || ""}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="extras.address.reference"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Referência"
                                    type="text"
                                    fullWidth
                                    value={field.value ?? ""}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="extras.address.complement"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Complemento"
                                    type="text"
                                    fullWidth
                                    value={field.value || ""}
                                    showLabel={true}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    marginTop={4}
                    spacing={1}
                >
                    <Grid item>
                        <Button disabled={loading} onClick={onClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            autoFocus
                            sx={{
                                backgroundColor: "#51d347",
                                "&:hover": {
                                    backgroundColor: "#39a930",
                                    borderColor: "#0062cc",
                                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                                },
                            }}
                        >
                            {loading ? <CircularProgress size={24} /> : "Atualizar"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}
