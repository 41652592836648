import { Fab, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export default function FloatButton({
    handleAction,
    icon
}: FloatButtonProps) {
    return (
        <Fab
            color="primary"
            sx={{
                margin: 0,
                top: 'auto',
                right: 40,
                bottom: 40,
                left: 'auto',
                position: 'fixed',
                backgroundColor: "#51d347",
                '&:hover': {
                    backgroundColor: '#39a930',
                    borderColor: '#0062cc',
                    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                },
            }}
            onClick={handleAction}
        >
            {icon}
        </Fab>
    )
}

interface FloatButtonProps {
    handleAction: () => void
    icon: React.ReactNode
}