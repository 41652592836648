import { gql } from "@apollo/client";

export const UPDATE_PAYMENT_PROPOSAL = gql`
  mutation UpdatePaymentProposal(
    $id: Int!
    $payment: UpdatePaymentProposalObjectInput!
  ) {
    updatePaymentProposal(
      id: $id, 
      payment: $payment
    )
  }
`;