import { gql } from "@apollo/client";

const UPDATE_BANK_DATA_PERSON = gql`
  mutation UpdateBankDataPerson(
    $person_id: Int!
    $bank_id: Int!
    $agency: String
    $account: String
    $pix: String
  ) {
    updateBankDataPerson(
      person_id: $person_id
      bank_id: $bank_id
      agency: $agency
      account: $account
      pix: $pix
    )
  }
`;

export default UPDATE_BANK_DATA_PERSON;
