import { 
    Dialog, 
    DialogContent, 
    DialogTitle
} from "@mui/material";


interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

export const Modal: React.FC<any> = ({
    isOpen,
    onClose,
    title,
    children,
}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
};