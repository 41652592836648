export const unmaskMoney = (maskedValue: string): number => {
  const unmaskedValue = maskedValue.replace(/[^\d,]/g, "");

  const valueWithDotDecimal = unmaskedValue.replace(/,/, ".").replace(/,/, "");

  const floatValue = parseFloat(valueWithDotDecimal);

  return isNaN(floatValue) ? 0 : floatValue;
};

export const unMaskPhone = (maskedValue: string): string => {
  const unmaskedValue = maskedValue.replace(/\D/g, "");
  return unmaskedValue;
};

export const unMaskCep = (maskedValue: string): string => {
  const unmaskedValue = maskedValue.replace(/\D/g, "");
  return unmaskedValue;
}