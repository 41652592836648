import { TableBody, TableCell, TableRow } from "@mui/material";
import { FetchProposalsResponse } from "graphql/gql/graphql";
import { maskCPF } from "utils/maskOutputs";

interface ProposalProps {
  code: string;
  tenant: string;
  document: string;
}

export function ProposalTable({ code, tenant, document }: ProposalProps) {
  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {code}
      </TableCell>
      <TableCell>{tenant}</TableCell>
      <TableCell>
        {maskCPF(document)}
      </TableCell>
    </TableRow>
  );
}