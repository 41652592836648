import { Box, Button } from "@mui/material";
import useVerifyScreen from "hooks/useVerifyScreen";
import { Link } from "react-router-dom";

interface FormActionsProps {
    activeStep: number,
    setActiveStep: React.Dispatch<React.SetStateAction<number>>,
    handleForm: any
    methods?: any
}

export default function FormActions({
    activeStep,
    setActiveStep,
    handleForm,
    methods
}: FormActionsProps) {
    const isMobile = useVerifyScreen()

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        methods.trigger();
    };

    const handleNext = async () => {
        await handleForm();
        if (!methods.formState.isValid) {
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap="1.2rem"
            paddingTop="1.5rem"
            flexDirection={isMobile ? "column-reverse" : "row"}
            alignItems={"center"}
        >
            <Link to="/dashboard">
                <Button
                    variant="text"
                >
                    Cancelar
                </Button>
            </Link>
            <Box display="flex" gap={2}>
                {activeStep > 0 ? (
                    <Button
                        variant="outlined"
                        onClick={handleBack}
                        sx={{
                            borderColor: "#51d347",
                            color: "#51d347",
                            '&:hover': {
                                borderColor: "#39a930"
                            }
                        }}
                    >
                        Voltar
                    </Button>
                ) : (
                    ""
                )}
                <Button
                    variant="contained"
                    onClick={handleNext}
                    autoFocus
                    sx={{
                        backgroundColor: "#51d347",
                        '&:hover': {
                            backgroundColor: '#39a930',
                            borderColor: '#0062cc',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
                        },
                    }}
                >
                    {activeStep == 2 ? "Cadastrar" : "Próximo"}
                </Button>
            </Box>
        </Box>
    )
}