import { Box, List, ListItem, ListItemText } from "@mui/material";
import TopicWithTable from "../TopicWithTable";
import React from "react";

interface Subtopic {
  title: string;
  subtopics?: Subtopic[];
}

interface TopicWithSubtopicsProps {
  index: number;
  title: string;
  subtopics: (Subtopic | string)[];
  tableData?: any;
  tableSubtopicIndex?: number;
}

export default function TopicWithSubtopics({
  index,
  title,
  subtopics,
  tableData,
  tableSubtopicIndex,
}: TopicWithSubtopicsProps) {
  return (
    <Box>
      <ListItemText primary={<strong>{`${index + 1}) ${title}`}</strong>} />
      {subtopics.map((subtopic, subIndex) => (
        <React.Fragment key={subIndex}>
          <ListItem>
            <ListItemText
              primary={`${index + 1}.${subIndex + 1} ${
                typeof subtopic === "string" ? subtopic : subtopic.title
              }`}
            />
          </ListItem>
          {typeof subtopic !== "string" && subtopic.subtopics && (
            <List>
              {subtopic.subtopics.map((nestedSubtopic, nestedSubIndex) => (
                <React.Fragment key={nestedSubIndex}>
                  <ListItem>
                    <ListItemText
                      primary={`${index + 1}.${subIndex + 1}.${
                        nestedSubIndex + 1
                      } ${
                        typeof nestedSubtopic === "string"
                          ? nestedSubtopic
                          : nestedSubtopic.title
                      }`}
                    />
                  </ListItem>
                  {typeof nestedSubtopic !== "string" &&
                    nestedSubtopic.subtopics && (
                      <List>
                        {nestedSubtopic.subtopics.map(
                          (nestedSubtopic2, nestedSubIndex2) => (
                            <React.Fragment key={nestedSubIndex2}>
                              <ListItem>
                                <ListItemText
                                  primary={`${index + 1}.${subIndex + 1}.${
                                    nestedSubIndex + 1
                                  }.${nestedSubIndex2 + 1} ${
                                    typeof nestedSubtopic2 === "string"
                                      ? nestedSubtopic2
                                      : nestedSubtopic2.title
                                  }`}
                                />
                              </ListItem>
                              {typeof nestedSubtopic2 !== "string" &&
                                nestedSubtopic2.subtopics && (
                                  <List>
                                    {nestedSubtopic2.subtopics.map(
                                      (nestedSubtopic3, nestedSubIndex3) => (
                                        <React.Fragment key={nestedSubIndex3}>
                                          <ListItem>
                                            <ListItemText
                                              primary={`${index + 1}.${
                                                subIndex + 1
                                              }.${nestedSubIndex + 1}.${
                                                nestedSubIndex2 + 1
                                              }.${nestedSubIndex3 + 1} ${
                                                typeof nestedSubtopic3 ===
                                                "string"
                                                  ? nestedSubtopic3
                                                  : nestedSubtopic3.title
                                              }`}
                                            />
                                          </ListItem>
                                        </React.Fragment>
                                      )
                                    )}
                                  </List>
                                )}
                            </React.Fragment>
                          )
                        )}
                      </List>
                    )}
                </React.Fragment>
              ))}
            </List>
          )}
          {tableData && tableSubtopicIndex === subIndex + 1 && (
            <TopicWithTable data={tableData} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
