import { Box, Typography } from "@mui/material";
import Topics from "./Topics";

export default function ContractHtml() {
  return (
    <Box
      sx={{
        fontFamily: "Courier Prime, monospace",
        my: 2,
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Typography variant="body1">
        TERMOS E CONDIÇÕES DE USO DOS SERVIÇOS – RENTI E LOCATÁRIO
      </Typography>

      <Typography variant="body1" my={2}>
        Data da última alteração: 10/08/2023.
      </Typography>

      <Topics />

      <Typography variant="body1" mx={2} fontWeight={"bold"}>
        A CONTRATAÇÃO DOS SERVIÇOS RENTI PRESSUPÕE A LEITURA INTEGRAL DOS
        PRESENTES TERMOS, SUA COMPREENSÃO E ACEITE PELA IMOBILIÁRIA, CORRETOR
        AUTÔNOMO, LOCATÁRIO E COOBRIGADO.
      </Typography>
    </Box>
  );
}
