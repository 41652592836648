import {
    FormHelperText,
    Stack,
    Typography,
} from "@mui/material";
import Input from "components/Input";
import { Controller, FormProvider } from "react-hook-form";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { maskCEP, maskNumber } from "utils/maskOutputs";
import { FETCH_CITIES } from "graphql/query/fetchCities";
import { SelectWithSearch } from "components/SelectWithSearch";
import FormActions from "./FormActions";
import { FETCH_STATES } from "graphql/query/fetchStates";

export default function FormLocation({
    formData,
    sendData,
    activeStep,
    setActiveStep
}: FormLocationProps) {
    const methods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            zipcode: formData.zipCode,
            address: formData.address,
            number: formData.number,
            district: formData.district,
            state: {
                id: formData.state.id,
                name: formData.state.name
            },
            complement: formData.complement,
            reference: formData.reference,
            city: {
                id: formData.city.id,
                name: formData.city.name
            }
        }
    });

    const { control, watch, setValue, getValues, trigger } = methods;

    const createDataObject = (values: any) => ({
        zipCode: values.zipcode,
        state: {
            id: Number(values.state.id),
            name: values.state.name || ""
        },
        city: {
            id: Number(values.city.id),
            name: values.city.name || ""
        },
        address: values.address,
        number: values.number || "",
        district: values.district,
        complement: values.complement || "",
        reference: values.reference || "",
    });

    const handleSendForm = async () => {
        await trigger();
        const data = createDataObject(getValues());
        sendData(data, "stepLocation");
    };

    useEffect(() => {
        setValue("city", formData.city)
        setValue("state", formData.state)
        setValue("zipcode", formData.zipCode)
        setValue("address", formData.address)
        setValue("number", formData.number)
        setValue("district", formData.district)
    }, [formData])
    
    return (
        <Box>
            <Box>
                <Typography
                    sx={{
                        fontSize: "1.3rem",
                        fontWeight: "400 ",
                        color: "#2E349F",
                    }}
                >
                    Preencha os dados sobre a locação que está sendo negociada entre o
                    locatário e a imobiliária
                </Typography>

                <FormProvider

                    {...methods}
                >
                    <Stack
                        component="form"
                        spacing={4}
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box display="flex" flexDirection="column" paddingTop="2rem">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="zipcode"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Cep*"
                                                type="text"
                                                fullWidth
                                                value={maskCEP(field.value)}
                                                showLabel={true}
                                                inputProps={{
                                                    maxLength: 9,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="address"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Endereço*"
                                                type="text"
                                                fullWidth
                                                value={field.value}
                                                showLabel={true}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="number"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Numero*"
                                                type="number"
                                                fullWidth
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(maskNumber(e.target.value))
                                                }}
                                                showLabel={true}
                                            />
                                        )}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="district"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Bairro*"
                                                type="text"
                                                fullWidth
                                                value={field.value}
                                                showLabel={true}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <SelectWithSearch
                                        query="fetchStates"
                                        queryHook={FETCH_STATES}
                                        label="Estado*"
                                        value={watch("state")?.name || ""}
                                        onChange={(e: { id: number, name: string }) => {
                                            setValue("state", e)
                                        }}
                                        error={methods.formState.errors.state?.name?.message}
                                    />
                                    
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <SelectWithSearch
                                        isDisabled={getValues("state").name == ""}
                                        query="fetchCities"
                                        queryHook={FETCH_CITIES}
                                        queryVariables={{
                                            state: getValues("state").name || "",
                                            limit: 860,
                                        }}
                                        label="Cidade*"
                                        value={watch("city")?.name || ""}
                                        onChange={(e: { id: number, name: string }) => {
                                            setValue("city", e)
                                        }}
                                        error={methods.formState.errors.city?.id?.message}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="complement"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Complemento"
                                                type="text"
                                                fullWidth
                                                value={field.value}
                                                showLabel={true}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <Controller
                                        name="reference"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Ponto de referência"
                                                type="text"
                                                fullWidth
                                                value={field.value}
                                                showLabel={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </FormProvider>
            </Box>

            <FormActions {...{ activeStep, setActiveStep, methods }} handleForm={handleSendForm} />
        </Box>
    );
}

interface FormLocationProps {
    formData: {
        zipCode: string,
        state: {
            id: number,
            name: string
        },
        city: {
            id: number,
            name: string
        },
        address: string,
        number: string,
        district: string,
        complement: string,
        reference: string,
    }
    sendData: (data: {
        zipCode: string;
        state: {
            id: number,
            name: string
        };
        city: {
            id: number,
            name: string
        };
        address: string;
        number: string;
        district: string;
        complement: string;
        reference: string;
    }, objName: string) => void;
    activeStep: number,
    setActiveStep: React.Dispatch<React.SetStateAction<number>>,
}


const schema = yup.object({
    zipcode: yup.string().required("CEP é obrigatório"),
    address: yup.string().required("Endereço é obrigatório"),
    number: yup.string().required("Número é obrigatório"),
    district: yup.string().required("Bairro é obrigatório"),
    state: yup.object().shape({
        id: yup.number().nullable(),
        name: yup.string().test('check-name', 'Estado é obrigatório', value => value !== ""),
    }).required('Nome do estado é obrigatório'),
    complement: yup.string(),
    reference: yup.string(),
    city: yup.object().shape({
        id: yup.number().nullable().test('check-id', 'Cidade é obrigatório', value => value !== 0),
        name: yup.string().test('check-name', 'Cidade é obrigatório', value => value !== ""),
    }).required('Nome da cidade é obrigatório'),
});