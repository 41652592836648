import { useState } from "react";

import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { UPDATE_OCCURRENCE } from "graphql/mutation/updateOccurrence";
import { useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import { DETAIL_OCCURRENCE } from "graphql/query/detailOccurrence";
import FETCH_OCCURRENCES from "graphql/query/fetchOccurrences";

interface EditStatusProps {
    id: number;
    occurrenceStatus: string;
    closeModal: () => void;
}

export const EditStatus: React.FC<EditStatusProps> = ({ id, occurrenceStatus, closeModal }) => {
    const [status, setStatus] = useState(occurrenceStatus);

    const [updateOccurrenceStatus, { loading }] = useMutation(UPDATE_OCCURRENCE, {
        refetchQueries: [
            { query: DETAIL_OCCURRENCE, variables: { id } },
            { query: FETCH_OCCURRENCES }
        ]
    });

    const handleUpdateStatus = async () => {
        const data = {
            id,
            status
        };
        
        try {
            await updateOccurrenceStatus({
                variables: {
                    id,
                    status
                }
            });

            enqueueSnackbar("Status atualizado com sucesso!", {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              });
        } catch (error) {
            enqueueSnackbar("Erro ao atualizar status!", {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              });
        } finally {
            closeModal();
        }
    };

    return (
        <Box pt={1}>
            <FormControl fullWidth>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={status}
                    label="Status"
                    onChange={(e) => setStatus(e.target.value as string)}
                >
                    <MenuItem value={"Cancelado"}>CANCELADO</MenuItem>
                    <MenuItem value={"Pago"}>PAGO</MenuItem>
                    <MenuItem value={"Pendente"}>PENDENTE</MenuItem>
                    <MenuItem value={"Exonerado"}>EXONERADO</MenuItem>
                </Select>
            </FormControl>

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                marginTop={4}
                spacing={1}
            >
                <Grid item>
                    <Button disabled={loading} onClick={closeModal}>
                        Cancelar
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        type="submit"
                        autoFocus
                        onClick={handleUpdateStatus}
                        disabled={loading}
                        sx={{
                            backgroundColor: "#51d347",
                            "&:hover": {
                                backgroundColor: "#39a930",
                                borderColor: "#0062cc",
                                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                            },
                        }}
                    >
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            "Atualizar"
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}