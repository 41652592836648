import { gql } from "@apollo/client";

export const COUNTERS_BY_STATUS_PROPOSALS= gql`
  query countersByStatusProposals($keyword: String, $status: String) {
    countersByStatusProposals(keyword: $keyword, status: $status ) {
      status
      total
    }
  }

`;