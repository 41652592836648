import { Button, CardContent, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { COUNTERS_BY_STATUS_PROPOSALS } from "graphql/query/countersByStatusProposalsResponse";
import { useQuery } from "@apollo/client";
import { CircularLoading } from "components/CircularLoading";
import DataDoteDisplay from "components/DataDoteDisplay";


export function CardAmountProposals({
  handleClick
}: CardAmountProposalsProps) {

  const { data, loading } = useQuery(COUNTERS_BY_STATUS_PROPOSALS, {
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <CircularLoading />;
  }

  const renderedItems = data?.countersByStatusProposals?.map(
    (item: StatusProposal) => (
      <Grid container spacing={1} key={item.status} display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column">
        <Grid item xs={12} width="100%">
          <DataDoteDisplay label={item.status} value={item.total} />
        </Grid>
      </Grid>
    )
  );

  return (
    <Card
      sx={{
        backgroundColor: "#EEF0F8",
        maxWidth: "100%",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        color: "#2E349F",
        padding: "1rem",
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {renderedItems}
      </CardContent>
      <Button
        variant="contained"
        fullWidth
        onClick={handleClick}
      >
        <b>Filtros</b>
      </Button>
    </Card>
  );
}

interface CardAmountProposalsProps {
  handleClick: () => void
}
interface StatusProposal {
  status: string;
  total: number;
}