import { TableCell, TableRow } from "@mui/material";
import { maskCPF } from "utils/maskOutputs";

interface ContractProps {
  code: string;
  name: string;
  document: string;
}

export function ContractTable({ code, name, document }: ContractProps) {
  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {code}
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        {maskCPF(document)}
      </TableCell>
    </TableRow>
  );
}