import { gql } from "@apollo/client";

const FETCH_BANKS_QUERY = gql`
  query FetchBanks($keyword: String) {
    fetchBanks(keyword: $keyword) {
      id
      code
      name
    }
  }
`;

export default FETCH_BANKS_QUERY;
