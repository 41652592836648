import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";

import { useMutation, useQuery } from "@apollo/client";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CardEdit from "components/CardEdit";
import { useEffect, useState } from "react";
import { maskCEP, maskCPF, maskDate, maskMoney } from "utils/maskOutputs";
import useVerifyScreen from "hooks/useVerifyScreen";
import SpeedDialComponent from "components/SpeedDial";

import { checkPattern } from "utils/helpers";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import CANCEL_CONTRACT from "graphql/mutation/cancelContract";
import { enqueueSnackbar } from "notistack";
import { FETCH_CONTRACTS } from "graphql/query/fetchContracts";
import { Historic } from "pages/DetailProposal/Historic";
import ModalAddContract from "./ModalAddOccurrenceByContract";
import { getStatusColor } from "utils/getStatusColor";
import ModalAddOccurrenceByContract from "./ModalAddOccurrenceByContract";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { CardTermOfUse } from "pages/DetailProposal/CardTermOfUse";

export function ContractDetail() {
  const isMobile = useVerifyScreen();

  const { id } = useParams();
  const { pathname } = useLocation();

  const location = useLocation();
  const contractId = location.state?.id;

  const navigate = useNavigate();

  const fromContract: boolean = checkPattern(pathname, /^\/contrato\/\d+$/);

  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [formDetail, setFormDetail] = useState<any>({});
  const [open, setOpen] = useState(false);

  const { data, loading } = useQuery(DETAIL_PROPOSAL, {
    variables: {
      id: Number(id),
    },
  });

  const occurrences = data?.detailProposal?.contract?.occurrences;

  const requiredTypes = ["termos de uso", "vistoria", "contrato"];
  const attachments = data?.detailProposal?.attachments || [];

  attachments.forEach((attachment: { type: string; }) => {
    const index = requiredTypes.indexOf(attachment.type.toLowerCase());
    if (index !== -1) {
      requiredTypes.splice(index, 1);
    }
  });

  const isDefaulter = () => {
    if (occurrences && occurrences.length) {
      for (let i = 0; i < occurrences.length; i++) {
        const occurrenceStatus = occurrences[i].status;
        if (occurrenceStatus === "Pendente" || occurrenceStatus === "Exonerado") {
          return true;
        }
      }
      return false;
    }
  }

  const [cancelContract, { loading: loadingContract }] = useMutation(
    CANCEL_CONTRACT,
    {
      refetchQueries: [
        {
          query: FETCH_CONTRACTS,
          variables: {
            limit: 10,
            page: 1,
          },
        },
      ],
    }
  );

  const handleBack = () => {
    navigate(-1)
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      await cancelContract({
        variables: {
          id: Number(contractId),
        },
      });
      enqueueSnackbar("Contrato cancelado com sucesso", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      handleClose();

      navigate("/contratos");
    } catch (error) {
      enqueueSnackbar("Erro ao cancelar contrato", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      console.error(error);
    }
  };

  const isPhone = useMediaQuery("(max-width: 500px) ");
  const isBigScreen = useMediaQuery("(max-width: 1400px) ");
  const isDesktop = useMediaQuery("(max-width: 1000px) ");

  const actionsButton = [
    {
      icon: <NotInterestedIcon />,
      name: "Encerrar contrato",
      onClick: handleOpen,
    },
    {
      icon: <PersonOffIcon />,
      name: "Apontar inadimplência",
      onClick: () => setOpenModalAdd(true),
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const extras = data?.detailProposal?.tenant.extras ? JSON.parse(data?.detailProposal?.tenant.extras) : {};

      setFormDetail({
        rentalData: {
          zipCode: data?.detailProposal?.immobile.zipcode,
          address: data?.detailProposal?.immobile.street,
          number: data?.detailProposal?.immobile.number,
          complement: data?.detailProposal?.immobile.complement,
          district: data?.detailProposal?.immobile.district,
          city: {
            id: null,
            name: data?.detailProposal?.immobile.city.name,
          },
          state: {
            id: null,
            name: data?.detailProposal?.immobile.city.state,
          },
          referencePoint: data?.detailProposal?.immobile.reference,
        },
        paymentDetails: {
          iptu: data?.detailProposal?.iptu || 0,
          rent: data?.detailProposal?.rent_amount,
          condo: data?.detailProposal?.condominium_amount,
          otherTaxes: data?.detailProposal?.other_taxes,
          setupFee: data?.detailProposal?.chosen_setup_fee,
          setupInstallments: data?.detailProposal?.chosen_setup_installments,
          plan: data?.detailProposal?.chosen_plan,
          planInstallments: data?.detailProposal?.chosen_installments,
          totalValue:
            data?.detailProposal?.iptu +
            data?.detailProposal?.rent_amount +
            data?.detailProposal?.condominium_amount +
            data?.detailProposal?.other_taxes,
          warranty: data?.detailProposal?.warranty_months,
        },
        tenantData: {
          name: data?.detailProposal?.tenant.name,
          cpf: data?.detailProposal?.tenant.document,
          birth: data?.detailProposal?.tenant.birth_date,
          income: data?.detailProposal?.tenant.income || 0,
          email: data?.detailProposal?.tenant.email,
          phone: data?.detailProposal?.tenant.phone,
          is_payment_responsible: data.detailProposal.tenant.is_payment_responsible,
          extras: {
            email: extras?.email || '',
            phone: extras?.phone || '',
            address: {
              number: extras?.address?.number || '',
              street: extras?.address?.street || '',
              city: {
                id: extras?.address?.city ? extras.address.city.id : '',
                name: extras?.address?.city ? extras.address.city.name : '',
                state: extras?.address?.city ? extras.address.city.state : '',
              },
              zipcode: extras?.address?.zipcode || '',
              district: extras?.address?.district || '',
              reference: extras?.address?.reference || '',
              complement: extras?.address?.complement || '',
            },
          }
        },
      });
    }
  }, [data]);

  const transformArray = (formItem: "rentalEl" | "paymentEl" | "tenantEl" | "extraTenantEl") => {
    if (Object.keys(formDetail).length > 0) {
      const { rentalData, paymentDetails, tenantData } = formDetail;

      const data = {
        rentalEl: [
          { title: "CEP", value: maskCEP(rentalData.zipCode) },
          { title: "Endereço", value: rentalData.address },
          { title: "Número", value: rentalData.number },
          { title: "Complement", value: rentalData.complement },
          { title: "Bairro", value: rentalData.district },
          { title: "Cidade", value: rentalData.city.name },
          { title: "Estado", value: rentalData.state.name },
          { title: "Referência", value: rentalData.referencePoint },
        ],
        paymentEl: [
          { title: "Aluguel", value: maskMoney(paymentDetails.rent) },
          { title: "Condomínio", value: maskMoney(paymentDetails.condo) },
          {
            title: "Outras Taxas",
            value: maskMoney(paymentDetails.otherTaxes),
          },
          { title: "IPTU", value: maskMoney(paymentDetails.iptu) },
          { title: "Valor Total", value: maskMoney(paymentDetails.totalValue) },
          { title: "Plano", value: paymentDetails.plan },
          { title: "Parcelas Plano", value: paymentDetails.planInstallments },
          { title: "Taxa Setup", value: maskMoney(paymentDetails.setupFee) },
          { title: "Parcelas Setup", value: paymentDetails.setupInstallments },
          { title: "Garantia", value: paymentDetails.warranty + "x" },
        ],
        tenantEl: [
          { title: "Nome", value: tenantData.name },
          { title: "CPF", value: maskCPF(tenantData.cpf) },
          { title: "Nascimento", value: maskDate(tenantData.birth) },
          { title: "E-mail", value: tenantData.email },
          { title: "Telefone", value: tenantData.phone },
        ],
        extraTenantEl: [
          { title: "E-mail", value: tenantData.extras.email || '' },
          { title: "Telefone", value: tenantData.extras.phone || '' },
          { title: "CEP", value: maskCEP(tenantData.extras.address.zipcode || '') },
          { title: "Endereço", value: tenantData.extras.address.street || '' },
          { title: "Número", value: tenantData.extras.address.number || '' },
          { title: "Estado", value: tenantData.extras.address.city.state || '' },
          { title: "Cidade", value: tenantData.extras.address.city.name || '' },
          { title: "Bairro", value: tenantData.extras.address.district || '' },
          { title: "Referência", value: tenantData.extras.address.reference || '' },
          { title: "Complemento", value: tenantData.extras.address.complement || '' },
        ],
      };
      return data[formItem];
    }
  };

  if (loading || !data)
    return (
      <Box width="100%" textAlign="center" mt={6}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleBack}>
          <ArrowBackIosRoundedIcon sx={{ color: "#2E349F" }} />
        </IconButton>
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "900",
            color: "#2E349F",
            textAlign: "start",
          }}
        >
          {fromContract ? "Contrato cadastrado" : "Proposta Cadastrada"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          color: "#2E349F",
          gap: "3rem",
          padding: "2rem 0 2rem 0",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <PersonIcon />
          <Typography sx={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
            <b>Locatário: </b>
            {data?.detailProposal?.tenant.name}
          </Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <EventIcon />
          <Typography sx={{ fontSize: "1rem", marginLeft: "0.5rem" }}>
            <b>Data de Criação: </b>
            {maskDate(data?.detailProposal?.created_at)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          color: "#2E349F",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>
              <b>Solicitação:</b> {data?.detailProposal?.code}
            </Typography>
            <Chip
              label={data?.detailProposal.contract?.status}
              sx={{
                marginTop: 1,
                fontWeight: "bolder",
                maxWidth: 200,
                backgroundColor: getStatusColor(
                  "Contrato",
                  data?.detailProposal.contract?.status
                ),
              }}
            />
          </Grid>

          <Grid
            item xs={12} md={5} lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              gap: "1rem",
            }}
          >
            {requiredTypes.length > 0 && (
              <Alert style={{ alignItems: "center", fontSize: 18 }} severity="error">
                Faltam arquivos obrigatórios: <strong>{requiredTypes.join(", ")}</strong>.
              </Alert>
            )}

            {occurrences && (
              <Alert style={{ alignItems: "center", fontSize: 18 }} severity="warning">
                Contrato inadimplente.
              </Alert>
            )}
          </Grid>
        </Grid>
      </Box>


      <Grid
        container
        spacing={3}
        sx={{
          marginTop: "2rem",
          display: "flex",
          gap: isPhone ? "1rem" : "4rem",
        }}
      >
        <Grid item xs={11} sm={12} md={isDesktop ? 12 : 5}>
          <CardEdit
            {...{ formDetail, fromContract }}
            title="Dados da Locação"
            elements={transformArray("rentalEl")}
            form="location"
          />
          <CardEdit
            {...{ formDetail, fromContract }}
            title="Dados de Pagamento"
            elements={transformArray("paymentEl")}
            form="payment"
          />
          <CardEdit
            {...{ formDetail, fromContract }}
            title="Dados do locatário"
            elements={transformArray("tenantEl")}
            form="tenant"
          />
          <CardEdit
            {...{ formDetail, fromContract }}
            title="Dados extras do locatário"
            elements={transformArray("extraTenantEl")}
            form="tenant"
          />

          <CardTermOfUse code={data.detailProposal.code || ""} />
        </Grid>

        <Grid item xs={12} sm={12} md={isDesktop ? 12 : 5}>
          <Historic timeline={data?.detailProposal?.history || []} />
        </Grid>
      </Grid>

      {fromContract && (
        <SpeedDialComponent ariaLabel="Opções" actions={actionsButton} />
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você tem certeza que deseja encerrar o contrato?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            disabled={loadingContract}
            variant="contained"
            onClick={handleConfirm}
            autoFocus
            sx={{
              backgroundColor: "#51d347",
              "&:hover": {
                backgroundColor: "#39a930",
                borderColor: "#0062cc",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            {loadingContract ? <CircularProgress size={24} /> : "Confirmar"}
          </Button>
        </DialogActions>
      </Dialog>

      {openModalAdd && (
        <ModalAddOccurrenceByContract
          open={openModalAdd}
          handleClose={() => setOpenModalAdd(false)}
          contractId={contractId}
        />
      )}
    </Box>
  );
}
