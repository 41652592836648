import { Box } from "@mui/material";

export default function CardWrapper({
    children
}: CardWrapperProps) {
    return (
        <Box
            mb={4}
            sx={{
                backgroundColor: "#EEF0F8",
                padding: 4,
                color: "#2E349F",
                width: "100%",
                borderRadius: "5px",
            }}
        >
            { children }
        </Box>
    )
}

interface CardWrapperProps {
    children: React.ReactNode
}