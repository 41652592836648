import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DELETE_ATTACHMENT_PROPOSAL } from "graphql/mutation/deleteAttachmentProposal";
import { enqueueSnackbar } from "notistack";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  itemId: number | null;
  onDeleted: () => void;
}

export const DeleteConfirmationDialog: React.FC<
  DeleteConfirmationDialogProps
> = ({ open, onClose, itemId, onDeleted }) => {

  const [deleteAttachmentProposal, { loading }] = useMutation(
    DELETE_ATTACHMENT_PROPOSAL
  );

  const handleConfirm = async () => {
    try {
      await deleteAttachmentProposal({ variables: { id: itemId } });
      onDeleted();

      enqueueSnackbar("Anexo excluído com sucesso!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      onClose();
    } catch (error) {
      console.error("Failed to delete attachment:", error);
      enqueueSnackbar("Falha ao excluir anexo", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tem certeza de que deseja excluir este anexo?</DialogTitle>
      <DialogContent>
        <DialogContentText>Esta ação não pode ser desfeita.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          color="error"
          disabled={loading}
          autoFocus
        >
          {loading ? <CircularProgress size={24} /> : "Excluir"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
