import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ModalResponsible } from "./ModalResponsible";
import CardWrapper from "components/CardWrapper";
import InfoIcon from "@mui/icons-material/Info";
import { maskDate, maskPhone } from "utils/maskOutputs";
import IconButton from "components/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DetailProposalCoResponsibleResponse,
  DetailProposalResponse,
} from "graphql/gql/graphql";
import { DELETE_CO_RESPONSIBLE_PROPOSAL } from "graphql/mutation/deleteCoResponsibleProposal";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

export function DataResponsible({
  coResponsibleData,
}: {
  coResponsibleData: DetailProposalResponse;
}) {
  const { id } = useParams<{ id: string }>();

  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState<DetailProposalResponse | undefined>(
    undefined
  );

  const [deleteCoResponsibleProposal] = useMutation(
    DELETE_CO_RESPONSIBLE_PROPOSAL,
    {
      refetchQueries: [
        {
          query: DETAIL_PROPOSAL,
          variables: { id: Number(id) },
        },
      ],
    }
  );

  const [selectedCoResponsible, setSelectedCoResponsible] =
    useState<DetailProposalCoResponsibleResponse | null>(null);

  const handleDeleteItem = async (index: number) => {
    let arrayClone = [...(data?.co_responsibles || [])];
    const coResponsibleToDelete = arrayClone[index];

    try {
      await deleteCoResponsibleProposal({
        variables: { id: coResponsibleToDelete?.id },
      });

      arrayClone.splice(index, 1);
      setData((prevData) => ({ ...prevData, co_responsibles: arrayClone }));

      enqueueSnackbar("Corresponsável excluído com sucesso", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    } catch (error) {
      console.error("Erro ao deletar co-responsável: ", error);
    }
  };

  const handleEditItem = (item: DetailProposalCoResponsibleResponse) => {
    setSelectedCoResponsible(item);
    setIsEditing(true);
    setOpenModal(true);
  };

  useEffect(() => {
    setData(coResponsibleData);
  }, [coResponsibleData]);

  return (
    <CardWrapper>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "900",
            color: "#2E349F",
          }}
        >
          Dados do corresponsável
        </Typography>
      </Box>

      <Grid container spacing={2} mt={4} mb={4}>
        {data && data.co_responsibles && data.co_responsibles.length <= 0 ? (
          <Grid item xs={12} display="flex" gap={1}>
            <InfoIcon />
            <Typography>Nenhum Corresponsável adicionado </Typography>
          </Grid>
        ) : (
          data &&
          data.co_responsibles &&
          data.co_responsibles
            .filter(
              (item): item is DetailProposalCoResponsibleResponse =>
                item !== null
            )
            .map((item: DetailProposalCoResponsibleResponse, index: number) => (
              <Grid
                item
                key={index}
                xs={12}
                display="flex"
                flexDirection="column"
                gap={1}
                justifyContent="space-between"
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={11}>
                    <Grid item xs={12} md={8} sx={{ wordWrap: "break-word" }}>
                      <Typography>
                        <b>Nome: </b> {item.person?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ wordWrap: "break-word" }}>
                      <Typography>
                        <b>E-mail: </b> {item.person?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ wordWrap: "break-word" }}>
                      <Typography>
                        <b>CPF: </b> {item.person?.document}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ wordWrap: "break-word" }}>
                      <Typography>
                        <b>Data Nasc.: </b>
                        {item.person?.birth_date
                          ? maskDate(item.person.birth_date)
                          : "ss"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ wordWrap: "break-word" }}>
                      <Typography>
                        <b>Telefone: </b>{" "}
                        {item.person?.phone ? maskPhone(item.person.phone) : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="edit"
                      size="medium"
                      color="primary"
                      onClick={() => handleEditItem(item)}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      color="error"
                      onClick={() => handleDeleteItem(index)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))
        )}
      </Grid>

      <Button variant="outlined" onClick={() => setOpenModal(true)}>
        Adicionar Corresponsável
      </Button>

      {openModal && (
        <ModalResponsible
          isEditing={isEditing}
          {...{ setData, data }}
          selectedCoResponsible={selectedCoResponsible}
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            setSelectedCoResponsible(null);
          }}
        />
      )}
    </CardWrapper>
  );
}
