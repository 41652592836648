import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { maskCPF, maskPhone } from "utils/maskOutputs";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "components/Input";

import { CREATE_CO_RESPONSIBLE_PROPOSAL } from "graphql/mutation/createCoResponsibleProposal";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";
import { useMutation } from "@apollo/client";

import { useParams } from "react-router-dom";

import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { DetailProposalCoResponsibleResponse } from "graphql/gql/graphql";
import { UPDATE_CO_RESPONSIBLE_PROPOSAL } from "graphql/mutation/updateCoResponsibleProposal";
import { enqueueSnackbar } from "notistack";

export function ModalResponsible({
  open,
  handleClose,
  setData,
  selectedCoResponsible,
  isEditing,
}: ModalResponsibleProps) {
  const { id } = useParams();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: isEditing
      ? {
          name: selectedCoResponsible?.person?.name || "",
          email: selectedCoResponsible?.person?.email || "",
          cpf: selectedCoResponsible?.person?.document || "",
          phone: selectedCoResponsible?.person?.phone || "",
          selectedDate: selectedCoResponsible?.person?.birth_date || "",
          payment_responsible:
            selectedCoResponsible?.is_payment_responsible || false,
        }
      : {
          name: "",
          email: "",
          cpf: "",
          phone: "",
          selectedDate: dayjs().format("YYYY-MM-DD"),
        },
  });

  const [createCoResponsibleProposal, { loading, error }] = useMutation(
    CREATE_CO_RESPONSIBLE_PROPOSAL,
    {
      refetchQueries: [
        { query: DETAIL_PROPOSAL, variables: { id: Number(id) } },
      ],
    }
  );

  const [updateCoResponsibleProposal] = useMutation(
    UPDATE_CO_RESPONSIBLE_PROPOSAL,
    {
      refetchQueries: [
        { query: DETAIL_PROPOSAL, variables: { id: Number(id) } },
      ],
    }
  );

  const { handleSubmit, control, watch, setValue, getValues } = methods;

  const onSubmit = async () => {
    const { name, email, cpf, phone, selectedDate } = getValues();
    let suitor;

    if (!isEditing) {
      suitor = {
        name,
        email,
        document: cpf,
        phone,
        birth_date: dayjs(selectedDate).format("YYYY-MM-DD"),
      };
      try {
        const result = await createCoResponsibleProposal({
          variables: { id: Number(id), suitor },
        });
        enqueueSnackbar("Dados adicionados com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      } catch (error) {
        console.error("Erro na mutation:", error);
        enqueueSnackbar("Erro ao adicionar dados", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    } else {
      suitor = {
        email,
        phone,
        birth_date: dayjs(selectedDate).format("YYYY-MM-DD"),
        payment_responsible: watch("payment_responsible"),
      };
      try {
        const result = await updateCoResponsibleProposal({
          variables: { id: Number(selectedCoResponsible?.id), suitor },
        });
        enqueueSnackbar("Dados atualizados com sucesso", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      } catch (error) {
        console.error("Erro na mutation:", error);
        enqueueSnackbar("Erro ao atualizar dados", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
      }
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        {isEditing ? "Editar " : "Adicionar "} Corresponsável
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Grid container spacing={3} paddingTop={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="cpf"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="CPF*"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 14 }}
                    value={maskCPF(field.value)}
                    showLabel={true}
                    disabled={isEditing}
                  />
                )}
              />
            </Grid>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <Grid item xs={12} md={6}>
                <Controller
                  name="selectedDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label="Data de Nascimento*"
                      format="DD/MM/YYYY"
                      value={dayjs(field.value)}
                      onChange={(date) => {
                        if (date) {
                          setValue("selectedDate", date.format("YYYY-MM-DD"));
                        } else {
                          setValue("selectedDate", "");
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Nome*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                    disabled={isEditing}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Email*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Telefone*"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    value={maskPhone(field.value)}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            {isEditing && (
              <Box display="flex" alignItems="center" ml={3} mt={2}>
                <Controller
                  name="payment_responsible"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        color: "#51d347",
                        "&.Mui-checked": {
                          color: "#51d347",
                        },
                      }}
                    />
                  )}
                />
                <span>Responsável pelo Pagamento</span>
              </Box>
            )}
          </Grid>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          autoFocus
          sx={{
            backgroundColor: "#51d347",
            "&:hover": {
              backgroundColor: "#39a930",
              borderColor: "#0062cc",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            },
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ModalResponsibleProps {
  open: boolean;
  selectedCoResponsible: DetailProposalCoResponsibleResponse | null;
  handleClose: () => void;
  setData: (arg: any) => void;
  isEditing: boolean;
}

const schema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  email: yup.string().required("E-mail é obrigatório"),
  cpf: yup.string().required("CPF é obrigatório"),
  phone: yup.string().required("Telefone é obrigatório"),
  selectedDate: yup.string().required("Data de nascimento é obrigatório"),
});
