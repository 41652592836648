import Box from "@mui/material/Box";
import { List, ListItem, Typography } from "@mui/material";

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import FeedIcon from '@mui/icons-material/Feed';
import ListIcon from '@mui/icons-material/List';

import { Link } from "react-router-dom";

import { useAuthContext } from "context/AuthProvider";
import { useMenuContext } from "context/MenuContext";

interface SidebarProps {
  openMenu: boolean,
  selectedItemMenu: string,
  setSelectedItemMenu: (args: string) => void,
}

export function Sidebar({
  openMenu
}: SidebarProps) {

  const { handleLogout, state } = useAuthContext();
  const { selectedItemMenu, setSelectedItemMenu } = useMenuContext();

  if (!state) {
    return null
  }
  
  const { profile } = state;

  return (
    <Box
      sx={{
        width: openMenu ? 200 : 100,
        height: "100%",
        position: "fixed",
        color: "white",
        marginTop: 1,
        transition: "0.3s",
        borderRight: "1px solid #f3f3f3",
      }}
    >
      <List sx={{ textDecoration: "none" }}>
        {
          menuItems.map((item, index) => {
            if (profile && profile !== "Real estate" && item.name === "Configurações") {
              return null;
            }

            return (
              <Link
                key={index}
                to={item.route}
                onClick={() => {
                  if (item.name == "Sair") {
                    handleLogout()
                    return
                  }
                  setSelectedItemMenu(item.route)
                }}
                style={{
                  textDecoration: "none"
                }}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    flexDirection: openMenu ? "row" : "column",
                    gap: 1,
                    alignItems: "center",
                    justifyItems: "center",
                    paddingTop: openMenu ? 3 : 2,
                    paddingBottom: openMenu ? 3 : 2,
                    "&:hover": {
                      backgroundColor: "rgba(46, 52, 159, 0.1)"
                    },
                    backgroundColor: selectedItemMenu == item.route ? "rgba(46, 52, 159, 0.1)" : "white",
                    transition: "0.3s"
                  }}
                >
                  {item.icon}
                  <Typography sx={{
                    color: "#2E349F",
                    fontSize: openMenu ? 14 : 13
                  }}>{item.name}</Typography>
                </ListItem>
              </Link>
            )
          })
        }
      </List>
    </Box>
  );
}

const itemsColor = "#2E349F"

const menuItems = [
  {
    route: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/propostas",
    name: "Propostas",
    icon: <FeedIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/contratos",
    name: "Contratos",
    icon: <ReceiptLongIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/ocorrencias",
    name: "Ocorrências",
    icon: <PersonOffIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/relatorios",
    name: "Relatórios",
    icon: <ListIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/configuracoes",
    name: "Configurações",
    icon: <SettingsIcon sx={{ color: itemsColor }} />
  },
  {
    route: "/login",
    name: "Sair",
    icon: <ExitToAppIcon sx={{ color: itemsColor }} />
  }
]