export const checkPattern = (text: string, regex: RegExp) => {
  return regex.test(text);
};

export function verifyUnTokenRoutes() {
  let { pathname } = window.location;

  return (
    pathname.includes("/login") ||
    pathname.includes("/assinatura") ||
    pathname.includes("/visualizar-contrato") ||
    pathname === "/"
  );
}
