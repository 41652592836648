import { useEffect, useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import CardWrapper from "components/CardWrapper";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "components/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ModalSendDocument from "../../../components/ModalSendDocument";
import {
  DetailProposalAttachmentsResponse,
  DetailProposalResponse,
} from "graphql/gql/graphql";
import { FormProvider, useForm } from "react-hook-form";
import { DeleteConfirmationDialog } from "./DeleteConfirmation";

export function CardUpFile({
  attachments,
}: {
  attachments: DetailProposalResponse;
}) {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<DetailProposalResponse | undefined>(
    undefined
  );

  const [groupedAttachments, setGroupedAttachments] = useState<{ [key: string]: DetailProposalAttachmentsResponse[] }>({});

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);

  const handleOpenDeleteDialog = (id: number | null) => {
    setItemToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

const handleDeleteItem = () => {
  if (itemToDelete !== null) {
    setData((prevData) => ({
      ...prevData,
      attachments: prevData?.attachments?.filter(
        (attachment) => attachment?.id !== itemToDelete
      ),
    }));
  }
};

  const methods = useForm();

  useEffect(() => {
    setData(attachments);
  }, [attachments]);

  useEffect(() => {
    if (data?.attachments) {
      const attachmentsByType: { [key: string]: DetailProposalAttachmentsResponse[] } = {};

      data.attachments.forEach((attachment) => {
        if (!attachmentsByType[attachment!.type!]) {
          attachmentsByType[attachment!.type!] = [];
        }

        attachmentsByType[attachment!.type!].push(attachment!);
      });

      setGroupedAttachments(attachmentsByType);
    }
  }, [data]);

  return (
    <FormProvider {...methods}>
      <CardWrapper>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "900",
              color: "#2E349F",
            }}
          >
            Anexos
          </Typography>
        </Box>
        <Grid container spacing={2} mt={4} mb={4}>
          {data && Object.keys(data).length <= 0 ? (
            <Grid item xs={12} display="flex" gap={1}>
              <InfoIcon />
              <Typography>Nenhum anexo adicionado </Typography>
            </Grid>
          ) : (
            Object.keys(groupedAttachments).map((type) => (
              <Grid item xs={12} key={type}>
                <Typography variant="h6">{type}</Typography>
                {groupedAttachments[type].map((item: DetailProposalAttachmentsResponse, index: number) => (
                  <Grid
                    container
                    item
                    key={index}
                    xs={12}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={11}>
                      <Box display="flex" alignItems="center">
                        <AttachFileIcon sx={{ fontSize: 16 }} />
                        <Link
                          href={item.url || ""}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.name || ""}
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        color="error"
                        onClick={() => handleOpenDeleteDialog(item.id ?? 0)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))
          )}
        </Grid>
        <Button variant="outlined" onClick={() => setOpenModal(true)}>
          Adicionar anexos
        </Button>

        {openModal && (
          <ModalSendDocument
            {...{ setData, data }}
            open={openModal}
            handleClose={() => setOpenModal(false)}
          />
        )}

        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          itemId={itemToDelete ?? 0}
          onDeleted={handleDeleteItem}
        />
      </CardWrapper>
    </FormProvider>
  );
}
