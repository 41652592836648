import { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useAuthContext } from "context/AuthProvider";

import { useQuery } from "@apollo/client";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { maskCNPJ, maskPhone } from "utils/maskOutputs";

import UserTable from "./UserTable";
import ModalEdit from "./ModalEdit";

import { ME } from "graphql/query/me";
import { QueryRoot } from "graphql/gql/graphql";
import { Navigate } from "react-router-dom";
import { BankTable } from "./BankTable";

export function Settings() {
  const { state } = useAuthContext();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const { data, loading, error } = useQuery<QueryRoot>(ME, {
    variables: { id: state?.person.id },
  });

  const isAdmin = state?.profile === 'Real estate';

  const me = data?.me;
  const me_id = me?.id;
  const users = me?.users;
  const real_estate_id = me?.real_estate?.id;
  const bank_data = me?.bank_data;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!isAdmin) {
    return <Navigate to="/dashboard" />;
  }

  if (error) return <p>Error :(</p>;

  return (
    <Grid container>
      <Grid item xs={12} marginBottom={4}>
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "bolder",
            color: "#2E349F",
            textAlign: "start",
          }}
        >
          Configurações
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        lg={3}
        container
        direction="column"
        alignItems={isSmallScreen ? "center" : "flex-start"}
      >
        <Box ml={isSmallScreen ? 0 : 6}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            badgeContent={<DeleteIcon />}
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 130, height: 130 }}
            />
          </Badge>
          <Box mt={2} ml={2}>
            <Typography variant="inherit" color={"#2E349F"}>
              Editar imagem
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={8} marginTop={isSmallScreen ? 4 : 0}>
        <Card>
          <CardHeader title="Informações do Usuário" />
          {loading ? (
            <Box width="100%" textAlign="center" mt={6} mb={6}>
              <CircularProgress />
            </Box>
          ) : (
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Nome
                  </Typography>
                  <Typography variant="body1">{me?.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    CNPJ
                  </Typography>
                  <Typography variant="body1">
                    {maskCNPJ(me?.document || "")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Telefone
                  </Typography>
                  <Typography variant="body1">
                    {maskPhone(me?.phone || "")}
                  </Typography>
                </Grid>
              </Grid>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Editar
                </Button>
              </CardActions>
              <ModalEdit
                open={open}
                handleClose={handleClose}
                data={me}
              />
            </CardContent>
          )}
        </Card>

        <Grid item xs={12} mt={4}>
          <Card>
            <CardHeader title="Tabela de Usuários" />
            {loading ? (
              <Box width="100%" textAlign="center" mt={6} mb={6}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <CardContent>
                  <UserTable
                    users={
                      users?.map((user) => ({
                        __typename: user?.__typename,
                        email: user?.email,
                        id: user?.id,
                        profile: user?.profile,
                        status: user?.status,
                      })) || []
                    }
                    real_estate_id={real_estate_id || 0}
                    me_id={me_id || 0}
                  />
                </CardContent>
              </>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} mt={4}>
          <Card>
            <CardHeader title="Dados bancário" />
            {loading ? (
              <Box width="100%" textAlign="center" mt={6} mb={6}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <CardContent>
                  <BankTable
                    me_id={me_id || 0}
                    bank_data={bank_data || {}}
                  />
                </CardContent>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
