import { gql } from "@apollo/client";

export const ME = gql`
  query Me($id: Int!) {
    me(id: $id){
      id
      name
      document
      birth_date
      phone
      zipcode
      email
      street
      number
      district
      complement
      reference
      created_at
      updated_at
      real_estate {
        id
      }
      city {
        id
        name
      }
      owner_user {
        id
        email
        status
        profile {
          id
          name
        }
      }
      users {
        id
        email
        status
        profile {
          id
          name
        }
      }
      bank_data {
        id
        agency
        account
        pix
        bank {
          id
          code
          name
        }
      }
    }
  }
`;
