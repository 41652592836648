import {
  DetailProposalCoResponsibleResponse,
  DetailProposalImmobileResponse,
  DetailProposalResponse,
  DetailProposalTenantResponse,
} from "graphql/gql/graphql";
import {
  maskCNPJ,
  maskCPF,
  maskDate,
  maskMoney,
  maskPhone,
} from "utils/maskOutputs";

interface Subtopic {
  title: string;
  subtopics?: Subtopic[];
}

interface Item {
  type: "table" | "subtopics";
  title: string;
  subtopics?: Subtopic[];
  data?: (string | { col1: string; col2?: string })[];
}

export function getItems(
  proposal: DetailProposalResponse,
  tenant: DetailProposalTenantResponse,
  immobile: DetailProposalImmobileResponse,
  co_responsibles: DetailProposalCoResponsibleResponse[]
): Item[] {
  const total =
    proposal.iptu! +
    proposal.rent_amount! +
    proposal.other_taxes! +
    proposal.condominium_amount!

  const totalContracted = proposal.chosen_installments! * total;
  const refundCost = total * 3!;

  const co_responsiblesData = co_responsibles.map((co_responsible) => [
    {
      col1: `Nome completo: ${co_responsible.person ? co_responsible.person.name : "Não disponível"}`,
      col2: `CPF: ${co_responsible.person ? maskCPF(co_responsible.person.document || "") : "Não disponível"}`,
    },
    {
      col1: `Data de nascimento: ${co_responsible.person ? maskDate(co_responsible.person.birth_date || "") : "Não disponível"}`,
      col2: `Telefone: ${co_responsible.person ? maskPhone(co_responsible.person.phone || "") : "Não disponível"}`,
    },
    {
      col1: `Email: ${co_responsible.person ? co_responsible.person.email : "Não disponível"}`,
      col2: `RG e órgão expedidor: Não disponível`,
    }
  ]).flat();

  return [
    {
      type: "table",
      title: "INTRODUÇÃO",
      subtopics: [
        {
          title: `O presente instrumento de TERMOS E CONDIÇÕES DE USO DOS SERVIÇOS RENTI ("TERMOS") tem o objetivo de regular
             o acesso, uso, produtos, conteúdos e serviços em geral ("SERVIÇOS"), prestados por RENTI GARANTIA LOCATÍCIA
             S.A., pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 51.560.656/0001-08, com sede na Rua
             Piauí, nº 1.627, loja 01, Funcionários, na cidade de Belo Horizonte/MG, CEP 30.150-323 (doravante "RENTI")`,
        },
        {
          title: `Portanto, ao utilizar os SERVIÇOS da RENTI, Você declara sua ciência sobre o conteúdo dos TERMOS e
             concordância com as condições contratuais aqui estabelecidas, sujeitando-se a essas durante toda
             a vigência dos SERVIÇOS.`,
        },
      ],
      data: [
        "Quadro resumo – Locação e fiança locatícia",
        "Locatário (se houver mais de um, incluir os dados de todos)",
        {
          col1: `Nome Completo: ${tenant.name}`,
          col2: `CPF: ${maskCPF(tenant.document || "")}`,
        },
        {
          col1: `Data de nascimento: ${maskDate(tenant.birth_date || "")}`,
          col2: `Telefone: ${maskPhone(tenant.phone || "")}`,
        },
        {
          col1: `Email: ${tenant.email}`,
          col2: `RG e órgão expedidor: Não disponível`,
        },
        "Coobrigado (se houver mais de um, incluir os dados de todos)",
        ...co_responsiblesData,
        "Administrador",
        {
          col1: `Nome completo ou razão social: ${proposal.real_estate?.name}`,
          col2: `CPF ou CNPJ: ${proposal.real_estate?.document
            ? proposal.real_estate?.document.length === 11
              ? maskCPF(proposal.real_estate?.document)
              : maskCNPJ(proposal.real_estate?.document)
            : "Não disponível"
            }`,
        },
        {
          col1: `Endereço ou sede: ${proposal.real_estate?.street || "Não disponível"
            }`,
          col2: `Complemento: ${proposal.real_estate?.complement || "Não disponível"
            }`,
        },
        {
          col1: `CEP: ${proposal.real_estate?.zipcode || "Não disponível"}`,
          col2: `UF: ${proposal.real_estate?.city?.state || "Não disponível"}`,
        },
        {
          col1: `Cidade: ${proposal.real_estate?.city?.name || "Não disponível"
            }`,
        },
        "Imóvel",
        {
          col1: `Endereço: ${immobile.street || "Não disponível"}`,
          col2: `Complemento: ${immobile.complement || "Não disponível"}`,
        },
        {
          col1: `Cidade, UF e CEP: ${immobile.city?.name} - ${immobile.city?.state} - ${immobile.zipcode}`,
          col2: `Tipo de locação: RESIDENCIAL`,
        },
        `Fiança locatícia contratada`,
        {
          col1: `Multiplicador: ${proposal.chosen_installments}`,
          col2: `Total contratado: ${maskMoney(totalContracted)}`,
        },
        {
          col1: `Obrigações locatícias: ${maskMoney(total)}`,
          col2: `Tarifa de contratação: ${maskMoney(
            proposal.chosen_setup_fee
          )}`,
        },
        {
          col1: `Custo de restituição (saída): ${maskMoney(refundCost)}`,
          col2: `Modo de pagamento: Cartão de Crédito`,
        },
      ],
    },
    {
      type: "subtopics",
      title: "DEFINIÇÕES",
      subtopics: [
        {
          title: `Comunicação de atraso: comunicação enviada pela imobiliária, administradora ou locador à RENTI, posicionando
          sobre a situação de inadimplência, pelo locatário, de qualquer das obrigações locatícias. Os comunicados serão 
          individualizados para cada uma das verbas contempladas nas obrigações locatícias.`,
        },
        {
          title: `Coobrigado: garantidor que se responsabilizará conjuntamente com o locatário, obrigando-se solidariamente pelo
          pagamento das obrigações locatícias.`,
        },
        {
          title: `Corretor autônomo: pessoa física legalmente habilitada, responsável pela administração de imóveis de terceiros
          que poderá intermediar a locação.`,
        },
        {
          title: `Despesas de devolução: valores devidos, pelo locatário, para a restituição do imóvel ao locador em razão do fim
  ou rescisão da locação. São exemplos: multa rescisória (quando aplicável), custos para retorno do imóvel ao
  estado anterior, taxas de desligamento de água, luz e gás.`,
        },
        {
          title: `Diligência: avaliação da capacidade financeira do locatário frente às obrigações locatícias, ou seja, análise 
  de crédito em nome do locatário.`,
        },
        {
          title: `Fiança locatícia: fiança prestada pela RENTI na locação firmada pelo locatário e locador, obrigando-se ao
          pagamento das obrigações locatícias até o total contratado, desde que não haja êxito nas tratativas
          de inadimplência`,
        },
        {
          title: `Imobiliária: pessoa jurídica legalmente habilitada, responsável pela administração de imóveis de terceiros 
          que poderá intermediar a locação.`,
        },
        {
          title: `Locação: contrato de locação entre locador e locatário, com a garantia locatícia RENTI.`,
        },
        {
          title: `Locador: proprietário ou titular de direitos sobre o imóvel objeto da locação.`,
        },
        {
          title: `Locatário: pessoa física ou jurídica contratante da locação, responsável pelas obrigações locatícias e
          tomadora dos SERVIÇOS da RENTI de garantia locatícia.`,
        },
        {
          title: `Modalidade: diferentes categorias de SERVIÇOS passíveis de contratação pelo locatário, conforme sua avaliação
          de crédito e valor das obrigações locatícias.`,
        },
        {
          title: `Obrigações locatícias: valor obtido da soma do aluguel, taxa de condomínio e demais encargos da locação,
          indicados na locação, além das despesas com a devolução do imóvel.`,
        },
        {
          title: `Plataforma: sistema web ou app disponibilizado pela RENTI para acesso do locador, locatário, imobiliária,
          corretor de imóveis, coobrigado, ou qualquer outro usuário, sempre após a realização do respectivo cadastro.`,
        },
        {
          title: `Preço: valor a ser pago à RENTI pelos SERVIÇOS contratados, a ser apurado a partir da modalidade de garantia
          RENTI sobre as obrigações locatícias. Uma vez contrato por período plurianual, o preço será reajustado a cada
           12 meses de contrato, pela variação positiva do IGPM (Índice Geral de Preços – Mercado) ou
            do IPCA - Índice Nacional de Preços ao Consumidor Amplo, o que for maior.`,
        },
        {
          title: `Seguros da locação: seguros exigidos, pela locação, para o imóvel locado, como contra incêndio ou
          desastres naturais.`,
        },
        {
          title: `Tarifa de contratação: valor devido pela contratação de garantia locatícia RENTI, para ativação dos SERVIÇOS.`,
        },
        {
          title: `Tratativas de inadimplência: negociação realizada pela RENTI junto ao locatário e coobrigado (quando houver),
          com o intuito de sanear valores em atraso.`,
        },
        {
          title: `Usuário: significa todo e qualquer usuário da plataforma ou dos SERVIÇOS RENTI, como locador, imobiliária,
          locatário ou coobrigado.`,
        },
      ],
    },
    {
      type: "subtopics",
      title: "OBJETO",
      subtopics: [
        {
          title: `É objeto dos presentes TERMOS definir as condições de prestação de SERVIÇOS pela RENTI,
             compreendendo (i) tratativas de inadimplência
             (para cobrança de valores em atraso pelo LOCATÁRIO), (ii) FIANÇA LOCATÍCIA e (iii) restituição dos valores
             custeados pela FIANÇA LOCATÍCIA, a título de regresso.`,
          subtopics: [
            {
              title: `Para que uma LOCAÇÃO possa se beneficiar dos SERVIÇOS, é obrigatório que seja realizada por intermédio
              de uma IMOBILIÁRIA ou CORRETOR AUTÔNOMO parceiro, que possuam relacionamento com a RENTI.
              Havendo interesse do respectivo LOCATÁRIO ou propenso LOCATÁRIO, este deverá manifestar sua intenção
              na contratação dos SERVIÇOS RENTI e ser aprovado na DILIGÊNCIA.`,
            },
          ],
        },
        {
          title: `Poderão ser objeto de contratação dos SERVIÇOS apenas as locações de imóveis urbanos, residenciais ou não
          residenciais, regidas pela Lei nº 8.245/1991 (Lei de Locações), excluindo-se as demais e as
          locações de terreno sem edificação.`,
        },
      ],
    },
    {
      type: "subtopics",
      title: "UTILIZAÇÃO DOS SERVIÇOS",
      subtopics: [
        {
          title:
            "A utilização dos SERVIÇOS será feita exclusivamente por meio de cadastro perante a RENTI:",
        },
        {
          title: "Para tanto, é obrigatório ao LOCATÁRIO:",
          subtopics: [
            {
              title: `Estar vinculado à IMOBILIÁRIA ou CORRETOR AUTÔNOMO cadastrado perante a RENTI.`,
            },
            {
              title: `Fornecer todos os dados necessários, como razão social ou nome completo, CNPJ ou CPF,
              contrato social (ou última alteração consolidada) ou carteira de identidade, telefone, e-mail
              e endereço (para locações não residenciais).`,
            },
            {
              title: `Acaso escolhido pagamento via cartão de crédito, apresentar cartão de crédito de
              titularidade do locatário com validade superior a 12 (doze) meses, contada a partir da data de apresentação,
              e limite disponível, no mínimo, superior a 04 (quatro) vezes as obrigações locatícias.`,
            },
          ],
        },
        {
          title: `Não serão aceitos cartões com código de verificação dinâmico e que tenham validade inferior a 12 (doze) meses
          contados da data da primeira cobrança.`,
        },
        {
          title: `É de responsabilidade exclusiva da IMOBILIÁRIA ou do CORRETOR AUTÔNOMO validar a integridade e autenticidade
          dos dados e documentos fornecidos na forma desta Cláusula Quarta. Qualquer falha na coleta de dados idôneos,
          inclusive quanto aos critérios para aceite de cartão de crédito estabelecidos acima, será unicamente imputável
          à IMOBILIÁRIA ou ao CORRETOR AUTÔNOMO, os quais se obrigarão a ressarcir à RENTI por qualquer dano, direto ou
          indireto, derivado de informações incorretas ou inconformes aos requisitos previstos nestes TERMOS.`,
        },

        {
          title: `A RENTI poderá, a seu exclusivo critério, requisitar a apresentação de COOBRIGADO ao LOCATÁRIO,
             que responderá solidariamente com o LOCATÁRIO pelas obrigações assumidas nos presentes TERMOS,
             notadamente o dever de restituir e/ou indenizar RENTI pelos valores pagos a título da
             FIANÇA LOCATÍCIA prestada na LOCAÇÃO.`,
          subtopics: [
            {
              title: `Sendo solicitado COOBRIGADO, deverão ser apresentados os mesmos dados e documentos previstos na Cláusula
              4.2. Não havendo aprovação da DILIGÊNCIA do COOBRIGADO em caso de substituição, os SERVIÇOS poderão
              ser imediatamente suspensos pela RENTI.`,
            },
          ],
        },
        {
          title: `A prestação de quaisquer SERVIÇOS pela RENTI está condicionada: a) Ao aceite dos presentes TERMOS,
          em sua totalidade, pelo usuário, seja LOCATÁRIO ou COOBRIGADO. b) À aprovação de análise cadastral, pela IMOBILIÁRIA e pela RENTI, após a apresentação dos
          documentos e dados exigidos nesta Cláusula.`,
        },
      ],
    },
    {
      type: "subtopics",
      title: "APROVAÇÃO DO CADASTRO",
      subtopics: [
        {
          title: `LOCATÁRIO: o cadastro do LOCATÁRIO interessado será realizado pela própria
          IMOBILIÁRIA ou CORRETOR AUTÔNOMO, de acordo com o procedimento da Cláusula 4ª.`,
          subtopics: [
            {
              title: `Além do cadastro do LOCATÁRIO, a IMOBILIÁRIA ou CORRETOR AUTÔNOMO deverá,
              obrigatoriamente, apresentar as informações comerciais relativas à LOCAÇÃO pretendida pelo
              LOCATÁRIO, sobretudo que componham as obrigações locatícias.`,
            },
          ],
        },
        {
          title: `DILIGÊNCIA: após o cadastro do LOCATÁRIO, a RENTI procederá à avaliação da capacidade
          financeira desse quanto às OBRIGAÇÕES LOCATÍCIAS, podendo solicitar a apresentação de
          COOBRIGADO. Neste último caso, a IMOBILIÁRIA ou CORRETOR AUTÔNOMO deverá realizar o
          cadastro na forma da Cláusula 5.1, para DILIGÊNCIA específica quanto ao COOBRIGADO.`,
        },
        {
          title: `O resultado da DILIGÊNCIA (aprovação ou não aprovação) será sempre pontual e
          casuístico, não assegurando aprovação ou não aprovação em futuras operações, ainda que as
          condições sejam semelhantes.`,
          subtopics: [
            {
              title: `A partir de critérios econômico-financeiros para proteção do crédito e redução de
              exposição ao risco, a RENTI se resguarda o direito de não aprovar DILIGÊNCIA.`,
            },
            {
              title: `Em qualquer caso, o resultado da DILIGÊNCIA será comunicado à IMOBILIÁRIA ou
              CORRETOR AUTÔNOMO indicado no momento do cadastro do LOCATÁRIO. Em razão das normas
              de privacidade e proteção de dados pessoais do LOCATÁRIO ou COOBRIGADO, a RENTI apenas
              informará, de forma resumida, o resultado da DILIGÊNCIA, ficando vedada de compartilhar
              qualquer detalhamento senão a pedido direto, pessoal e específico do titular dos dados pessoais
              nesse sentido.`,
            },
          ],
        },
        {
          title: `Em caso de aprovação, será fornecido acesso ao LOCATÁRIO ou COOBRIGADO, para ciência
          e concordância com os presentes TERMOS.`,
          subtopics: [
            {
              title: `Alerta legal: o login eventualmente disponibilizado será individual e
              intransferível, devendo ser utilizado apenas por representante legal ou outra pessoa
              com poderes de representação do LOCATÁRIO ou COOBRIGADO, a fim de assegurar a
              legitimidade do aceite aos TERMOS. O LOCATÁRIO e o COOBRIGADO serão os únicos
              responsáveis pelo login, devendo cadastrar apenas senhas fortes e renová-las
              periodicamente.`,
            },
          ],
        },
        {
          title: `Vigência dos SERVIÇOS: com o cumprimento dos requisitos e condicionantes para os
          SERVIÇOS previstos nos TERMOS, deverá a IMOBILIÁRIA ou CORRETOR AUTÔNOMO
          providenciar a elaboração e assinatura de contrato de locação (ou de termo aditivo, para as
          locações em curso), a ser assinado por todos os signatários originais (sejam locador, locatário,
          intervenientes anuentes, e outros de qualquer natureza). O contrato ou termo aditivo conterá,
          obrigatória e cumulativamente, a cláusula abaixo e cópia destes TERMOS como anexo: “Da garantia locatícia. 
          Nos termos e condições de uso da RENTI GARANTIA LOCATÍCIA S.A. (em anexo e que fazem parte integrante do presente
          instrumento), pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 51.560.656/0001-08,
          é prestada garantia locatícia pelo Locatário em favor do Locador, quanto ao aluguel e demais encargos,
          respeitadas as limitações expressamente mencionadas nos referidos termos de uso.
          Nessa oportunidade, portanto, ambos Locador e Locatário se declaram cientes e de acordo com
          o regulamento da garantia aqui prestada, especialmente quanto a:
          a) Valor máximo garantido.
          b) Exclusões e limitações de responsabilidade.
          c) Prazo de duração e possibilidades de prorrogação.
          d) Possibilidade da RENTI substituir a garantia por outra legalmente admitida, como seguro.
          e) Hipóteses de exoneração da garantia pela RENTI.
          Em caso de exoneração da garantia pela RENTI em qualquer uma das situações admitidas,
          obriga-se o Locatário a apresentar, em até 30 (trinta) dias contados da comunicação de
          exoneração, nova garantia locatícia, sob pena de inadimplemento a autorizar a rescisão
          antecipada e o ajuizamento de ação de despejo.
          Por sua vez, a Imobiliária, enquanto representante do Locador, outorga à RENTI poderes
          especiais para notificação do Locatário quanto ao inadimplemento e eventual desocupação do
          imóvel.”`,
          subtopics: [
            {
              title: `Para a contratação por meio de termo aditivo (locações em curso), o respectivo
              instrumento aditivo deverá prever, ainda, a plena, geral, rasa, irretratável e irrevogável quitação
              de quaisquer valores, sejam alugueis ou encargos da locação, pelo LOCADOR ao LOCATÁRIO,
              relativamente ao período anterior do contrato.`,
            },
            {
              title: `Após a assinatura do contrato de LOCAÇÃO ou termo aditivo, cópia assinada desse deverá
              ser disponibilizada à RENTI, juntamente do relatório de vistoria de entrega do imóvel, com fotos
              do seu estado atual, para novas locações, ou seu estado inicial, para locações em curso.`,
            },
          ],
        },
        {
          title: `Extensão dos SERVIÇOS e cobrança de valores do LOCATÁRIO: pelo aceite das condições
          comerciais da locação e pela contratação dos SERVIÇOS RENTI, o LOCATÁRIO expressamente
          autoriza: a) A cobrança da tarifa de contratação, com emissão de boleto, código pix ou lançamento no
          cartão de crédito, conforme a forma de pagamento definida.
          b) A cobrança quaisquer valores inadimplidos durante a locação e que sejam quitados pela
          RENTI, a título de ressarcimento, com emissão de boleto, código pix ou lançamento no cartão de
          crédito, conforme a forma de pagamento definida.
          c) A cobrança extrajudicial ou judicial dos valores quitados pela RENTI em razão da
          inadimplência do LOCATÁRIO na locação, bem como o protesto de eventual título daí derivado e
          a inscrição ou apontamento perante órgãos de proteção ao crédito (como SPC ou Serasa), acaso
          não haja o pagamento voluntário previsto no item “b” desta Cláusula 5.6`,
          subtopics: [
            {
              title: `Pagamento por meio de cartão de crédito: quando o LOCATÁRIO ou eventual
              COOBRIGADO optar pelo pagamento dos SERVIÇOS por meio de cartão de crédito, declaram-se
              cientes de que o cadastro do cartão de crédito somente ocorrerá após a concordância com as
              condições comerciais da fiança RENTI e a adesão aos presentes TERMOS.`,
            },
            {
              title: `Autorização para lançamentos: ao autorizarem o cadastro do cartão de crédito, o
              LOCATÁRIO e eventual COOBRIGADO outorgam expressamente à RENTI o direito de realizar os
              competentes lançamentos para cobrança de valores pelos SERVIÇOS (tarifa de contratação,
              preço, obrigações locatícias reembolsáveis e outros valores quitados pela RENTI). A presente
              autorização é concedida em caráter irrevogável e irretratável, independentemente de qualquer
              outra notificação ou comunicação.`,
            },
            {
              title: `A TARIFA DE CONTRATAÇÃO será paga em uma única parcela, à vista, enquanto o PREÇO
              poderá ser parcelado em até 12 (doze) prestações mensais, iguais e consecutivas do mesmo
              valor.`,
            },
            {
              title: `Qualquer falha no pagamento de qualquer valor relativo aos SERVIÇOS RENTI, seja da
              TARIFA DE CONTRATAÇÃO, do PREÇO (integral ou de qualquer uma das parcelas) prejudicará a
              consumação da contratação dos SERVIÇOS, podendo implicar, a critério da RENTI, na rescisão
              da contratação e consequente exoneração da FIANÇA LOCATÍCIA prestada no âmbito da
              LOCAÇÃO.`,
            },
          ],
        },
        {
          title: `Limitação de responsabilidade perante a IMOBILIÁRIA, LOCADOR e terceiros: a associação
          de locações, novas ou em curso, aos SERVIÇOS RENTI em descumprimento aos requisitos
          previstos nestes TERMOS, isentará a RENTI de qualquer responsabilidade, assumindo a
          IMOBILIÁRIA, expressa e isoladamente, responsabilidade pelos danos diretos e indiretos
          incorridos pela RENTI pela inobservância dos presentes TERMOS.`,
        },
        {
          title: `Contratação do Seguro Obrigatório: sendo exigida, na LOCAÇÃO, a contratação de seguro
          obrigatório, cópia da respectiva apólice deverá ser compartilhada com a RENTI em até 10 dias
          contados do aceite destes TERMOS.`,
        },
        {
          title: `Condição suspensiva da contratação dos SERVIÇOS: os SERVIÇOS não estarão em vigor e
          não serão exigíveis da RENTI, até que seja aperfeiçoado o lançamento das cobranças no cartão
          de crédito do LOCATÁRIO (quando esta for a forma de pagamento escolhida), a compensação de
          valores (quando outra forma de pagamento for eleita), e que haja disponibilização dos
          documentos mencionados acima.`,
        },
        {
          title: `Após a contratação dos SERVIÇOS nenhuma alteração substancial poderá ser realizada na
          LOCAÇÃO sem a prévia e expressa anuência da RENTI.`,
        },
      ],
    },
    {
      title: "LIMITES DOS SERVIÇOS E VALORES",
      type: "subtopics",
      subtopics: [
        {
          title: `OBRIGAÇÕES LOCATÍCIAS: estão compreendidos na fiança prestada pela RENTI, com o que
          desde já se declara ciente e de acordo o LOCATÁRIO: a) O valor do aluguel.
          b) As taxas e despesas com água e luz durante a LOCAÇÃO.
          c) Despesas relativas ao estado do imóvel, para fins de viabilizar a restituição ao LOCADOR.
          d) Multas contratuais até o teto de 05% (cinco porcento) do aluguel mensal.
          e) Encargos legais derivados da mora.
          f) Despesas para promover o despejo judicial do LOCATÁRIO, em razão da exoneração do fiador
          e não apresentação de nova garantia no prazo legal.`,
        },
        {
          title: `Tais pagamentos ocorrerão conforme a indicação pela IMOBILIÁRIA quanto aos valores das
          OBRIGAÇÕES LOCATÍCIAS, limitados ao TOTAL CONTRATADO. A RENTI expressamente se
          exonera de qualquer responsabilidade por valores acima de tal limite.`,
        },
        {
          title: `Qualquer outro valor que porventura seja custeado pela RENTI, seja por determinação
          judicial para despejo do LOCATÁRIO ou a qualquer outro título que venha a ser exigido pelo
          LOCADOR ou IMOBILIÁRIA, deverão ser igualmente reembolsados à RENTI pelo LOCATÁRIO.`,
        },
        {
          title: `Valores não compreendidos pela fiança RENTI: estão expressamente excluídos da FIANÇA
          LOCATÍCIA prestada pela RENTI: a) Atos públicos, de qualquer natureza, sejam do Poder Público ou que repercutam sobre a
          LOCAÇÃO ou a capacidade financeira das PARTES, como operações militares, bélicas ou guerra,
          revoluções, rebeliões ou intervenções, confiscos, tumultos, motins, greves e outros atos
          similares.
          b) Danos a qualquer trabalho de natureza artística, como obras de arte, decoração, painéis,
          pinturas ou gravações, independentemente do local ou forma em que estejam instalados
          (paredes, portas, vidros, internos ou externos) já existentes no imóvel.
          c) Danos de qualquer natureza causados por terceiros.
          d) Danos estruturais sobre o imóvel decorrentes de caso fortuito e/ou força maior, ou derivados
          de dolo do LOCATÁRIO e outros conviventes ou prestadores de serviços a seu mando.
          e) Danos ou vícios construtivos, principalmente mas não limitado a redes elétricas ou
          hidráulicas, que sejam de responsabilidade do condomínio, da construtora ou incorporadora, ou
          das concessionárias de serviços públicos, ou que, de qualquer forma, não sejam de
          responsabilidade do LOCATÁRIO, como vícios ocultos.
          f) Danos por atos ilícitos, dolosos ou culposos do LOCATÁRIO ou por pessoa a ele vinculada,
          sejam conviventes ou prestadores de serviços a seu mando.
          g) Desgastes inerentes ao tempo de uso do imóvel, como desgastes ocasionados por poluição,
          temperatura, umidade ou infiltração e vibração, mas sem se limitar a essas.
          h) Desvalorização ou qualquer tipo de depreciação sobre o imóvel.
          i) Incapacidade de pagamento, pelo LOCATÁRIO, derivada de fatos novos ou de conhecimento
          da RENTI posterior à prestação da FIANÇA LOCATÍCIA.
          j) Não aprovação da DILIGÊNCIA do cônjuge sobrevivente, companheiro, herdeiro ou sucessor,
          no caso de falecimento do LOCATÁRIO com a intenção de continuidade da LOCAÇÃO pelos aqui
          mencionados, em até 30 (trinta) dias contados da comunicação expedida para esse fim.
          k) Não apresentação de novo COOBRIGADO nos termos solicitados, ou não aprovação da
          DILIGÊNCIA do novo COOBRIGADO, em até 30 (trinta) dias contados da comunicação expedida
          para esse fim.
          l) OBRIGAÇÕES LOCATÍCIAS em aberto pelo LOCATÁRIO e não comunicadas à RENTI em até 60
          (sessenta) dias após o respectivo vencimento, de acordo com o contrato de LOCAÇÃO. Quando
          se tratar de custos de restituição, o será contado da data de entrega das chaves.
          m) Quaisquer despesas, taxas e obrigações do imóvel não mencionadas na LOCAÇÃO.
          n) Quaisquer valores a título de dano indireto, lucro cessante ou dano moral,
          independentemente do ato ou fundamento alegado.
          o) Quaisquer valores derivados de acordos ou negociações feitas entre LOCATÁRIO e LOCADOR,
          com ou sem a participação da IMOBILIÁRIA, principalmente, mas não limitado a, reforma ou
          benfeitoria de qualquer natureza sobre o IMÓVEL.
          p) Questões causadas por material nuclear, desmoronamento, chuvas, enchentes, inundação,
          abalos sísmicos, tremor de terra e erupção vulcânica
          q) Valores de qualquer natureza cobrados pela IMOBILIÁRIA.
          r) Valores de qualquer natureza em aberto vinculados ao IMÓVEL antes da LOCAÇÃO, ou valores
          de qualquer natureza devidos pelo LOCATÁRIO antes da FIANÇA LOCATÍCIA (quando a
          LOCAÇÃO já preexistir aos SERVIÇOS RENTI).
          s) Valores de qualquer natureza relacionados à sublocação, cessão ou empréstimo do imóvel
          locado, total ou parcialmente, bem como valores de qualquer natureza devidos pelo uso do
          imóvel em desacordo com a LOCAÇÃO, principalmente, mas não limitado a, utilização para
          natureza diversa.`,
        },
        {
          title: `Responsabilidades do LOCATÁRIO: a qualquer momento durante os SERVIÇOS RENTI,
          ocorrendo qualquer dificuldade quanto ao pagamento independentemente da forma escolhida
          (por exemplo, recusa da operadora, bandeira ou instituição financeira em aprovar o
          lançamento), caberá ao LOCATÁRIO (e seu eventual COOBRIGADO) apresentar outra forma de
          pagamento no prazo máximo de 05 (cinco) dias contados da comunicação de falha no
          pagamento.`,
          subtopics: [
            {
              title: `A ausência de saneamento da pendência de pagamento mencionada no item 6.5 acima
              implicará na rescisão dos SERVIÇOS de FIANÇA LOCATÍCIA RENTI, unilateralmente, bem como a
              adoção de todas as providências cabíveis para exoneração da FIANÇA LOCATÍCIA e cobrança de
              quaisquer valores porventura pagos pela RENTI no âmbito dos SERVIÇOS. Manifesta-se o
              LOCATÁRIO desde já ciente e de acordo.`,
            },
          ],
        },
      ],
    },
    {
      title: "INADIMPLEMENTO DE VALORES CONTRATADOS",
      type: "subtopics",
      subtopics: [
        {
          title: `Inadimplemento de valores pelo LOCATÁRIO: caberá à IMOBILIÁRIA realizar a cobrança das
          OBRIGAÇÕES LOCATÍCIAS mensalmente ou de acordo com outra periodicidade prevista na
          LOCAÇÃO. A partir do 20º (vigésimo) dia corrido contado do vencimento e não pagamento,
          caberá à IMOBILIÁRIA persistir nas tentativas de cobrança perante o LOCATÁRIO ou
          COOBRIGADO ou encaminhar à RENTI a COMUNICAÇÃO DE ATRASO, fornecendo todos os
          detalhes relacionados aos valores em aberto, atualizações financeiras e dados para quitação,
          oportunidade em que a RENTI iniciará, por conta própria, a cobrança dos referidos valores.`,
          subtopics: [
            {
              title: `A COMUNICAÇÃO DE ATRASO poderá ser encaminhada pela IMOBILIÁRIA a partir do 20º
              dia mas até o 60º (sexagésimo) dia de atraso, sob pena de exclusão da responsabilidade da
              RENTI com relação aos valores em aberto após 60 (sessenta) dias contados do seu vencimento.`,
            },
            {
              title: `Iniciados os trabalhos de cobrança pela RENTI, esta terá obrigação de arcar com os
              valores em aberto (nas condições e limites garantidos pela FIANÇA LOCATÍCIA previstos nestes
              TERMOS) acaso não tenha obtido o pagamento pelo LOCATÁRIO ou COOBRIGADO em até 30
              (trinta) dias corridos contados do recebimento da COMUNICAÇÃO DE ATRASO.`,
            },
            {
              title: `A RENTI poderá solicitar informações ou documentos complementares a fim de constatar
              o inadimplemento pelo LOCATÁRIO, oportunidade em que não se iniciarão os prazos de
              cobrança ou pagamento pela RENTI previstos nesta Cláusula 7ª até a respectiva apresentação
              de esclarecimentos pela IMOBILIÁRIA ou LOCADOR.`,
              subtopics: [
                {
                  title: `Não sendo apresentadas informações ou documentos complementares solicitados na
                  forma do item 7.1.3 em até 30 (trinta) dias contados da solicitada da RENTI, ficará
                  expressamente cancelada a inadimplência comunicada, isentando a RENTI de qualquer
                  pagamento previsto na respectiva COMUNICAÇÃO DE ATRASO. Tal sanção se estende à hipótese
                  em que a IMOBILIÁRIA não informar os respectivos dados bancários para quitação dos valores
                  em atraso, como a ausência de envio de boleto ou sinalização de outra forma de pagamento.`,
                },
              ],
            },
            {
              title: `Com a quitação dos valores em atraso, seja pela RENTI, pelo LOCATÁRIO ou
              COOBRIGADO, obriga-se a IMOBILIÁRIA a realizar o respectivo repasse ao LOCADOR, nos
              termos do contrato de administração por esses firmado. Independentemente da forma de
              repasse, fica a RENTI expressamente isenta de qualquer responsabilidade perante o LOCADOR,
              notadamente por qualquer falha da IMOBILIÁRIA, que neste ato de obriga a indenizá-la em caso
              de qualquer valor porventura custeado pela RENTI por falhas da IMOBILIÁRIA.`,
            },
          ],
        },
        {
          title: `A COMUNICAÇÃO DE ATRASO encaminhada pela RENTI não prejudica o recebimento de
          valores diretamente pela IMOBILIÁRIA, devendo manter a RENTI atualizada em caráter
          imediato, a fim de serem suspensas as cobranças por essa. Qualquer falha de comunicação pela
          IMOBILIÁRIA nesse sentido será de sua exclusiva responsabilidade, devendo indenizar a RENTI
          por qualquer responsabilização em razão de cobranças em duplicidade.`,
        },
        {
          title: `Acaso a RENTI venha a pagar os valores em atraso, estará sub-rogada, na forma destes
          TERMOS e da legislação aplicável, nos direitos de credor, podendo cobrar os referidos valores,
          acrescidos das atualizações financeiras, por qualquer das formas de pagamento escolhidas pelo
          LOCATÁRIO e COOBRIGADOS, como cartão de crédito.`,
          subtopics: [
            {
              title: `A RENTI poderá, a seu livre critério, sem qualquer renúncia às prerrogativas previstas
              nestes TERMOS e na legislação, negociar um acordo com o LOCATÁRIO ou COOBRIGADO para
              pagamento dos valores sub-rogados pela RENTI`,
            },
          ],
        },
        {
          title: `Limite da FIANÇA LOCATÍCIA: acaso a cobrança pela RENTI não obtenha êxito e a RENTI
          tenha que arcar com o pagamento de OBRIGAÇÕES LOCATÍCIAS, de forma consecutiva ou
          não, até 03 (três) vezes o valor de uma das verbas contempladas nas OBRIGAÇÕES
          LOCATÍCIAS, poderá rescindir a prestação de serviços de FIANÇA LOCATÍCIA, conforme
          procedimento abaixo.`,
        },
      ],
    },
    {
      title: "VIGÊNCIA DOS SERVIÇOS RENTI E RESCISÃO ANTECIPADA",
      type: "subtopics",
      subtopics: [
        {
          title: `Vigência: os SERVIÇOS serão prestados por prazo determinado relativo aos meses
          remanescentes da LOCAÇÃO, salvo se prazo distinto for especificamente pactuado entre as
          PARTES.`,
          subtopics: [
            {
              title: `Em caso de prorrogação da LOCAÇÃO com continuidade dos SERVIÇOS RENTI, o prazo
              acima previsto será igualmente prorrogado. Qualquer desinteresse na prorrogação da FIANÇA
              LOCATÍCIA deverá ser expressamente comunicado pela IMOBILIÁRIA, que arcará com quaisquer
              valores, encargos ou sanções derivados de atraso na comunicação sobre o desinteresse na
              prorrogação. Os SERVIÇOS vigerão até a efetiva restituição do IMÓVEL, observadas as hipóteses
              de rescisão previstas nesta Cláusula 8ª.`,
            },
            {
              title: `A vigência por mais de 12 (doze) meses, se for o caso, poderá ser parcelada anualmente,
              sem que o número de parcelas reflita o prazo de duração. Portanto, o PREÇO poderá ser pago a
              cada 12 (doze) meses, momento em que incidirá correção monetária pela variação positiva do
              IGP-M ou IPCA-IBGE acumulado no período, prevalecendo o que for maior.`,
            },
          ],
        },
        {
          title: `Qualquer falha no pagamento de valores, sobretudo na renovação anual acima mencionada,
          sem o devido saneamento pelo LOCATÁRIO ou COOBRIGADO em até 05 (cinco) dias contados
          da comunicação de falha de pagamento pela RENTI, poderá ocasionar a rescisão dos SERVIÇOS,
          a livre critério da RENTI, e consequente exoneração da FIANÇA LOCATÍCIA prestada na
          LOCAÇÃO.`,
        },
        {
          title: `Rescisão antecipada pelo LOCATÁRIO: a) Em caso de rescisão da LOCAÇÃO e restituição 
          do IMÓVEL, deverá a IMOBILIÁRIA expedir a
          respectiva comunicação à RENTI, apresentando o termo de quitação das obrigações relativas à
          LOCAÇÃO. Somente após tal procedimento e validação do conteúdo dos documentos é que a
          RENTI poderá rescindir a FIANÇA LOCATÍCIA, e, a seu critério, cancelar a cobrança de valores
          pendentes pelo LOCATÁRIO ou restituir proporcionalmente os valores pagos pelo LOCATÁRIO,
          pelo prazo remanescente da LOCAÇÃO antecipadamente rescindida. A RENTI não se
          responsabilizará por qualquer atraso ou demora na comunicação pela IMOBILIÁRIA.
          b) Em caso de substituição da garantia e desinteresse da FIANÇA LOCATÍCIA RENTI, caberá à
          IMOBILIÁRIA encaminhar a respectiva comunicação e aceite do LOCADOR, bem como
          instrumento aditivo à LOCAÇÃO, com cláusula de quitação das obrigações à RENTI. Somente
          após tal procedimento e assinatura do termo aditivo por todas as PARTES é que a RENTI poderá
          rescindir a FIANÇA LOCATÍCIA, e, a seu critério, cancelar a cobrança de valores pendentes pelo
          LOCATÁRIO ou restituir proporcionalmente os valores pagos pelo LOCATÁRIO, pelo prazo
          remanescente da LOCAÇÃO antecipadamente rescindida. A RENTI não se responsabilizará por
          qualquer atraso ou demora na comunicação pela IMOBILIÁRIA.`,
          subtopics: [
            {
              title: `A restituição de valores mencionados no item 8.3 será realizada em até 60 (sessenta)
              dias corridos, contados da conclusão dos procedimentos previstos nas alíneas “a” ou “b” da
              referida Cláusula. A devolução ocorrerá por meio de depósito, transferência ou pix em conta
              bancária exclusivamente de titularidade do LOCATÁRIO, a ser por esse informada, podendo ser
              mantido o débito mensal, quando pago por cartão, acaso não seja viável realizar o
              cancelamento das cobranças futuras.`,
            },
          ],
        },
        {
          title: `Rescisão antecipada pela RENTI:
          a) Não saneamento de falha de pagamento, pelo LOCATÁRIO ou COOBRIGADO, no prazo de 05
          (cinco) dias contados da comunicação expedida pela RENTI.
          b) Não apresentação de COOBRIGADO com condições de aprovação na DILIGÊNCIA, no prazo
          de 05 (cinco) dias contados da comunicação expedida pela RENTI.
          c) Pagamento, pela RENTI, de OBRIGAÇÕES LOCATÍCIAS, de forma consecutiva ou não, até
          03 (três) vezes o valor de uma das verbas contempladas nas OBRIGAÇÕES LOCATÍCIAS, sem o
          devido ressarcimento pelo LOCATÁRIO ou COOBRIGADO, e sem o devido saneamento em até 30
          (trinta) dias contados da comunicação de cobrança expedida pela RENTI, ressalvada a hipótese
          legal e legítima da RENTI se exonerar da obrigação de fiadora a qualquer tempo, na forma do
          art. 835 do Código Civil e demais lei aplicáveis.
          d) Não contratação do seguro obrigatório, sem o devido saneamento no prazo de 30 (trinta)
          dias contados da comunicação expedida pela RENTI.
          e) Não pagamento, principalmente por contestação de lançamentos, quando a RENTI realizar a
          cobrança de valores por ela pagos e a serem ressarcidos pelo LOCATÁRIO ou COOBRIGADO nos
          respectivos cartões de créditos (quando esta for a forma de pagamento eleita), sem o devido
          saneamento no prazo de 05 (cinco) dias contados da comunicação expedida pela RENTI.
          f) Qualquer alteração na LOCAÇÃO que não tenha sido previa e expressamente comunicada à
          RENTI, com a respectiva autorização. São exemplos (mas sem limitar a): modificações nas
          PARTES da LOCAÇÃO, a transferência da gestão do IMÓVEL pelo LOCADOR ou IMOBILIÁRIA,
          cessão de qualquer natureza, realização de qualquer acordo ou termo aditivo sem a
          interveniência e anuência da RENTI,
          g) Descumprimento de qualquer previsão dos presentes TERMOS, sem o devido saneamento no
          prazo de 05 (cinco) dias contados do recebimento da comunicação da RENTI, quando saneável o
          inadimplemento, ou imediatamente, contado do recebimento da comunicação da RENTI, quando
          não saneável.
          h) Não inclusão no contrato ou aditivo da LOCAÇÃO, ou falha na respectiva assinatura conforme
          fixado nestes TERMOS, da cláusula de FIANÇA LOCATÍCIA (Cláusula 5.5), sem o devido
          saneamento em até 05 (cinco) dias contados do recebimento da comunicação da RENTI.`,
          subtopics: [
            {
              title: `Independentemente da hipótese de rescisão pela RENTI, os SERVIÇOS perderão
              imediatamente sua vigência, oportunidade em que a RENTI notificará todas as PARTES quanto à
              exoneração da FIANÇA LOCATÍCIA. A partir da data da rescisão, cessará a responsabilidade da
              RENTI quanto a qualquer valor contrato no âmbito dos SERVIÇOS.`,
            },
          ],
        },
        {
          title: `Havendo o falecimento, interdição ou qualquer outra forma de incapacidade do LOCATÁRIO,
          ou existindo suspeita de ilícito na LOCAÇÃO (como fraude nas declarações), sem prejuízo da
          responsabilidade exclusiva da IMOBILIÁRIA, esta será notificada pela RENTI para promover o
          cancelamento e rescisão da respectiva LOCAÇÃO.`,
        },
        {
          title: `Em qualquer hipótese de encerramento da LOCAÇÃO, compromete-se o LOCATÁRIO a
          comunicar imediatamente a restituição do imóvel (desocupação, entrega das chaves ou outro
          ato de devolução do imóvel) à RENTI, exclusivamente pelo canal
          atendimento@rentialuga.com.br, incluindo todos os dados da LOCAÇÃO, do LOCATÁRIO e do
          IMÓVEL, sob pena de responder por qualquer valor (despesa, taxa ou indenização, seja das
          OBRIGAÇÕES LOCATÍCIAS ou não) derivada do respectivo atraso.`,
        },
      ],
    },
    {
      title: "INADIMPLEMENTO",
      type: "subtopics",
      subtopics: [
        {
          title: `Em caso de descumprimento, pelas PARTES, de qualquer das obrigações assumidas nestes
          TERMOS, incidirá, em favor da outra PARTE, multa no valor de 10% (dez porcento) das
          OBRIGAÇÕES LOCATÍCIAS vencidas no mês anterior à infração contratual.`,
        },
        {
          title: `O LOCATÁRIO e o COOBRIGADO desde já autorizam à RENTI, em caráter irrevogável e
          irretratável, a realizar a cobrança e lançamento do valor da multa acima mencionada, de acordo
          com a forma de pagamento eleita. Já a IMOBILIÁRIA autoriza, igualmente em caráter
          irretratável e irrevogável, à RENTI a reter quaisquer pagamentos que lhe sejam devidos pelos
          SERVIÇOS, acaso incorra em falta contratual, concordando com que esta realize a retenção até
          a inteira compensação do valor apurado a título de multa.`,
        },
        {
          title: `A autorização para retenção e compensação prevista na Cláusula 9.2 se aplica também às
          demandas, ações, autuações, de caráter patrimonial ou não, promovidas pelas autoridades
          competentes para a regulamentação ou fiscalização, direta ou indireta, dos SERVIÇOS.`,
        },
      ],
    },
    {
      title: "CONFIDENCIALIDADE",
      type: "subtopics",
      subtopics: [
        {
          title: `As PARTES concordam em manter o mais absoluto sigilo e a confidencialidade em relação
          às informações fornecidas, em decorrência dos SERVIÇOS RENTI, assim como qualquer material
          de natureza sigilosa ou de propriedade intelectual ou industrial protegida, jamais revelando tal
          material ou informação a terceiros não autorizados, decorrentes ou oriundos dos presentes
          TERMOS e do serviço prestado.`,
        },
        {
          title: `A IMOBILIÁRIA deverá notificar previamente a RENTI acaso seja obrigada, por lei ou
          decisão judicial ou arbitral, a realizar divulgação de qualquer informação confidencial.`,
        },
        {
          title: `Caso uma das PARTES entenda ser necessária transmissão ou divulgação de informação
          confidencial a terceiros, deverá obter prévia autorização da outra PARTE e providenciar que
          referidos terceiros se comprometam e obriguem ao dever de sigilo aqui previsto.`,
        },
        {
          title: `As PARTES se obrigam a devolver, ao final da prestação de serviços, todas informações
          confidenciais em forma tangível.`,
        },
        {
          title: `A presente Cláusula vigerá mesmo após o fim dos presentes TERMOS, pelo prazo de 10
          (dez) anos.`,
        },
        {
          title: `Qualquer violação a esta Cláusula acarretará a rescisão destes TERMOS, a incidência da
          multa prevista na Cláusula 9ª e o ressarcimento, pela PARTE infratora, por perdas e danos e
          demais cominações legais e contratuais cabíveis.`,
        },
      ],
    },
    {
      title: "PROTEÇÃO DE DADOS PESSOAIS",
      type: "subtopics",
      subtopics: [
        {
          title: `As PARTES, se comprometem, por si, seus colaboradores, subcontratados e demais
          representantes, a tratar os dados pessoais, a que tiverem acesso na execução dos SERVIÇOS
          RENTI, em conformidade com os requisitos exigidos pela Lei nº 13.709/2018 (Lei Geral de
          Proteção de Dados Pessoais), com as determinações emitidas pela Autoridade Nacional de
          Proteção de Dados (ANPD) e demais órgãos fiscalizadores sobre o tema, bem como as
          regulamentações de países envolvidos em operações internacionais de tratamento de dados.`,
        },
        {
          title: `As obrigações aqui estabelecidas se aplicam aos dados coletados ou produzidos
          diretamente pela PARTE e também às bases de dados compartilhadas entre si ou recebidas de
          terceiros no escopo destes TERMOS.`,
        },
        {
          title: `As PARTES deverão manter controles adequados, com a finalidade de monitorar e verificar
          sua própria conformidade, devendo implantar todas as medidas de segurança, técnicas e
          administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações
          acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de
          tratamento inadequado ou ilícito.`,
          subtopics: [
            {
              title: `Tais medidas deverão ser abrangentes, aplicáveis a todo conjunto de dados pessoais sob
              controle da PARTE, adaptadas à estrutura, à escala e ao volume de suas operações, bem como à
              sensibilidade dos dados tratados, o que inclui a utilização de mecanismos tecnológicos
              atualizados e medidas administrativas que salvaguardem as informações.`,
            },
            {
              title: `As PARTES deverão tratar todo e qualquer dado pessoal com o mesmo nível de
              confidencialidade e segurança técnica e administrativa, mesmo em caso de extinção destes
              TERMOS e do motivo do seu término.`,
            },
          ],
        },
        {
          title: `As PARTES se obrigam a atender aos direitos dos titulares, nos termos da legislação,
          devendo cooperar mutuamente para subsidiar eventual atendimento à requisição que envolva
          tratamento conjunto de dados pessoais. Para esse fim, a PARTE recebedora da requisição deverá
          posicionar, à outra PARTE, em até 48 horas contadas do recebimento da solicitação do titular,
          informando todos os termos apresentados pelo titular e os esclarecimentos necessários para
          atendimento.`,
          subtopics: [
            {
              title: `Se qualquer das PARTES receber requisição de titular sobre tratamento de dados que
              não seja de sua responsabilidade, deverá notificar a PARTE responsável, no mesmo prazo de 48
              horas contados do recebimento, para que providencie a resposta à requisição.`,
            },
          ],
        },
        {
          title: `Para execução do objeto dos SERVIÇOS, as PARTES poderão compartilhar dados pessoais
          ou mesmo bases de dados pessoais, conforme a necessidade para atendimento dos respectivos
          escopos. Em qualquer caso, ambas as PARTES declaram que possuem os dados (seja por coleta,
          compartilhamento ou produção) de forma lícita, em conformidade com a Lei Geral de Proteção
          de Dados Pessoais, sobretudo (i) pautando o tratamento em alguma das bases legais elencadas
          no art. 7º da referida lei, (ii) atendendo ao princípio da transparência, e (iii) obtendo, quando
          cabível, as autorizações necessárias para devida coleta dos dados. A IMOBILIÁRIA se
          responsabiliza integralmente, perante a RENTI, pela licitude da constituição da respectiva base
          de dados, que será utilizada no âmbito dos SERVIÇOS.`,
          subtopics: [
            {
              title: `Os dados pessoais que as PARTES tiverem acesso no curso deste CONTRATO deverão
              ser tratados exclusivamente para execução do seu objeto, de maneira lícita e transparente,
              segundo expectativas dos titulares, não podendo o tratamento ser desvirtuado para outra
              finalidade em hipótese alguma, sob pena de responsabilização isolada da PARTE responsável
              pelo descumprimento desta cláusula.`,
            },
          ],
        },
        {
          title: `As PARTES se obrigam a posicionar a outra PARTE em caso de identificação de suspeita ou
          de incidente envolvendo dados pessoais, no prazo de 48 horas contados do conhecimento, pela
          PARTE, da suspeita ou do incidente.`,
        },
      ],
    },
    {
      title: "DISPOSIÇÕES FINAIS",
      type: "subtopics",
      subtopics: [
        {
          title: `Estes TERMOS representam o acordo integral entre a RENTI, a IMOBILIÁRIA, o
          LOCATÁRIO e o COOBRIGADO, substituindo e prevalescente sobre qualquer instrumento ou
          entendimento anterior sobre os SERVIÇOS.`,
        },
        {
          title: `A IMOBILIÁRIA, o LOCATÁRIO e o COOBRIGADO não poderão ceder ou transferir os
          direitos e obrigações assumidos na LOCAÇÃO e nestes TERMOS, sem a prévia e expressa
          anuência da RENTI, sob pena de rescisão motivada da FIANÇA LOCATÍCIA e responsabilização
          exclusiva da parte responsável.`,
          subtopics: [
            {
              title: `Em caso de intenção de transferência de gestão, deverá a IMOBILIÁRIA notificar a
              RENTI, apresentando todos os dados da respectiva cessão de carteira, do cessionário e da
              anuência do LOCADOR e do LOCATÁRIO, para análise pela RENTI e autorização ou não. A RENTI
              poderá, a seu exclusivo critério, realizar DILIGÊNCIA quanto ao cessionário, a fim de apurar os
              riscos envolvidos na transferência.`,
            },
            {
              title: `Em caso de intenção de transferência de LOCADOR ou de LOCATÁRIO, deverá a
              IMOBILIÁRIA notificar a RENTI apresentando todos os dados da respectiva cessão contratual, do
              cessionário e da anuência da outra parte da LOCAÇÃO, para análise pela RENTI e autorização ou
              não. A RENTI poderá, a seu exclusivo critério, realizar DILIGÊNCIA quanto ao cessionário, a fim
              de apurar os riscos envolvidos na transferência.`,
            },
          ],
        },
        {
          title: `Comunicações: qualquer comunicação ou notificação no âmbito destes TERMOS deverá
          ocorrer eletronicamente, por meio de envio de e-mail ao endereço eletrônico das PARTES,
          cadastrado no início dos SERVIÇOS. Para a RENTI, somente serão admitidas comunicações ou
          notificações encaminhadas exclusivamente ao endereço atendimento@rentialuga.com.br. Para
          tanto, a IMOBILIÁRIA, o LOCATÁRIO e o COOBRIGADO se comprometem a cadastrar endereços
          eletrônicos exclusivamente de pessoas com poderes legais de representação da respectiva
          parte. Obrigam-se, ainda, a manter referidos endereços eletrônicos atualizados, sob pena
          responderem isoladamente por qualquer dano ou prejuízo daí acarretado.`,
          subtopics: [
            {
              title: `As comunicações e notificações serão consideradas recebidas pelo destinatário na data
              em que o e-mail tiver sido transmitido, pela PARTE, acaso a transmissão tenha ocorrido em dia
              útil e até as 19:00 horas. Transmissões posteriores a tal horário ou ocorridas em dias não úteis,
              serão consideradas recebidas no primeiro dia útil subsequente.`,
            },
          ],
        },
        {
          title: `Nenhuma renúncia ou rescisão relativa a qualquer prerrogativa prevista nestes TERMOS
          vinculará quaisquer das PARTES, salvo se confirmada por escrito pela própria PARTE
          interessada. Assim, eventual tolerância de qualquer das PARTES em relação a descumprimento
          de qualquer obrigação será mera liberalidade e não poderá ser entendida como novação ou
          renúncia do ajustado.`,
        },
        {
          title: `Caso qualquer disposição destes TERMOS seja considerada nula, anulável, inválida ou
          ineficaz, as demais disposições permanecerão em pleno vigor, válidas e exequíveis, devendo as
          PARTES negociar um ajuste equânime da disposição considerada nula, anulável, inválida ou
          ineficaz, de modo a assegurar a respectiva validade e exequibilidade.`,
        },
        {
          title: `Nenhuma PARTE poderá usar a imagem e a marca da outra sem a prévia e expressa
          autorização. A RENTI não se responsabiliza por qualquer informação divulgada pela
          IMOBILIÁRIA ou CORRETOR AUTÔNOMO a respeito dos SERVIÇOS.`,
        },
        {
          title: `Os presentes TERMOS poderão ser atualizados a qualquer tempo, exclusivo critério da
          RENTI, mediante comunicação à IMOBILIÁRIA e ao LOCATÁRIO.`,
        },
      ],
    },
    {
      title: "FORO",
      type: "subtopics",
      subtopics: [
        {
          title: `Para quaisquer questões envolvendo os presentes TERMOS, fica eleito o foro da situação
          do IMÓVEL OBJETO da LOCAÇÃO.`,
        },
      ],
    },
  ];
}
