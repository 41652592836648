import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider = ({ children }: { children: React.ReactNode }) => {

    const location = useLocation();
    const [selectedItemMenu, setSelectedItemMenu] = useState<string | null>(null);

    useEffect(() => {
        const currentRoute = location.pathname;
        setSelectedItemMenu(currentRoute);
    }, [location.pathname]);

    return (
        <MenuContext.Provider value={{ selectedItemMenu, setSelectedItemMenu }}>
            {children}
        </MenuContext.Provider>
    );
};

export const useMenuContext = () => {
    const context = useContext(MenuContext);

    if (!context) {
        throw new Error('useMenuContext deve ser usado dentro de um MenuProvider');
    }

    return context;
};

interface MenuContextType {
    selectedItemMenu: string | null;
    setSelectedItemMenu: React.Dispatch<React.SetStateAction<string | null>>;
}
