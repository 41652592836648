import React from "react";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import { client } from "./service/api";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { Login } from "./pages/Login";
import { Layout } from "./components/Layout";
import { AuthProvider } from "context/AuthProvider";
import { SnackbarProvider } from "notistack";
import { Report } from "pages/Report";
import { DetailProposal } from "pages/DetailProposal";
import { CreateProposal } from "pages/CreateProposal";
import { Proposal } from "pages/Proposal";
import { Contracts } from "pages/Contracts";
import Ocurrences from "pages/Occurrences";
import { ThemeProvider } from "@emotion/react";
import theme from "theme";
import { Settings } from "pages/Settings";
import { MenuProvider } from "context/MenuContext";
import SignContract from "pages/SignContract";
import ViewContract from "pages/ViewContract";
import { ReportDetail } from "pages/ReportDetail";
import { ContractDetail } from "pages/ContractDetail";
import { OccurrenceDetail } from "pages/OccurrenceDetail";
import { TermsOfUse } from "pages/TermsOfUse";


export default function App() {
  return (
    <SnackbarProvider
      maxSnack={2}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >

      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>

          <BrowserRouter>
            <AuthProvider>
              <MenuProvider>

                <Layout>
                  <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route index path="/login/:token?" element={<Login />} />

                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/cadastrarProposta" element={<CreateProposal />} />

                    <Route path="/proposta/:id" element={<DetailProposal />} />
                    <Route path="/propostas" element={<Proposal />} />

                    <Route path="/assinatura/:code" element={<SignContract />} />

                    <Route path="/visualizar-contrato/:code" element={<ViewContract />} />

                    <Route path="/termos-de-uso/:code" element={<TermsOfUse />} />

                    <Route path="/contrato/:id" element={<ContractDetail />} />
                    <Route path="/contratos" element={<Contracts />} />

                    <Route path="/ocorrencia/:id" element={<OccurrenceDetail />} />
                    <Route path="/ocorrencias" element={<Ocurrences />} />

                    <Route path="/relatorio" element={<ReportDetail />} />
                    <Route path="/relatorios" element={<Report />} />

                    <Route path="/configuracoes" element={<Settings />} />
                  </Routes>
                </Layout>

              </MenuProvider>
            </AuthProvider>
          </BrowserRouter>

        </ThemeProvider>
      </ApolloProvider>
    </SnackbarProvider>
  );
}
