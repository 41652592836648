import { gql } from "@apollo/client";

export const CREATE_PROPOSAL = gql`
  mutation createProposal(
    $immobile: CreateProposalImmobileObjectInput!
    $plan_id: Int!
    $rent_amount: Float!
    $condominium_amount: Float
    $other_taxes: Float
    $chosen_installments: Int!
    $chosen_setup_fee: Float!
    $chosen_setup_installments: Int!
    $iptu: Float
    $tenant: CreateProposalTenantObjectInput!
    $proposal_simulation_id: Int
  ) {
    createProposal(
      immobile: $immobile
      plan_id: $plan_id
      rent_amount: $rent_amount
      condominium_amount: $condominium_amount
      other_taxes: $other_taxes
      chosen_installments: $chosen_installments
      chosen_setup_fee: $chosen_setup_fee
      chosen_setup_installments: $chosen_setup_installments
      iptu: $iptu
      tenant: $tenant
      proposal_simulation_id: $proposal_simulation_id
    )
  }
`;
