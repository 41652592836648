import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { InputProps } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "../IconButton";

type InputComponentProps = InputProps & {
  isPassword?: boolean;
  isRequired?: boolean;
  shouldUnregister?: boolean;
  showLabel?: boolean;
  colorText?:string;
  fontWeight?:string;
};

const InputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  InputComponentProps
> = (
  {
    type,
    name = "",
    placeholder,
    isPassword,
    isRequired,
    showLabel,
    colorText,
    fontWeight,
    shouldUnregister = false,
    ...rest
  },
  ref
) => {
  const { control } = useFormContext();
  const [isShowPassword, setIsShowPassword] = React.useState(false);

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      rules={{
        required: isRequired && "Campo obrigatório",
      }}
      render={(inputProps) => (
        <FormControl variant="outlined" fullWidth>
         {showLabel ? (
            <InputLabel
              htmlFor={name}
              error={inputProps.fieldState.error && true}
              sx={{ color: "#2E349F"}}
            >
              {placeholder}
            </InputLabel>
          ) : (
            ""
          )}
            
          <OutlinedInput
            endAdornment={
              isPassword && (
                <IconButton
                  onClick={() => setIsShowPassword((prevState) => !prevState)}
                >
                  {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              )
            }
            // inputProps={{ shrink: false }}
            value={inputProps.field.value ?? ""}
            onChange={(e) => inputProps.field.onChange(e.target.value)}
            name={name}
            id={name}
            type={isPassword ? (isShowPassword ? "text" : "password") : type}
            {...rest}
            label={placeholder}
            error={inputProps.fieldState.error && true}
            ref={ref}
            fullWidth
            placeholder={placeholder}
            sx={{ backgroundColor: "white", color: colorText, fontWeight:fontWeight }}
          />
          {inputProps.fieldState.error && (
            <FormHelperText error={inputProps.fieldState.error && true}>
              {inputProps.fieldState.error.message + ""}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default forwardRef(InputBase);
