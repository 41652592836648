import React from "react";
import logoFacebbok from "../../assets/facebook.png";
import logoWhatsapp from "../../assets/whatsapp.png";
import logoInstagran from "../../assets/instagran.png";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const linkStyle = {
  textDecoration: "none",
  color: "#fff",
  marginRight: "2rem",
};

const Footer = () => {
  const istablet = useMediaQuery("(min-width: 650px) ");
  return (
    
    <div
      style={{
        backgroundColor: "#51D347",
        padding: "1rem 0",
        position: "fixed",
        bottom: "0",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        fontSize: "1.1rem",
        
      }}
    >
      {istablet ? <div>
        <Link to="#" style={linkStyle}>
          Home
        </Link>
        <Link to="#" style={linkStyle}>
          Imobiliárias
        </Link>
        <Link to="#" style={linkStyle}>
          Blog
        </Link>
        <Link to="#" style={linkStyle}>
          FAQ
        </Link>
        <Link to="#" style={linkStyle}>
          Contato
        </Link>
      </div>: ""}
      <div style={{ display: "flex", gap: "1rem" }}>
        <a href="#">
          <img
            width="35px"
            height="35px"
            src={logoFacebbok}
            alt="logoFacebbok"
          />
        </a>
        <a href="#">
          <img
            width="35px"
            height="35px"
            src={logoWhatsapp}
            alt="logoWhatsapp"
          />
        </a>
        <a href="#">
          <img
            width="35px"
            height="35px"
            src={logoInstagran}
            alt="logoInstagran"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
