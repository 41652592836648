import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import { Control, Controller, UseFormGetValues, useForm } from "react-hook-form";
import FormEmail from "./FormEmail";
import { useMutation } from "@apollo/client";
import { RECOVERY_PASSWORD } from "graphql/mutation/recoveryPassword";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import FormToken from "./FormToken";
import FormPasswords from "./FormPasswords";
import { UPDATE_PASSWORD } from "graphql/mutation/updatePassword";
import { useNavigate } from "react-router-dom";


export default function RecoveryPassword({
    open,
    handleClose,
    token
}: RecoveryPasswordProps) {

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        defaultValues: {
            email: "",
            securityToken: token || "",
            password: "",
            confirmPassword: ""
        }
    });
    const { control, watch, setValue, getValues } = methods;

    const [recoveryPassword, { loading: loadingRecovery }] = useMutation(RECOVERY_PASSWORD)
    const [updatePassword, { loading: loadingUpdate }] = useMutation(UPDATE_PASSWORD)

    const [hideStep, setHideStep] = useState(0)

    const recoveryPasswordSubmit = async () => {

        const { email } = getValues()

        if (!email) {
            enqueueSnackbar("Favor, informa um e-mail válido", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
            return
        }

        try {
            const data = await recoveryPassword({
                variables: {
                    email
                }
            })
            if (data) {
                enqueueSnackbar("Código enviado com sucesso!", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                });
                handleClose()
            }
        } catch (e) {
            enqueueSnackbar((e as Error).message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
        }
    }

    const changePasswordSubmit = async () => {

        const { password, confirmPassword, email, securityToken } = getValues()
        const matchedPassword = password === confirmPassword

        if (!matchedPassword) {
            enqueueSnackbar("Favor digitar a mesma senha no campo de confirmação!", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
            return
        }

        try {
            const data = await updatePassword({
                variables: {
                    code: securityToken,
                    password: password
                }
            })
            if (data) {
                enqueueSnackbar("Senha alterada com sucesso!", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                });
                navigate("/login", { replace: true })
            }
        } catch (e) {
            enqueueSnackbar((e as Error).message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
        }

    }

    const handleSubmit = () => {
        switch (hideStep) {
            case 0:
                recoveryPasswordSubmit()
                break
            case 1:
                changePasswordSubmit()
                break
        }
    }

    useEffect(() => {
        if (token) {
            setHideStep(1)
            return
        }
        setHideStep(0)
    })

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" color="primary">
                Esqueci minha senha
            </DialogTitle>
            <DialogContent>
                {
                    hideStep === 0 &&
                    <FormEmail {...{ control, getValues }} />
                }
                {
                    hideStep === 1 &&
                    <FormPasswords {...{ control, getValues }} />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>

                <Button
                    disabled={loadingRecovery || loadingUpdate}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    sx={{
                        color: "white"
                    }}
                >
                    {
                        (loadingRecovery || loadingUpdate) &&
                        <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
                    }
                    Concluir
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export interface RecoveryItemsProps {
    control: Control<{
        email: string;
        securityToken: string;
        password: string;
        confirmPassword: string;
    }>,
    getValues: UseFormGetValues<{
        email: string;
        securityToken: string;
        password: string;
        confirmPassword: string;
    }>
}

interface RecoveryPasswordProps {
    open: boolean
    handleClose: () => void,
    token: string | null
}