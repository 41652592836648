import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import Input from "components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { maskCEP, maskCPF, maskMoney, maskPhone } from "utils/maskOutputs";
import styled from "@emotion/styled";
import FormActions from "./FormActions";
import { FETCH_STATES } from "graphql/query/fetchStates";
import { SelectWithSearch } from "components/SelectWithSearch";
import { FETCH_CITIES } from "graphql/query/fetchCities";

const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#51D347",
  "&.Mui-checked": {
    color: "#51D347",
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
    backgroundColor: "transparent",
  },
  "& .MuiIconButton-root:hover": {
    backgroundColor: "transparent",
  },
}));

interface FormTenantProps {
  formData: {
    name: string,
    email: string,
    cpf: string,
    phone: string,
    selectedDate: string,
    responsiblePayment: boolean,
    income: string,
    extras: {
      email: string,
      phone: string,
      address: {
        zipcode: string,
        street: string,
        number: string,
        district: string,
        city: {
          id: number,
          name: string
          state: string
        },
        complement: string,
        reference: string,
      }
    }
  }
  sendData: (data: {
    name: string;
    email: string;
    cpf: string;
    phone: string;
    selectedDate: string | null;
    responsiblePayment: boolean;
    income: string;
    extras: {
      email: string;
      phone: string,
      address: {
        zipcode: string,
        street: string,
        number: string,
        district: string,
        city: {
          id: number,
          name: string,
          state: string,
        },
        complement: string,
        reference: string,
      }
    }
  }, objName: string) => void;
  activeStep: number,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export default function FormTenant({
  formData,
  sendData,
  activeStep,
  setActiveStep
}: FormTenantProps) {

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: formData.name,
      email: formData.email,
      cpf: formData.cpf,
      phone: formData.phone,
      selectedDate: formData.selectedDate,
      responsiblePayment: formData.responsiblePayment,
      income: formData.income,
      extras: formData.extras ?? {}
    }
  });

  const { control, watch, setValue, getValues, clearErrors } = methods;

  console.log(methods.formState.errors.extras)

  const handleSendForm = async () => {
    await methods.trigger();

    const {
      name,
      email,
      cpf,
      phone,
      selectedDate,
      responsiblePayment,
      income,
      extras
    } = getValues()

    sendData({
      name: name,
      email: email,
      cpf: cpf,
      phone: phone,
      selectedDate: selectedDate,
      responsiblePayment: responsiblePayment || true,
      income: income,
      extras: {
        email: extras.email,
        phone: extras.phone,
        address: {
          zipcode: extras.address.zipcode,
          street: extras.address.street,
          number: extras.address.number,
          district: extras.address.district,
          city: {
            id: extras.address.city.id,
            name: extras.address.city.name,
            state: extras.address.city.state
          },
          complement: extras.address.complement,
          reference: extras.address.reference,
        }
      }
    }, "stepTenant");
  };


  const handleResponsiblePaymentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("responsiblePayment", event.target.checked);
  };

  const customTypography = {
    fontSize: "1.3rem",
    fontWeight: "400",
    color: "#2E349F",
  };

  return (
    <Box width="100%">
      <Typography style={customTypography}>
        Preencha informações sobre o locatário que permitam à Renti fazer uma
        análise de crédito, verificando se atendem aos critérios para adquirir
        uma garantia locatícia
      </Typography>

      <FormProvider {...methods}>
        <Stack
          component="form"
          spacing={4}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Nome*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="cpf"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="CPF*"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 14 }}
                    value={maskCPF(field.value)}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Controller
                name="selectedDate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...{ field }}
                    type="date"
                    id="date"
                    label="Data de Nascimento*"
                    value={field.value}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "#2E349F" },
                    }}
                    onChange={(e) => {
                      setValue("selectedDate", e.target.value)
                    }}
                    fullWidth
                    error={!!methods.formState.errors.selectedDate}
                    helperText={methods.formState.errors.selectedDate?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="income"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Renda Mensal*"
                    type="text"
                    fullWidth
                    onChange={(e) => {
                      const value = e.target.value;
                      const number = maskMoney(value);
                      field.onChange(number);
                    }}
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="E-mail*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Telefone*"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    value={maskPhone(field.value)}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography color="#2E349F" variant="h5">
                Extras
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="extras.email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="E-mail*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="extras.phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Telefone*"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    value={maskPhone(field.value ?? "")}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography color="#2E349F" variant="h5">
                Endereço
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="extras.address.zipcode"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="CEP*"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 9 }}
                    value={maskCEP(field.value || "")}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="extras.address.street"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Endereço*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="extras.address.number"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Número*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="extras.address.district"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Bairro*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectWithSearch
                query="fetchStates"
                queryHook={FETCH_STATES}
                label="Estado*"
                value={watch("extras.address.city.state") || ""}
                onChange={(e: { id: number, name: string }) => {
                  setValue("extras.address.city.state", e.name)
                  clearErrors("extras.address.city.state")
                }}
                error={methods.formState.errors.extras?.address?.city?.state?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectWithSearch
                isDisabled={!getValues("extras.address.city.state")}
                query="fetchCities"
                queryHook={FETCH_CITIES}
                queryVariables={{
                  state: getValues("extras.address.city.state") || "",
                  limit: 860,
                }}
                label="Cidade*"
                value={watch("extras.address.city.name") || ""}
                onChange={(e: { id: number, name: string, state: string }) => {
                  setValue("extras.address.city.id", e.id)
                  setValue("extras.address.city.name", e.name)
                  clearErrors("extras.address.city.name")
                }}
                error={methods.formState.errors.extras?.address?.city?.name?.message}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="extras.address.complement"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Complemento*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="extras.address.reference"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Referência*"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box width="100%" display="flex" justifyContent="flex-start">
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={watch("responsiblePayment")}
                  onChange={handleResponsiblePaymentChange}
                  name="responsavelPagamento"
                />
              }
              label="Responsável pelo pagamento"
              sx={{ color: "#2E349F" }}
            />
          </Box>
        </Stack>
      </FormProvider>

      <FormActions {...{ activeStep, setActiveStep, methods }} handleForm={handleSendForm} />

    </Box>
  );
}

const schema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  email: yup.string().required("E-mail é obrigatório"),
  phone: yup.string().required("Telefone é obrigatório"),
  cpf: yup.string().required("Cpf é obrigatório"),
  selectedDate: yup.string().required("Data de nascimento é obrigatório"),
  responsiblePayment: yup.boolean(),
  income: yup.string().required("Renda é obrigatório"),
  extras: yup.object().shape({
    email: yup.string().required("E-mail é obrigatório"),
    phone: yup.string().required("Telefone é obrigatório"),
    address: yup.object().shape({
      zipcode: yup.string().required("Cep é obrigatório"),
      street: yup.string().required("Endereço é obrigatório"),
      number: yup.string().required("Número é obrigatório"),
      district: yup.string().required("Bairro é obrigatório"),
      city: yup.object().shape({
        id: yup.number().required("Cidade é obrigatório"),
        name: yup.string().required("Cidade é obrigatório"),
        state: yup.string().required("Estado é obrigatório")
      }),
      complement: yup.string().required("Complemento é obrigatório"),
      reference: yup.string().required("Referência é obrigatório"),
    })
  })
});