import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useAuthContext } from "../../context/AuthProvider";
import { Grid, Stack, CircularProgress } from "@mui/material";
import { Sidebar } from "components/Sidebar";
import { useLocation } from "react-router-dom";
import logo from "../../assets/horizontal05.png";
import { MenuOpen } from "components/Sidebar/MenuOpen";
import IconButton from "components/IconButton";
import useVerifyScreen from "hooks/useVerifyScreen";
import { useQuery } from "@apollo/client";
import { QueryRoot } from "graphql/gql/graphql";
import { ME } from "graphql/query/me";

interface HeaderProps {
  children: React.ReactNode
}

export function Header({
  children
}: HeaderProps) {

  const isMobile = useVerifyScreen()
  const { state } = useAuthContext()
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [selectedItemMenu, setSelectedItemMenu] = useState("/dashboard")

  const { data, loading, error } = useQuery<QueryRoot>(ME, {
    variables: { id: state?.person.id },
  });

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "#ffff",
          boxShadow: "none",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "0.3%",
          paddingRight: "0.3%",
          borderBottom: "1px solid #f3f3f3",
          marginBottom: 4
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <>
              {location.pathname !== "/login" ? (
                <>
                  <Box display="flex" alignItems="center" gap={5}>
                    <Box display="flex" justifyContent="flex-start">
                      <IconButton
                        onClick={handleDrawer}
                        edge="start"
                        sx={{
                          color: "#2E349F",
                          marginLeft: "0.1rem",
                          ...(open && { display: "block" }),
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Box>

                    <Box>
                      <Grid container>
                        <img
                          src={logo}
                          width="110px"
                          height="30px"
                          alt="logo Renti"
                        />
                      </Grid>
                    </Box>
                  </Box>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Box display="flex">
                      <Typography
                        sx={{ wordBreak: "break-all" }}
                        variant="h6"
                        fontWeight={600}
                        color="#2E349F"
                      >
                        {data?.me?.email || ""}
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Stack flexDirection="row">
        {
          !isMobile &&
          <Sidebar
            {...{ selectedItemMenu, setSelectedItemMenu }}
            openMenu={open}
          />
        }
        {
          isMobile &&
          <MenuOpen {...{ open, selectedItemMenu, setSelectedItemMenu }} onClose={handleDrawer} />
        }
        <Box
          sx={{
            marginLeft: (open && !isMobile) ? 10 : 0,
            transition: "0.3s",
            padding: 6,
            width: "100%"
          }}
        >
          <Box sx={{
            marginLeft: !isMobile ? 15 : 0
          }} >{children}</Box>
        </Box>
      </Stack>
    </>
  );
}

export default Header;
