import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import { Controller, FormProvider, useForm } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@apollo/client";
import { SEND_ATTACHMENTS_PROPOSAL } from "graphql/mutation/sendAttachmentsProposal";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { DETAIL_PROPOSAL } from "graphql/query/detailProposal";

interface Attachment {
  name: string;
  file: File;
  type: string;
}

export default function ModalSendDocument({
  open,
  handleClose,
  setData,
  data,
}: ModalSendDocumentProps) {
  const { id } = useParams<{ id: string }>();

  const methods = useForm({
    defaultValues: {
      name: "",
      file: "",
      type: "Outros",
    },
  });
  const { handleSubmit, getValues, control, watch, setValue } = methods;

  const [attachments, setAttachments] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<string>('Outros');

  const [sendAttachmentsProposal, { loading }] = useMutation(
    SEND_ATTACHMENTS_PROPOSAL,
    {
      refetchQueries: [
        { query: DETAIL_PROPOSAL, variables: { id: Number(id) } },
      ],
    }
  );

  const handleAdd = () => {
    let { name, file } = getValues();

    if (!file || file.length === 0) {
      enqueueSnackbar("Selecione um arquivo", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      return;
    }

    if (!name) {
      console.error("No name to file");
      enqueueSnackbar("Informe um nome para o arquivo", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      return;
    }

    let attachment = { name, file: file[0], type: fileType };

    setAttachments([...attachments, attachment]);

    setValue("name", "");
    setValue("file", "");
    setFileType("Outros");
  };

  const handleRemove = (index: number) => {
    let arrayClone = [...attachments];

    arrayClone.splice(index, 1);
    setAttachments(arrayClone);
  };

  const handleSave = async () => {
    if (attachments.length === 0) {
      enqueueSnackbar("Nenhum arquivo para enviar", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      return;
    }

    const names = attachments.map((a) => a.name);

    const formData = new FormData();

    attachments.forEach((attachment, index) => {
      formData.append(`files[${index}]`, attachment.file);
      formData.append(`types[${index}]`, attachment.type);
    });

    const filesData = attachments.map((_, index) =>
      formData.get(`files[${index}]`)
    );

    try {
      await sendAttachmentsProposal({
        variables: {
          proposal_id: Number(id),
          names: names,
          files: filesData,
          types: attachments.map((a) => a.type),
        },
      });

      enqueueSnackbar("Anexos enviados com sucesso", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });

      setAttachments([]);
      setData({ filesData });
      handleClose();
    } catch (error) {
      console.error("Error sending attachments", error);
      enqueueSnackbar("Erro ao enviar anexos", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" fontWeight="bold">
        Enviar documentação
      </DialogTitle>
      <DialogContent>
        {attachments.length > 0 && (
          <>
            <Box flexDirection="column" display="flex">
              {attachments.map((item: Attachment, index: number) => (
                <Box
                  key={index}
                  flexDirection="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography fontWeight="bold">{item.name}</Typography>
                    <Typography>
                      <AttachFileIcon sx={{ fontSize: 15 }} />
                      {item.file.name}
                    </Typography>
                    <Typography>{item.type}</Typography>
                  </Box>
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    color="error"
                    onClick={() => handleRemove(index)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              ))}

            </Box>

            <Divider sx={{ mt: 3, mb: 3 }} />
          </>
        )}
        <FormProvider {...methods}>
          <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
              <Controller
                {...{ control }}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Nome do arquivo"
                    type="text"
                    fullWidth
                    value={field.value}
                    showLabel={true}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <Controller
                control={control}
                name="file"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <>
                    <input
                      id="file-input"
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(
                          e.target.files ? Array.from(e.target.files) : [],
                          setSelectedFile(
                            e.target.files ? e.target.files[0] : null
                          )
                        );
                      }}
                      ref={ref}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                    />
                    <label htmlFor="file-input">
                      <Button
                        variant="outlined"
                        component="span"
                        fullWidth
                        sx={{ height: "100%" }}
                      >
                        {selectedFile
                          ? selectedFile.name
                          : "Selecione um arquivo"}
                      </Button>
                    </label>
                  </>
                )}
              />
            </Grid>


            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={fileType}
                    onChange={(e) => {
                      setFileType(e.target.value as string);
                      field.onChange(e);
                    }}
                  >
                    <MenuItem value="Termos de uso">Termos de uso</MenuItem>
                    <MenuItem value="Contrato">Contrato</MenuItem>
                    <MenuItem value="Vistoria">Vistoria</MenuItem>
                    <MenuItem value="Outros">Outros</MenuItem>
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ height: "100%" }}
                onClick={handleAdd}
              >
                <Typography fontSize={25}>+</Typography>
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          variant="contained"
          size="large"
          disabled={loading}
          onClick={handleSubmit(handleSave)}
          autoFocus
          sx={{
            backgroundColor: "#51d347",
            "&:hover": {
              backgroundColor: "#39a930",
              borderColor: "#0062cc",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          {loading ? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ display: loading ? "inline-block" : "none" }}
            />
          ) : (
            "Enviar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ModalSendDocumentProps {
  open: boolean;
  handleClose: () => void;
  setData: (args: any) => void;
  data: any;
}
