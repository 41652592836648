import React, { useState } from "react";
import Box from "@mui/material/Box";
import search from "../../../assets/search.png";
import house from "../../../assets/house.png";
import arrow from "../../../assets/arrow_forward_ios.png";
import { useMediaQuery } from "@mui/material";
import FeatureCard from "pages/Dashboard/FeatureCard";
import { ModalSimulationDashboard } from "pages/Dashboard/ModalSimulationDashboard";
import { useNavigate } from "react-router-dom";
import useVerifyScreen from "hooks/useVerifyScreen";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

export function Card() {

  const isMobile = useVerifyScreen()

  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleRegisterProposal = () => {
    navigate("/cadastrarProposta");
  }
  return (
    <>
      <Box
        padding="3rem 0 3rem 0"
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection={isMobile ? "column" : "row"}
        columnGap={"4%"}
      >
        <Box onClick={handleOpenModal} sx={{marginBottom: isMobile ? 4 : 0}}>
          <FeatureCard
            icon={<ManageSearchIcon sx={{color: '#51D347', fontSize: 60}}/>}
            title="Faça uma simulação"
            description="Faça uma simulação de crédito do locatário"
          />
        </Box>
        <Box onClick={handleRegisterProposal}>
          <FeatureCard
            icon={<MapsHomeWorkIcon sx={{color: '#51D347', fontSize: 60}}/>}
            title="Cadastre uma proposta"
            description="Inicie o processo de locação de um locatário"
          />
        </Box>
      </Box>

      {
        openModal &&
        <ModalSimulationDashboard
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
      }
    </>
  );
}
