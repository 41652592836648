import React, { useState } from "react";
import { Tab, Tabs, List, Typography, IconButton, Box } from "@mui/material";
import {
  AccessTime,
  Cancel,
  ErrorOutline,
  HourglassEmpty,
  Payment,
  PendingActions,
  ReportProblem,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import { TabPanel } from "./TabPanel";
import { useNavigate } from "react-router-dom";
import { ReportListItem } from "./ReportListItem";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

export function Report() {
  const [value, setValue] = useState(0);
  const [selectedReport, setSelectedReport] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleReportClick = (report: string, type: string) => {
    setSelectedReport(report);
    navigate(`/relatorio?status=${report}&type=${type}`);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleBack}>
          <ArrowBackIosRoundedIcon sx={{ color: "#2E349F" }} />
        </IconButton>
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "bolder",
            color: "#2E349F",
            textAlign: "start",
          }}
        >
          Relatórios
        </Typography>
      </Box>

      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab label="Propostas" />
        <Tab label="Contratos" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <List>
          <ReportListItem
            onClick={() =>
              handleReportClick("Aguardando assinatura", "proposta")
            }
            icon={<PendingActions />}
            text="Aguardando assinatura"
            color="#F1C425"
          />
          <ReportListItem
            onClick={() => handleReportClick("Aguardando análise", "proposta")}
            icon={<HourglassEmpty />}
            text="Aguardando análise"
            color="#FF9800"
          />
          <ReportListItem
            onClick={() => handleReportClick("Recusada", "proposta")}
            icon={<ThumbDown />}
            text="Recusada"
            color="#FF2727"
          />
          <ReportListItem
            onClick={() => handleReportClick("Aprovada", "proposta")}
            icon={<ThumbUp />}
            text="Aprovada"
            color="#4CAF50"
          />
          <ReportListItem
            onClick={() =>
              handleReportClick("Aguardando pagamento", "proposta")
            }
            icon={<Payment />}
            text="Aguardando pagamento"
            color="#3F51B5"
          />
          <ReportListItem
            onClick={() => handleReportClick("Resolver pendências", "proposta")}
            icon={<ReportProblem />}
            text="Resolver pendências"
            color="#FF9800"
          />
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List>
          <ReportListItem
            onClick={() => handleReportClick("Ativo", "contrato")}
            icon={<AccessTime />}
            text="Ativo"
            color="#4CAF50"
          />
          <ReportListItem
            onClick={() => handleReportClick("Encerrado", "contrato")}
            icon={<Cancel />}
            text="Encerrado"
            color="#757575"
          />
          <ReportListItem
            onClick={() => handleReportClick("Inadimplente", "contrato")}
            icon={<ErrorOutline />}
            text="Inadimplente"
            color="#f44336"
          />
        </List>
      </TabPanel>
    </>
  );
}
