import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as yup from "yup";
import Input from "components/Input";
import LoadingButton from "components/LoadingButton";
import Cookies from "js-cookie";
import {
  Typography,
  Box,
  Stack,
  useMediaQuery,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  FilledInput,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { AUTH } from "graphql/mutation/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "context/AuthProvider";
import { tokenName } from "../../../service/api";
import IconButton from "components/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RecoveryPassword from "../RecoveryPassword";


export function FormLogin({
  token
}: FormLoginProps) {

  const navigate = useNavigate();
  const { handleLogin } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false)
  const [openRecoveryModal, setOpenRecoveryModal] = useState(false)

  const methods = useForm({
    defaultValues: {
      email: "",
      password: ""
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control, getValues, watch } = methods;

  const [auth, { loading }] = useMutation(AUTH);

  async function onSubmit() {

    const { email, password } = getValues()

    try {
      if (email && password) {
        const { data } = await auth({
          variables: {
            email: email,
            password: password,
          },
        });
        if (data) {
          enqueueSnackbar("Login realizado com sucesso", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          handleLogin(data.auth || "");
          navigate("/dashboard");
        }
      }
    } catch (err) {
      enqueueSnackbar((err as Error).message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  }
  const [isChecked, setIsChecked] = useState(false);
  const isPhone = useMediaQuery("(max-width: 450px) ");

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const clearCookies = () => {
    Cookies.remove(tokenName);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (!isChecked) {
      const cookieTimer = setTimeout(() => {
        clearCookies();
      }, 6 * 60 * 60 * 1000);
      return () => {
        clearTimeout(cookieTimer);
      };
    }
  }, [isChecked]);

  useEffect(() => {
    if (token) {
      setOpenRecoveryModal(true)
      return
    }
    setOpenRecoveryModal(false)
  }, [])

  return (
    <>
      <Box
        color="#fff"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FormProvider {...methods}>
          <Stack
            component="form"
            spacing={2}
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >

            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="E-mail"
                  variant="filled"
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 1
                  }}
                  InputLabelProps={{
                    sx: {
                      fontWeight: "bold"
                    }
                  }}
                  value={field.value}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <FormControl sx={{ m: 1, backgroundColor: "white", borderRadius: 1 }} variant="filled" fullWidth>
                  <InputLabel htmlFor="filled-adornment-password">Senha</InputLabel>
                  <FilledInput
                    {...field}
                    value={field.value}
                    id="filled-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              )}
            />



            <Box
              width="100%"
              onClick={() => {
                setOpenRecoveryModal(true)
              }}
            >
              <Typography align="left" sx={{
                color: "#51D347",
                fontWeight: "700",
                cursor: "pointer",
                textDecoration: "underline"
              }}>
                Esqueceu sua senha?
              </Typography>
            </Box>

            <Box
              textAlign="left"
              width="100%"
            >
              <FormControlLabel
                color="#fff"
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    value="remember"
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      "&.MuiButtonBase-root": {
                        color: "#fff",
                      },
                    }}
                  />
                }
                label="Lembrar de mim"
              />
            </Box>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={loading}
              textColor="white"
              width={isPhone ? "300px" : "400px"}
            >
              Entrar
            </LoadingButton>

          </Stack>

          <Typography
            align="center"
            sx={{
              fontWeight: "700",
              paddingTop: isPhone ? "0.8rem" : "2rem",
            }}
          >
            Não possui uma conta?{" "}
            <a href="#" style={{ color: "#51D347" }}>
              crie uma aqui.
            </a>
          </Typography>
        </FormProvider>
      </Box>

      {
        openRecoveryModal &&
        <RecoveryPassword
          {...{ token }}
          open={openRecoveryModal}
          handleClose={() => setOpenRecoveryModal(false)}
        />
      }

    </>
  );
}

interface FormLoginProps {
  token: string | null
}

const schema = yup.object({
  email: yup.string().required("email obrigatório"),
  password: yup.string().required("Senha obrigatória"),
});