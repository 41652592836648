import { useState } from 'react';

import {
    Autocomplete,
    Button,
    CardActions,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@mui/material';

import UPDATE_BANK_DATA_PERSON from 'graphql/mutation/updateBankPerson';
import { MeBankDataResponse, QueryRoot } from 'graphql/gql/graphql';
import FETCH_BANKS_QUERY from 'graphql/query/fetchBanks';
import { useMutation, useQuery } from '@apollo/client';
import { ME } from 'graphql/query/me';

import * as yup from 'yup';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

interface BankTableProps {
    me_id: number;
    bank_data: MeBankDataResponse;
}

const validationSchema = yup.object().shape({
    banco: yup.number(),
    agencia: yup.string(),
    codigoAgencia: yup.string(),
    conta: yup.string().required("Campo obrigatório"),
    codigoConta: yup.string().required("Campo obrigatório"),
    chavePix: yup.string().required(),

});

export function BankTable({ me_id, bank_data }: BankTableProps) {
    const agencyCode = bank_data.agency?.split('-') ?? [];
    const accountCode = bank_data.account?.split('-') ?? [];

    const methods = useForm({
        defaultValues: {
            banco: bank_data.bank?.id || 0,
            agencia: agencyCode[0] || "",
            codigoAgencia: agencyCode[1] || "",
            conta: accountCode[0] || "",
            codigoConta: accountCode[1] || "",
            chavePix: bank_data.pix || "",
        },
        resolver: yupResolver(validationSchema)
    });

    const { handleSubmit, control } = methods;

    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const { data, loading: loadingBank, error } = useQuery<QueryRoot>(FETCH_BANKS_QUERY, {
        variables: { keyword: searchTerm },
    });

    const [updateBankDataPerson, { loading }] = useMutation(UPDATE_BANK_DATA_PERSON, {
        refetchQueries: [{ query: ME, variables: { id: me_id } }],
    });

    const banks = data?.fetchBanks;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (data: any) => {
        try {
            await updateBankDataPerson({
                variables: {
                    person_id: me_id,
                    bank_id: data.banco,
                    agency: `${data.agencia}-${data.codigoAgencia}`,
                    account: `${data.conta}-${data.codigoConta}`,
                    pix: data.chavePix
                }
            });

            enqueueSnackbar("Dados atualizados com sucesso", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });

        } catch (error) {
            enqueueSnackbar("Erro ao atualizar dados", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
            });
        } finally {
            handleClose();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                >
                    Banco
                </Typography>
                <Typography variant="body1">{bank_data.bank?.name}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                >
                    Agência
                </Typography>
                <Typography variant="body1">
                    {bank_data.agency}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                >
                    Conta
                </Typography>
                <Typography variant="body1">
                    {bank_data.account}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                >
                    Chave Pix
                </Typography>
                <Typography variant="body1">
                    {bank_data.pix}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <CardActions style={{ justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                    >
                        Editar
                    </Button>
                </CardActions>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Editar Dados Bancários</DialogTitle>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="banco"
                                        control={control}
                                        render={({ field }) => (
                                            <Autocomplete
                                                options={banks || []}
                                                loading={loadingBank}
                                                getOptionLabel={(option) => option?.name ?? ''}
                                                onChange={(event, newValue) => { field.onChange(newValue?.id) }}
                                                value={banks?.find(bank => bank?.id === field.value) || null}
                                                renderInput={(params) =>
                                                    <TextField
                                                        label="Banco"
                                                        variant="outlined"
                                                        {...params}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="agencia"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField {...field} margin="dense" label="Agência" type="text" fullWidth />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="codigoAgencia"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField {...field} margin="dense" label="Código Agência" type="text" fullWidth />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="conta"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                margin="dense"
                                                label="Conta"
                                                type="text"
                                                fullWidth
                                                error={!!error}
                                                helperText={error ? error.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="codigoConta"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                margin="dense"
                                                label="Código Conta"
                                                type="text"
                                                fullWidth
                                                error={!!error}
                                                helperText={error ? error.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="chavePix"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField {...field} margin="dense" label="Chave Pix" type="text" fullWidth />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                autoFocus
                                disabled={loading}
                                sx={{
                                    backgroundColor: "#51d347",
                                    "&:hover": {
                                        backgroundColor: "#39a930",
                                        borderColor: "#0062cc",
                                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                                    },
                                }}
                            >
                                {loading ? <CircularProgress size={24} /> : "Salvar"}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </Grid>
    );
}