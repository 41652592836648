import { gql } from "@apollo/client";

const CREATE_OCCURRENCE = gql`
  mutation CreateOccurrence(
    $contract_id: Int!
    $type: String
    $rent_amount: Float
    $condominium_amount: Float
    $copasa: Float
    $cemig: Float
    $repairs: Float
    $iptu: Float
    $contractual_fine: Float
    $other_taxes: Float
    $value: Float!
    $dead_line: String!
    $description: String!
    $files: [CreateOccurrenceFiles]
  ) {
    createOccurrence(
      contract_id: $contract_id
      type: $type
      rent_amount: $rent_amount
      condominium_amount: $condominium_amount
      copasa: $copasa
      cemig: $cemig
      repairs: $repairs
      iptu: $iptu
      contractual_fine: $contractual_fine
      other_taxes: $other_taxes
      value: $value
      dead_line: $dead_line
      description: $description
      files: $files
    )
  }
`;

export default CREATE_OCCURRENCE;
